import React from 'react'
import { Grid, Typography } from '@mui/material'
import mtrPrint from '../../../assets/images/updates/company-persistance-update-mtr-print.png'
import documentationPrint from '../../../assets/images/updates/company-persistance-update-documentation-print.png'

const companyPersistanceUpdate = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Em busca de oferecer agilidade ao usuário, agora o sistema oferece uma integração direta entre as páginas,
          permitindo que a empresa selecionada seja persistida ao navegar entre as páginas.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Exemplo: Você selecionou a empresa &quot;XYZ LTDA&quot; durante sua navegação na página de Manifestos de
          Transporte. Você deseja ver os parceiros vinculados a essa empresa e seus documentos logo em seguida. Ao mudar
          para a página de Documentação Ambiental, a empresa &quot;XYZ LTDA&quot; continuará selecionada, evitando que
          você tenha que orocurá-la e selecioná-la novamente.
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={mtrPrint} alt="Exemplo de persistência de empresa selecionada" />
        <div>
          <Typography variant="caption">Empresa XYZ LTDA selecionada na página de Manifestos de Transporte.</Typography>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={documentationPrint} alt="Exemplo de persistência de empresa selecionada" />
        <div>
          <Typography variant="caption">
            Empresa XYZ LTDA permanece selecionada na página de Documentação Ambiental.
          </Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default companyPersistanceUpdate
