import axios from 'axios'

import { GatheringParams } from '../../routes/Gathering/GatheringList/GatheringFilterDrawer/GatheringFilterDrawer'
import Gathering, { CancelGathering, GatheringPaginated, type GatheringRequest } from '../../models/Gathering/Gathering'
import getEndpointUrl from '../../utils/url-resolver'

const urlV2 = `${getEndpointUrl('collection')}/v2/collections`
const baseUrl = `${getEndpointUrl('collection')}/v1/collections`

const getAllGatherings = (paramsProp: GatheringParams): Promise<GatheringPaginated> => {
  return axios.post<GatheringPaginated>(urlV2, paramsProp).then((response) => response.data)
}

const getGatheringById = (id: number): Promise<Gathering> =>
  axios.get<Gathering>(`${baseUrl}/${id}`).then((response) => response.data)

const createGathering = (gathering: GatheringRequest): Promise<Gathering> =>
  axios.post(`${baseUrl}/`, gathering).then((response) => response.data)

const updateGathering = (gathering: GatheringRequest): Promise<void> =>
  axios.put(`${baseUrl}/${gathering.idCollections}`, gathering).then((response) => response.data)

const deleteGathering = (id: number): Promise<void> => axios.delete(`${baseUrl}/${id}`)

const cancelGathering = (gathering: CancelGathering): Promise<CancelGathering> =>
  axios.post(`${baseUrl}/cancel`, gathering).then((response) => response.data)

const retryGathering = (idGathering: number): Promise<void> =>
  axios.post(`${baseUrl}/retry/${idGathering}`).then((response) => response.data)

const downloadGatheringsPDF = (idGatherings: number[]): Promise<Blob> =>
  axios.get(`${baseUrl}/download?ids=${idGatherings}`, { responseType: 'blob' }).then((response) => response.data)

const downloadGatheringsCDF = (idGatherings: number[]): Promise<Blob> =>
  axios.get(`${baseUrl}/download/cdf?ids=${idGatherings}`, { responseType: 'blob' }).then((response) => response.data)

export {
  getAllGatherings,
  getGatheringById,
  createGathering,
  updateGathering,
  deleteGathering,
  cancelGathering,
  retryGathering,
  downloadGatheringsPDF,
  downloadGatheringsCDF,
}
