import axios from 'axios'
import {
  FeamAcondicionamento,
  FeamClasses,
  FeamEstadoFisico,
  FeamResiduos,
  FeamStatusRequest,
  FeamStatusResponse,
  FeamTecnologias,
  FeamUnidades,
} from '../../models/Feam/Feam'
import getEndpointUrl from '../../utils/url-resolver'

type FeamData = {
  classes: { value: number; label: string }[]
  estadosFisicos: { value: number; label: string }[]
  unidades: { value: number; label: string }[]
  tecnologias: { value: number; label: string }[]
  acondicionamento: { value: string; label: string }[]
  residuos: { value: string; label: string }[]
}

const getAllFeamData = (): FeamData => ({
  classes: FeamClasses,
  estadosFisicos: FeamEstadoFisico,
  unidades: FeamUnidades,
  tecnologias: FeamTecnologias,
  acondicionamento: FeamAcondicionamento,
  residuos: FeamResiduos,
})

const baseUrl = `${getEndpointUrl('collection')}/v1`

export const retrieveFeamStatus = (customerInfo: FeamStatusRequest): Promise<FeamStatusResponse> => {
  return axios.post<FeamStatusResponse>(`${baseUrl}/feam/status`, customerInfo).then((response) => response.data)
}

export default getAllFeamData
