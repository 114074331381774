import { Button, Dialog, DialogContent, DialogTitle, SelectChangeEvent } from '@mui/material'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import React, { useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import InputText from '../../../components/Form/Input/Input'
import InputSelect from '../../../components/Form/Select/Select'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import { FeamResiduos } from '../../../models/Feam/Feam'
import { WasteModel } from '../../../models/MtrModel/MtrModel'
import getAllFeamData from '../../../services/FEAM/FeamService'
import useYupValidationResolver from '../../../utils/yup-validator-resolver'

const wasteValidationSchema = yup.object({
  densityType: yup.number().when('unit', {
    is: 1 || 2,
    then: yup.number().required('campo obrigatório'),
  }),
  densityValue: yup.number().when('unit', {
    is: 1 || 2,
    then: yup.number().required('campo obrigatório').min(1, 'quantidade deve ser maior que 0'),
  }),
  name: yup.string().required('Preencha um código e clique na lupa para carregar'),
  generatorInternalDescription: yup.string(),
  generatorInternalCode: yup.string().max(10, 'Máximo de 10 caracteres'),
  onuNumber: yup.string(),
  packagingCod: yup.string().required('campo obrigatório'),
  quantity: yup
    .number()
    .required('campo obrigatório')
    .min(1, 'quantidade deve ser maior que 0')
    .typeError('Quantidade deve ser um número.'),
  riskClass: yup.string(),
  state: yup.number().required('campo obrigatório'),
  technology: yup.number().required('campo obrigatório'),
  unit: yup.number().required('campo obrigatório'),
  wasteClass: yup.number().required('campo obrigatório'),
  wasteCode: yup.string().required('campo obrigatório'),
})

export type WasteFormDialogProps = {
  waste: WasteModel | null
  open: boolean
  handleSave: (wasteData: WasteModel) => void
  handleClose: () => void
}

const WasteFormDialog: React.FC<WasteFormDialogProps> = ({
  waste,
  open,
  handleSave,
  handleClose,
}: WasteFormDialogProps) => {
  const resolver = useYupValidationResolver(wasteValidationSchema)
  const { control, formState, handleSubmit, setValue, reset, setError, clearErrors } = useForm<WasteModel>({
    resolver,
    mode: 'onChange',
  })
  const [showForm, setShowForm] = React.useState<boolean>(false)
  const [showDensityFields, setShowDensityFields] = React.useState<boolean>(false)
  const [feamClassesMap, setFeamClassesMap] = React.useState<{ label: string; value: number }[]>([])
  const [feamUnidadesMap, setFeamUnidadesMap] = React.useState<{ label: string; value: number }[]>([])
  const [feamTecnologiasMap, setFeamTecnologiasMap] = React.useState<{ label: string; value: number }[]>([])
  const [feamEstadoFisicoMap, setFeamEstadoFisicoMap] = React.useState<{ label: string; value: number }[]>([])
  const feamResiduos = FeamResiduos
  const [feamAcondicionamentoMap, setFeamAcondicionamentoMap] = React.useState<{ label: string; value: string }[]>([])
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const densityTypeMap = [
    { value: 1, label: 't/m³' },
    { value: 2, label: 'g/cm³' },
  ]

  const formDefaultValue = useRef({
    wasteCode: '',
    name: '',
    quantity: 0,
    unit: 4,
    state: 1,
    wasteClass: 1,
    generatorInternalCode: '',
    generatorInternalDescription: '',
  })

  const handleCloseDialog = (): void => {
    reset(formDefaultValue.current)
    handleClose()
  }

  const handleClosePopover = (): void => {
    setAnchorEl(null)
  }

  const handleClickResiduo = (residuo: { label: string; value: string }): void => {
    setValue('wasteCode', residuo.value)
    setValue('name', residuo.label)
    handleClosePopover()
  }

  const openPopover = Boolean(anchorEl)

  const onSubmit: SubmitHandler<WasteModel> = (data: WasteModel) => {
    handleSave(data)
    handleClose()
    reset(formDefaultValue.current)
  }

  const handleSearchWaste = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    if (value) {
      const wasteCode = value.value as string
      const name = value.label as string
      const formattedName = name.split('-')[1].trim()
      clearErrors('wasteCode')
      setValue('wasteCode', wasteCode)
      setValue('name', formattedName)
    } else {
      setError('wasteCode', { message: 'Residuo Invalido' })
    }
  }

  const handleUnitChange = (event: SelectChangeEvent<any>): void => {
    const value = Number(event.target.value)
    setValue('unit', value)
    if (value === 1 || value === 2) {
      setShowDensityFields(true)
      setValue('densityType', Number(value) as number)
    } else {
      setShowDensityFields(false)
      setValue('densityValue', undefined)
      setValue('densityType', undefined)
    }
  }

  React.useEffect(() => {
    if (waste) {
      if (Number(waste.unit) !== 1 && Number(waste.unit) !== 2) {
        const copyWaste = { ...waste }
        delete copyWaste.densityType
        delete copyWaste.densityValue
        reset(copyWaste)
      } else {
        reset(waste, { keepErrors: false })
      }
    } else {
      reset(formDefaultValue.current)
    }
  }, [waste, reset, formDefaultValue])

  React.useEffect(() => {
    setValue('unit', 4)
  }, [feamUnidadesMap, setValue])

  React.useEffect(() => {
    const feamData = getAllFeamData()
    setFeamClassesMap(feamData.classes)
    setFeamUnidadesMap(feamData.unidades)
    setFeamTecnologiasMap(feamData.tecnologias)
    setFeamEstadoFisicoMap(feamData.estadosFisicos)
    setFeamAcondicionamentoMap(feamData.acondicionamento)

    setTimeout(() => {
      setShowForm(true)
    }, 1500)
  }, [])

  const feamOpt = feamResiduos.map((res) => {
    return { value: res.value, label: `${res.value} - ${res.label}` }
  })

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adicionar Resíduo</DialogTitle>
        {showForm && (
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                  <Controller
                    name="wasteCode"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputSelect2
                        id="wasteCode"
                        label="Resíduo"
                        errorText={formState.errors?.wasteCode?.message}
                        options={feamOpt}
                        {...field}
                        onChange={handleSearchWaste}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="quantity"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <InputText
                        id="quantity"
                        type="number"
                        label="Quantidade"
                        errorText={formState.errors?.quantity?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="unit"
                    control={control}
                    defaultValue={feamUnidadesMap[0]?.value}
                    render={({ field }) => (
                      <InputSelect
                        id="unit"
                        label="Unidade"
                        errorText={formState.errors?.unit?.message}
                        options={feamUnidadesMap}
                        {...field}
                        onChange={handleUnitChange}
                      />
                    )}
                  />
                </Grid>
                {showDensityFields && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="densityValue"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <InputText
                            id="densityValue"
                            type="number"
                            label="Valor Densidade"
                            errorText={formState.errors?.densityValue?.message}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="densityType"
                        control={control}
                        defaultValue={1}
                        render={({ field }) => (
                          <InputSelect
                            id="densityType"
                            label="Tipo Densidade"
                            errorText={formState.errors?.densityType?.message}
                            options={densityTypeMap}
                            disabled
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="state"
                    control={control}
                    defaultValue={Number(feamEstadoFisicoMap[0]?.value) as number}
                    render={({ field }) => (
                      <InputSelect
                        id="state"
                        label="Estado Físico"
                        errorText={formState.errors?.state?.message}
                        options={feamEstadoFisicoMap}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="wasteClass"
                    control={control}
                    defaultValue={Number(feamClassesMap[0]?.value) as number}
                    render={({ field }) => (
                      <InputSelect
                        id="wasteClass"
                        label="Classe"
                        errorText={formState.errors?.wasteClass?.message}
                        options={feamClassesMap}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="packagingCod"
                    control={control}
                    defaultValue={feamAcondicionamentoMap[0]?.value}
                    render={({ field }) => (
                      <InputSelect
                        id="packagingCod"
                        label="Acondicionamento"
                        errorText={formState.errors?.packagingCod?.message}
                        options={feamAcondicionamentoMap}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="technology"
                    control={control}
                    defaultValue={Number(feamTecnologiasMap[0]?.value) as number}
                    render={({ field }) => (
                      <InputSelect
                        id="technology"
                        label="Tecnologia"
                        errorText={formState.errors?.technology?.message}
                        options={feamTecnologiasMap}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    name="onuNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        id="onuNumber"
                        type="text"
                        label="Número ONU"
                        errorText={formState.errors?.onuNumber?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="riskClass"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        id="riskClass"
                        type="text"
                        label="Classe de Risco"
                        errorText={formState.errors?.riskClass?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="generatorInternalCode"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        id="generatorInternalCode"
                        type="text"
                        label="Código Interno no Gerador"
                        errorText={formState.errors?.generatorInternalCode?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="generatorInternalDescription"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        id="generatorInternalDescription"
                        type="text"
                        label="Descrição Interna do Gerador"
                        errorText={formState.errors?.generatorInternalDescription?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '16px',
                  margin: '16px 0px 24px 0px',
                }}
              >
                <Button onClick={handleClose} color="primary">
                  Cancelar
                </Button>
                <Button variant="contained" color="primary" type="submit" data-cy="add-new-residue-button">
                  Salvar
                </Button>
              </Grid>
            </DialogContent>
          </form>
        )}
      </Dialog>

      <Popover
        id="popover id"
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="div" style={{ maxHeight: 300 }}>
          {feamResiduos.map((r) => (
            <ListItem
              key={r.value}
              button
              onClick={() => {
                handleClickResiduo(r)
              }}
            >
              <ListItemText>
                {r.value} - {r.label}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )
}

export default WasteFormDialog
