import React from 'react'
import { Card, FormLabel, Grid, Switch } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import InputText from '../../../../Form/Input/Input'
import { useStyles } from '../../../CompanyFormStyle'
import { PartnerFormType } from '../../../../../models/Company/Company'

interface PartnerBankInfoProps {
  form: UseFormReturn<PartnerFormType>
}

const PartnerBankInfo: React.FC<PartnerBankInfoProps> = ({ form }) => {
  const { control, formState } = form
  const companyFormclasses = useStyles()

  return (
    <Card className="form-card" variant="outlined">
      <h2 className={companyFormclasses.tabTitle}>Dados bancários</h2>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} key="bankCode">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Código do Banco
            <Controller
              name="bankCode"
              control={control}
              render={({ field }) => (
                <InputText
                  id="bankCode"
                  label=""
                  placeholder="100"
                  errorText={formState.errors?.bankCode?.message}
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="bankRanch">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Agência
            <Controller
              name="bankBranch"
              control={control}
              render={({ field }) => (
                <InputText
                  id="bankBranch"
                  label=""
                  placeholder="100"
                  errorText={formState.errors?.bankBranch?.message}
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="accountNumber">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Conta corrente
            <Controller
              name="accountNumber"
              control={control}
              render={({ field }) => (
                <InputText
                  id="accountNumber"
                  label=""
                  placeholder="01234567"
                  errorText={formState.errors?.accountNumber?.message}
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="maxPaymentTerm">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Prazo para pagamento
            <Controller
              name="maxPaymentTerm"
              control={control}
              render={({ field }) => (
                <InputText
                  id="maxPaymentTerm"
                  label=""
                  placeholder="10"
                  type="number"
                  InputProps={{ endAdornment: 'dias' }}
                  errorText={formState.errors?.maxPaymentTerm?.message}
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
      </Grid>
    </Card>
  )
}

export default PartnerBankInfo
