import axios, { AxiosResponse } from 'axios'

import getEndpointUrl from '../../utils/url-resolver'
import {
  PartnerMinimalResponse,
  PartnerRequest,
  PartnerResponse,
  PartnersDocuments,
} from '../../models/Partner/Partner'

const baseUrl = `${getEndpointUrl('company')}/v1/partners`
const baseUrlDocs = `${getEndpointUrl('company')}/v1/documents`

export const getAllPartners = async (): Promise<PartnerMinimalResponse[]> => {
  return axios.get(`${baseUrl}`).then((response) => response.data)
}

export const getPartnerByIdentifier = async (partnerIdentifier: string): Promise<PartnerResponse> => {
  return axios.get(`${baseUrl}/${partnerIdentifier}`).then((response) => response.data)
}

export const savePartner = async (partnerRequest: PartnerRequest): Promise<PartnerResponse> => {
  return axios.post(`${baseUrl}`, partnerRequest).then((response) => response.data)
}

export const updatePartner = async (partnerRequest: PartnerRequest, identifier: string): Promise<PartnerResponse> => {
  return axios.put(`${baseUrl}/${identifier}`, partnerRequest).then((response) => response.data)
}

export const deletePartner = async (identifier: string): Promise<AxiosResponse> => {
  return axios.delete(`${baseUrl}/${identifier}`)
}

export const getAllTransporterPartners = async (): Promise<PartnerMinimalResponse[]> => {
  return axios.get(`${baseUrl}/transporter`).then((response) => response.data)
}

export const getAllDestinationPartners = async (): Promise<PartnerMinimalResponse[]> => {
  return axios.get(`${baseUrl}/destination`).then((response) => response.data)
}

export const getAllTemporaryStoragePartners = async (): Promise<PartnerMinimalResponse[]> => {
  return axios.get(`${baseUrl}/temporary-storage`).then((response) => response.data)
}

export const getAllPartnerDocumentsByCustomer = async (identifier: string): Promise<PartnersDocuments[] | null> => {
  return axios.get(`${baseUrlDocs}/customer/${identifier}/partners`).then((response) => {
    if (response.data && response.data.length > 0) {
      return response.data
    }
    return null
  })
}

export const getAllPartnersDocumentsByUserIdentifier = async (
  identifier: string
): Promise<PartnersDocuments[] | null> => {
  return axios.get(`${baseUrlDocs}/user/${identifier}/partners`).then((response) => {
    if (response.data && response.data.length > 0) {
      return response.data
    }
    return null
  })
}
