import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    fileInputContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #ccc',
      padding: '10px',
      boxSizing: 'border-box',
      borderRadius: '5px',
      webkitBoxSizing: 'border-box',
      mozBoxSizing: 'border-box',
      height: '58px',
    },
    fileNameContainer: {
      backgroundColor: '#E3F6E8',
      color: '#1A1C18',
      margin: '2px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '20px',
    },
    savedFileLink: {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    iconStyle: {
      cursor: 'pointer',
      width: '100%',
      marginRight: '10px',
    },
  })
)

export default useStyles
