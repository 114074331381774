import React from 'react'
import { Card, FormLabel, Grid } from '@mui/material'
import { Control, Controller, UseFormReturn } from 'react-hook-form'
import { CompanyFormType } from '../../../../models/Company/Company'
import InputText from '../../../Form/Input/Input'
import InputMaskPhone from '../../../Form/Input/InputMaskPhone'
import { useStyles } from '../../CompanyFormStyle'

interface ContactFormProps {
  form: UseFormReturn<any>
  contactType: string
}

const ContactForm: React.FC<ContactFormProps> = ({ form, contactType }) => {
  const companyFormclasses = useStyles()
  const { control, getValues } = form

  const name = `${contactType}.name`
  const surname = `${contactType}.surname`
  const telephone = `${contactType}.telephone`
  const email = `${contactType}.email`

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} key="name">
        <FormLabel component="label" className={companyFormclasses.labelStyle}>
          Nome
          <Controller
            name={name as keyof CompanyFormType}
            control={control}
            defaultValue={getValues(name as keyof CompanyFormType)}
            render={({ field }) => <InputText id={name} label="" {...field} />}
          />
        </FormLabel>
      </Grid>
      <Grid item xs={12} sm={6} key="surname">
        <FormLabel component="label" className={companyFormclasses.labelStyle}>
          Sobrenome
          <Controller
            name={surname as keyof CompanyFormType}
            control={control}
            defaultValue={getValues(surname as keyof CompanyFormType)}
            render={({ field }) => <InputText id={surname} label="" {...field} />}
          />
        </FormLabel>
      </Grid>
      <Grid item xs={12} sm={6} key="telephone">
        <FormLabel component="label" className={companyFormclasses.labelStyle}>
          Telefone
          <Controller
            name={telephone as keyof CompanyFormType}
            control={control}
            defaultValue={getValues(telephone as keyof CompanyFormType)}
            render={({ field }) => <InputMaskPhone id={telephone} label="" {...field} />}
          />
        </FormLabel>
      </Grid>
      <Grid item xs={12} sm={6} key="email">
        <FormLabel component="label" className={companyFormclasses.labelStyle}>
          E-mail
          <Controller
            name={email as keyof CompanyFormType}
            control={control}
            defaultValue={getValues(email as keyof CompanyFormType)}
            render={({ field }) => <InputText id={email} label="" {...field} />}
          />
        </FormLabel>
      </Grid>
    </Grid>
  )
}

export default ContactForm
