import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainCard: {
      width: '100%',
      minWidth: 'fit-content',
      maxWidth: 'calc(100% - 32px)',
      padding: '16px 0px 0px 0px',
      overflowY: 'auto',
      border: 'none',
      margin: '0px 16px',
    },
    tabTitle: {
      fontWeight: 'lighter',
    },
    switchStyle: {
      width: 50,
      height: 30,
      padding: 0,
      '&.MuiSwitch-root': {
        width: 68,
        height: 52,
        margin: 0,
        right: 12,
      },
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 16,
        transitionDuration: '300ms',
        '& + .MuiSwitch-track': {
          borderRadius: 13,
        },
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
            opacity: 1,
            border: 0,
            borderRadius: 13,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
    },
    labelStyle: {
      '&.MuiFormLabel-root': {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '16px',
        letterSpacing: '0em',
        color: '#484946',
      },
    },
  })
)
