import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import PartnerTechnicalInfo from './components/PartnerTechnicalInfo'
import PartnerBankInfo from './components/PartnerBankInfo'
import { PartnerFormType } from '../../../../models/Company/Company'

interface PartnerTechnicalInfoTabProps {
  form: UseFormReturn<PartnerFormType>
}

const PartnerTechnicalInfoTab: React.FC<PartnerTechnicalInfoTabProps> = ({ form }) => {
  return (
    <>
      <PartnerTechnicalInfo form={form} />
      <PartnerBankInfo form={form} />
    </>
  )
}

export default PartnerTechnicalInfoTab
