import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      height: '100vh',
      display: 'flex',
      '& aside': {
        display: 'block',
        flexBasis: '300px',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      '& main': {
        flex: 1,
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'auto',
        height: 'calc(100% - 125px)',
      },
    },
    containerMain: {
      width: '100%',
      overflowX: 'hidden',
    },
  })
)
