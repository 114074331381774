export const getChipColor = (status: string): 'success' | 'error' | 'warning' => {
  switch (status) {
    case 'EXPIRED':
      return 'error'
    case 'TO-EXPIRE':
      return 'warning'
    default:
      return 'success'
  }
}

export const getChipLabel = (status: string): string => {
  switch (status) {
    case 'EXPIRED':
      return 'Expirado'
    case 'TO-EXPIRE':
      return 'Expirando'
    default:
      return 'Ativo'
  }
}
