import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertColor } from '@mui/material/Alert'
import React from 'react'
import { SnackbarCloseReason } from '@mui/material'

type AlertsProps = {
  isShowAlert: boolean
  setIsShowAlert: (value: boolean) => void
  alertText: string
  alertType: AlertColor
}

const Alerts: React.FC<AlertsProps> = ({ isShowAlert, setIsShowAlert, alertText, alertType }: AlertsProps) => {
  React.useEffect(() => {
    if (isShowAlert) {
      setTimeout(() => {
        setIsShowAlert(false)
      }, 7000)
    }
  }, [isShowAlert, setIsShowAlert])

  const handleClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason): void => {
    if (reason === 'clickaway') {
      return
    }

    setIsShowAlert(false)
  }

  return (
    <Snackbar open={isShowAlert} autoHideDuration={6000} onClose={handleClose}>
      <MuiAlert data-cy="alert-message" elevation={6} variant="filled" severity={alertType} onClose={handleClose}>
        {alertText}
      </MuiAlert>
    </Snackbar>
  )
}

export default Alerts
