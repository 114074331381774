import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'space-between',
      paddingRight: '20px !important',
      paddingLeft: '20px !important',
    },
    item: { paddingRight: '2px !important' },
    iconButton: {
      height: '48px',
      width: '48px',
      borderRadius: '50%',
      border: '2px solid #33B652 !important',
      '&:disabled': {
        border: '2px solid #D1D2D1 !important',
      },
    },
  })
)
