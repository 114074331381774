/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Card, Grid } from '@mui/material'
import React from 'react'
import AppContext, { AppContextType } from '../../../AppContext'
import { useStyles } from './HelpPageStyles'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'

const HelpPage: React.FC = () => {
  const classes = useStyles()
  const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSdQL7V362vZsME7oEHHNsWfLmw12gwvATd9phDSkLWSD73gvQ/viewform'
  const { setTitle, setCustomHeaderContent, setItemsBreadCrumb } = React.useContext(
    AppContext as React.Context<AppContextType>
  )

  React.useEffect(() => {
    setTitle('Ajuda')
    setCustomHeaderContent(<div />)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Ajuda', path: '/main/help/tutorial' }])
  }, [])

  return (
    <Card className="card card--form" variant="outlined">
      <div className={classes.tutorial}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2 className={classes.title}>Área de suporte ténico ao usuário.</h2>
            <p className={classes.paragraph}>
              Acesse o formulário de requerimentos para reportar erros do sistema, abrir requerimentos e dar feedback ao
              time de Tecnologia da Recicla Club.
            </p>
            <Grid item className={classes.buttonContainer}>
              <Button variant="contained" color="primary" href={formUrl} className={classes.button} target="_blank">
                Acessar Formulário
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Card>
  )
}

export default HelpPage
