/* eslint-disable react/no-array-index-key */

import { SxProps, Theme } from '@mui/system'
import { Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import React, { ReactElement } from 'react'
import { getComparator, ItemType, Order, stableSort } from '../../utils/sort'

import DataTableHeader from './DataTableHeader/DataTableHeader'

import useStyles from './DataTableStyles'

import IconPicture from '../../models/Icon/icon'

export type DataTableMapProps = {
  truncate?: number
  key: string
  value: string | number | JSX.Element
  isUrl?: boolean
  transform?: (value: any) => any
  isHead?: boolean
}

type DataTableConfigProps = {
  showPagination?: boolean
  rowsPerPage?: number
  initialSort?: { key: string; type: Order }
  emptyMessage?: string
  emptyValue?: string
}

export type DataTableActionsProps = {
  label: string
  icon: IconPicture | ReactElement
  showIf?: { property: string; value?: any }
  fn: (row: any, index?: number, target?: EventTarget & HTMLButtonElement) => void
}

type DataTableProps = {
  actions?: DataTableActionsProps[]
  config?: DataTableConfigProps
  data: any
  dataMap: DataTableMapProps[]
  loading: boolean
  compact?: boolean
  customColumns?: { label: string; content: (row: any) => JSX.Element }[]
  totalItems?: number
  pageSize?: React.Dispatch<React.SetStateAction<number>>
  paginating?: React.Dispatch<React.SetStateAction<number>>
  onSort?: (order: Order, property: string) => void
  settings?: boolean
  minWidth?: string
  tableClassName?: string
  tableContainerSx?: SxProps<Theme>
  tableSx?: SxProps<Theme>
}

const DataTableFreezer: React.FC<DataTableProps> = ({
  actions,
  config,
  data,
  dataMap,
  loading,
  compact,
  customColumns,
  totalItems,
  pageSize,
  paginating,
  onSort,
  settings,
  minWidth,
  tableClassName = '',
  tableContainerSx = {},
  tableSx = {},
}: DataTableProps) => {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(config?.rowsPerPage || 100)
  const [order, setOrder] = React.useState<Order>(config?.initialSort ? config?.initialSort.type : 'asc')
  const [orderBy, setOrderBy] = React.useState<string>(config?.initialSort ? config?.initialSort.key : '')
  const newData: any[] = paginating ? data.content : data

  const handleChangePage = (event: unknown, newPage: number): void => {
    if (paginating) {
      paginating(newPage)
    }
    setPage(newPage)
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string): void => {
    const shouldBeAscending = orderBy !== property || order !== 'asc'
    setOrder(shouldBeAscending ? 'asc' : 'desc')
    setOrderBy(property)
    if (paginating && onSort) {
      onSort(shouldBeAscending ? 'asc' : 'desc', property)
    }
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (paginating) paginating(0)
    setPage(0)
    if (pageSize) pageSize(parseInt(event.target.value, 10))

    setRowsPerPage(parseInt(event.target.value, 10))
  }

  React.useEffect(() => {
    if (paginating) {
      paginating(data.currentPage)
      setPage(data.currentPage)
    }
  }, [data])

  const renderCellContent = (item: DataTableMapProps, row: ItemType<any>): JSX.Element => {
    return item?.truncate ? (
      <Tooltip title={<h3>{row[item.key]}</h3>}>
        <span style={{ width: `${item?.truncate}px` }} className={classes.truncate}>
          {item?.isUrl ? (
            <a href={row[item.key] as string} target="_blank" rel="noreferrer">
              {row[item.key]}
            </a>
          ) : (
            <div>{row[item.key]}</div>
          )}
        </span>
      </Tooltip>
    ) : (
      <span>
        {item?.isUrl ? (
          <a href={row[item.key] as string} target="_blank" rel="noreferrer">
            {row[item.key]}
          </a>
        ) : (
          <div>{item?.transform ? item?.transform(row[item.key]) : row[item.key]}</div>
        )}
      </span>
    )
  }

  const renderItem = (row: ItemType<any>, i: number): JSX.Element => {
    return (
      <TableRow
        sx={{
          '.MuiTableCell-root': {
            height: '60px',
            minWidth: minWidth || 'fit-content',
            maxWidth: minWidth || 'fit-content',
          },
        }}
        hover
        tabIndex={-1}
        key={`row-${Object.values(row)[0]}-${i}`}
      >
        {dataMap.map((item) =>
          item.isHead ? (
            <th key={`cell-${item.key}`} data-cy={`list-item-${(row[item.key] as string).replace(/\s/g, '')}`}>
              {renderCellContent(item, row)}
            </th>
          ) : (
            <TableCell key={`cell-${item.key}`} variant="head">
              {renderCellContent(item, row)}
            </TableCell>
          )
        )}
        {customColumns &&
          customColumns.map((column) => <TableCell key={column.label}>{column.content(row)}</TableCell>)}
        {actions && actions?.length > 0 && (
          <TableCell
            style={{
              textAlign: 'center',
              minWidth: '130px',
            }}
          >
            {actions.map((action) => (
              <div key={action.label}>
                {(!action.showIf ||
                  (action.showIf.value !== undefined
                    ? row[action.showIf.property] === action.showIf.value
                    : row[action.showIf.property])) && (
                  <IconButton
                    key={`${action.label}`}
                    aria-label={action.label}
                    onClick={(e) => {
                      action.fn(row, i, e.currentTarget)
                    }}
                    size="large"
                  >
                    {action.icon}
                  </IconButton>
                )}
              </div>
            ))}
          </TableCell>
        )}
      </TableRow>
    )
  }

  const renderItems = (): JSX.Element[] => {
    if (!paginating) {
      return stableSort(newData, getComparator(order, orderBy, config?.emptyValue))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => renderItem(row, i))
    }
    return newData.map((row, i) => renderItem(row, i))
  }

  const actualTableClassName = `${settings ? classes.table : ''} ${tableClassName}`.trim()

  return (
    <div className={`${settings ? classes.card : ''} horizontal-scroll-mobile`}>
      <Table
        className={actualTableClassName}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
        sx={tableSx}
      >
        <DataTableHeader
          dataProps={
            customColumns
              ? [
                  ...dataMap,
                  ...customColumns.map((column) => ({
                    key: 'any',
                    value: column.label,
                  })),
                ]
              : dataMap
          }
          hasActions={!!(actions && actions?.length > 0)}
          onRequestSort={handleRequestSort}
          orderBy={orderBy}
          order={order}
        />
        <TableBody sx={{ overflowX: 'scroll' }}>
          <>
            {!loading && newData && renderItems()}
            {newData.length === 0 && !loading && (
              <TableRow>
                <TableCell colSpan={dataMap.length + (actions ? 1 : 0)}>
                  <p style={{ textAlign: 'center' }}>{config?.emptyMessage ? config.emptyMessage : 'Sem Registros'}</p>
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={dataMap.length + (actions ? 1 : 0)}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '16px 0px',
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </>
        </TableBody>
      </Table>
    </div>
  )
}

DataTableFreezer.defaultProps = {
  actions: [],
  compact: false,
  config: {
    showPagination: true,
    rowsPerPage: 100,
  },
  customColumns: undefined,
}

export default DataTableFreezer
