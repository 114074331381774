import React from 'react'
import { Grid, Typography } from '@mui/material'
import formContractFileField from '../../../assets/images/updates/contract-file-update/contract-file-field-form.png'
import visualizationContractFileField from '../../../assets/images/updates/contract-file-update/contract-file-field-visualization.png'

const newContractFileFieldUpdateContent = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Ampliamos o cadastro e edição de clientes para guardar o arquivo PDF do contrato de cada um com a Recicla
          Club. Agora é possível tanto salvar quanto visualizar a versão atual do arquivo no nosso sistema. Veja abaixo:
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img
          src={formContractFileField}
          alt="Demonstração do novo campo para salvar o arquivo do contrato no Formulário de Clientes."
        />
        <div>
          <Typography variant="caption">
            Demonstração do novo campo para salvar o arquivo do contrato no Formulário de Clientes.
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img
          src={visualizationContractFileField}
          alt="Demonstração do link para o arquivo do contrato na visualização de dados do Cliente."
        />
        <div>
          <Typography variant="caption">
            Demonstração do link para o arquivo do contrato na visualização de dados do Cliente.
          </Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default newContractFileFieldUpdateContent
