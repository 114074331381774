import React, { MouseEvent } from 'react'
import { Button, Grid } from '@mui/material'
import brokenRobot from '../../assets/images/BrokenRobot.png'
import AppContext, { AppContextType } from '../../AppContext'
import { defaultBreadCrumbItems } from '../../components/BreadCrumb/BreadCrumb'

import useStyles from './AccessDeniedStyle'

declare global {
  interface Window {
    zE: any
  }
}

const AccessDenied: React.FC = () => {
  const styles = useStyles()
  const { setTitle, setItemsBreadCrumb } = React.useContext(AppContext as React.Context<AppContextType>)

  const handleInit = React.useCallback((): void => {
    setTitle('Acesso Negado')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTitle])

  React.useEffect(() => {
    handleInit()
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Acesso negado', path: '/main/access-denied' }])
  }, [handleInit, setItemsBreadCrumb])

  const openSupportWindow = (): void => {
    if (window.zE) {
      window.zE('webWidget', 'open')
    }
  }

  const handleClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault()
    openSupportWindow()
  }

  return (
    <Grid container className={styles.mainContainer}>
      <Grid item xs={12}>
        <h1 className={styles.title}>
          Desculpe, mas parece que você está tentando acessar uma página para a qual você não tem permissão de acesso.
        </h1>
        <p className={styles.paragraph}>
          Se você acha que deveria ter acesso a essa página, entre em contato com a equipe da recicla.
        </p>
        <Grid item className={styles.buttonContainer}>
          <Button variant="contained" color="primary" href="/main/home" className={styles.buttonLeft}>
            Voltar ao Início
          </Button>
        </Grid>
      </Grid>
      <Grid item className={styles.brokenRobotGrid}>
        <img className={styles.brokenRobotImage} src={brokenRobot} alt="home-pic" />
      </Grid>
    </Grid>
  )
}

export default AccessDenied
