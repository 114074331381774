import { FormLabel, Grid, Typography } from '@mui/material'
import React from 'react'
import { GatheringRequest } from '../../../../models/Gathering/Gathering'
import useStyles from './styles/GatheringConfirmationInfoModalStyle'

interface GatheringGeneralInfoProps {
  gatheringInfo: GatheringRequest
}

const GatheringGeneralInfo: React.FC<GatheringGeneralInfoProps> = ({ gatheringInfo }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} className={classes.card}>
      {gatheringInfo.driverName && (
        <Grid item xs={12}>
          <Typography variant="body1" data-cy="confirmation-driverName">
            <strong>Motorista:</strong> {gatheringInfo.driverName}
          </Typography>
        </Grid>
      )}
      {gatheringInfo.vehicleName && (
        <Grid item xs={12}>
          <Typography variant="body1" data-cy="confirmation-vehicleName">
            <strong>Veículo:</strong> {gatheringInfo.vehicleName}
          </Typography>
        </Grid>
      )}
      {gatheringInfo.mtrObservation && (
        <Grid item xs={12}>
          <Typography variant="body1" data-cy="confirmation-mtrObservation">
            <strong>Observação:</strong> {gatheringInfo.mtrObservation || ''}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default GatheringGeneralInfo
