export default interface Report {
  customerIdentifier: string
  identifier?: string
  name: string
  type: string
  url: string
}

export enum ReportTypeEnum {
  FEAM = 'Feam',
}
