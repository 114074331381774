import { Button } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import AppContext, { AppContextType } from '../../../AppContext'
import InputText from '../../../components/Form/Input/Input'
import InputSelect from '../../../components/Form/Select/Select'
import InputSelect2Multiple, { Select2Options } from '../../../components/Form/Select/Select2Multiple'
import Employee, { EmployeeType, employeeTypes } from '../../../models/Employee/employee'
import { getRole } from '../../../services/Auth/TokenService'
import {
  createEmployee,
  getCustomersByEmployeeId,
  getEmployeeById,
  updateEmployee,
  updateEmployeeCustomerConnections,
} from '../../../services/Employee/EmployeeService'
import useYupValidationResolver from '../../../utils/yup-validator-resolver'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import { getAllClients } from '../../../services/Client/ClientService'
import type { CompanyMinimalInfo } from '../../../models/Company/Company'

const validationSchema = yup.object({
  cpf: yup.string().required('campo obrigatório'),
  driverLicense: yup.string().when('employeeType', {
    is: EmployeeType.MOTORISTA,
    then: yup.string().required('campo obrigatório'),
    otherwise: yup.string().nullable(),
  }),
  employeeType: yup.string().required('campo obrigatório'),
  idBranches: yup.array().required('campo obrigatório').min(1),
  name: yup.string().required('campo obrigatório'),
})

const EmployeeForm: React.FC = () => {
  const isOperador = getRole() === 'USER'
  const {
    defaultBranch,
    defaultCompany,
    setTitle,
    setCustomHeaderContent,
    setIsShowLoading,
    showAlert,
    setItemsBreadCrumb,
  } = React.useContext(AppContext as React.Context<AppContextType>)
  const resolver = useYupValidationResolver(validationSchema)
  const { control, formState, handleSubmit, reset, setValue } = useForm<Employee>({ resolver })
  const employeeTypeList = employeeTypes
  const navigate = useNavigate()
  const { id } = useParams<{ id: string | undefined }>()
  const [customers, setCustomers] = React.useState<Select2Options[]>([])

  const processUserDataForSubmit = (data: Employee): Employee => {
    return {
      ...data,
      idBranches: [0],
    }
  }

  const updateEmployeeCustomers = (customersList: string[], employeeId: number): void => {
    updateEmployeeCustomerConnections(customersList, employeeId).catch(() =>
      showAlert('error', 'Erro ao atualizar vínculos com clientes. Tente novamente mais tarde.')
    )
  }

  const onSubmit: SubmitHandler<Employee> = (data: Employee): void => {
    setIsShowLoading(true)
    const userDataWithoutCustomersList = processUserDataForSubmit(data)
    const customersListForCustomerEmployeesUpdate = data.idBranches as string[]
    if (id) {
      updateEmployee(userDataWithoutCustomersList)
        .then(() => {
          updateEmployeeCustomers(customersListForCustomerEmployeesUpdate, Number(id))
          showAlert('success', 'Funcionário alterado com sucesso.')
        })
        .catch(() => {
          showAlert('error', 'Erro ao editar funcionário. Tente novamente mais tarde.')
        })
        .finally(() => {
          setIsShowLoading(false)
          navigate('/main/employee/list')
        })
    } else {
      createEmployee(userDataWithoutCustomersList)
        .then((employee) => {
          updateEmployeeCustomers(customersListForCustomerEmployeesUpdate, employee.idEmployees as number)
          showAlert('success', 'Funcionário salvo com sucesso.')
        })
        .catch(() => {
          showAlert('error', 'Erro ao salvar funcionário. Tente novamente mais tarde.')
        })
        .finally(() => {
          setIsShowLoading(false)
          navigate('/main/employee/list')
        })
    }
  }

  const setDefaultValues = React.useCallback(
    (employee: Employee): void => {
      reset({ ...employee, idBranches: [] })
    },
    [reset]
  )

  const handleBranchesChange = (event: React.SyntheticEvent, value: Select2Options[] | null): void => {
    const branchesMapUpdate = value ? value.map((v) => v.value as string) : []
    setValue('idBranches', branchesMapUpdate)
  }

  const getUserCompaniesByEmployeeId = (): Promise<string[]> => {
    return getCustomersByEmployeeId(Number(id)).then((customersData: CompanyMinimalInfo[]) => {
      const customersIdentifiersList = customersData.map((customer) => customer.identifier)
      return customersIdentifiersList
    })
  }

  const setUserOnUpdateInfo = (): void => {
    if (id) {
      setIsShowLoading(true)
      getEmployeeById(Number(id))
        .then(async (employee) => {
          setDefaultValues(employee)
          const userCustomers = await getUserCompaniesByEmployeeId()
          setValue('idBranches', userCustomers)
        })
        .catch(() =>
          showAlert('error', 'Erro ao buscar informações do funcionário. Reinicie a página ou contate o suporte!')
        )
        .finally(() => {
          setIsShowLoading(false)
        })
    }
  }

  const setPrimayInformations = (): void => {
    setIsShowLoading(true)
    getAllClients()
      .then((clientsList: CompanyMinimalInfo[]) => {
        const customersList = clientsList.map((client) => ({ value: client.identifier, label: client.name }))
        setCustomers(customersList)
      })
      .catch(() => showAlert('error', 'Erro ao carregar clientes. Tente novamente mais tarde.'))
      .finally(() => {
        setIsShowLoading(false)
        setUserOnUpdateInfo()
      })
  }

  React.useEffect(() => {
    setPrimayInformations()
    setTitle(id ? `Editar funcionário` : `Novo funcionário`)
    setCustomHeaderContent(<div />)
  }, [id, isOperador, setCustomHeaderContent, setDefaultValues, setTitle, setValue, defaultBranch, defaultCompany])

  React.useEffect(() => {
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar funcionários', path: '/main/employee/list' },
      { label: id ? 'Editar funcionário' : 'Novo funcionário', path: '/main/employee/form' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Card className="card card--form card-forms-styles" variant="outlined">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="name" label="Nome" errorText={formState.errors?.name?.message} {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="cpf"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="cpf" label="CPF" errorText={formState.errors?.cpf?.message} {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="driverLicense"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText
                  id="driverLicense"
                  label="Licença Motorista"
                  errorText={formState.errors?.driverLicense?.message}
                  {...field}
                  value={field.value !== null ? field.value : ''}
                />
              )}
            />
          </Grid>
          {!isOperador && (
            <Grid item xs={12} sm={12}>
              <Controller
                name="employeeType"
                control={control}
                defaultValue={EmployeeType.MOTORISTA}
                render={({ field }) => (
                  <InputSelect
                    id="employeeType"
                    label="Tipo Funcionário"
                    errorText={formState.errors?.employeeType?.message}
                    options={employeeTypeList}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}
          <Grid
            container
            item
            xs={12}
            sm={12}
            sx={{
              marginTop: '24px',
              marginLeft: '24px',
              border: '1px solid #bdbdbd',
              borderRadius: '4px',
              padding: '16px',
              overflow: 'hidden',
            }}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              sx={{ padding: '5px' }}
            >
              <Button
                onClick={() => {
                  setValue('idBranches', customers.map((customer) => customer.value) as string[])
                }}
              >
                Selecionar Tudo
              </Button>
              <Button
                onClick={() => {
                  setValue('idBranches', [])
                }}
              >
                Limpar
              </Button>
            </Grid>

            <Grid item sm={12}>
              <Controller
                name="idBranches"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <InputSelect2Multiple
                    id="idBranches"
                    label="Empresa"
                    errorText=""
                    options={customers}
                    onChange={handleBranchesChange}
                    value={field.value}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '24px',
          }}
        >
          <Button variant="contained" color="primary" type="submit">
            Salvar
          </Button>
        </Grid>
      </form>
    </Card>
  )
}

export default EmployeeForm
