import React from 'react'
import { Card, FormLabel, Grid, Switch } from '@mui/material'
import { Controller, FieldError, UseFormReturn } from 'react-hook-form'
import InputText from '../../../../Form/Input/Input'
import InputSelect2Multiple, { Select2Options } from '../../../../Form/Select/Select2Multiple'
import InputSelect2 from '../../../../Form/Select/Select2'
import {
  partnerServiceTypesOptions,
  taxRegimeOptions,
} from '../../../../../models/Company/FormOptions/PrimaryInfoOptions'
import { useStyles } from '../../../CompanyFormStyle'
import InputMaskCNPJ from '../../../../Form/Input/InputMaskCNPJ'
import { PartnerFormType } from '../../../../../models/Company/Company'

interface PartnerPrimaryInfoProps {
  form: UseFormReturn<PartnerFormType>
}

const PartnerPrimaryInfo: React.FC<PartnerPrimaryInfoProps> = ({ form }) => {
  const { control, formState, setValue, watch, getValues } = form
  const companyFormclasses = useStyles()

  const handleServiceTypeChange = (_event: React.SyntheticEvent, value: Select2Options[] | null): void => {
    const types = value ? (value.map((type) => type.value) as string[]) : []
    setValue('serviceType', types)
  }

  const handleTaxRegimeChange = (_event: React.SyntheticEvent, value: Select2Options | null): void => {
    setValue('taxRegime', value?.value as string)
  }

  return (
    <Card className="form-card" variant="outlined">
      <h2 className={companyFormclasses.tabTitle}>Dados primários</h2>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} key="corporateName">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Razão Social*
            <Controller
              name="corporateName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText
                  id="corporateName"
                  label=""
                  errorText={formState.errors?.corporateName?.message}
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="cnpj">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            CNPJ*
            <Controller
              name="cnpj"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputMaskCNPJ id="cnpj" label="" errorText={formState.errors?.cnpj?.message} {...field} />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="name">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Nome*
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="name" label="" errorText={formState.errors?.name?.message} {...field} />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="serviceType">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Tipo de Serviço*
            <Controller
              name="serviceType"
              control={control}
              defaultValue={watch('serviceType') || []}
              render={({ field }) => (
                <InputSelect2Multiple
                  id="serviceType"
                  label=""
                  errorText={(formState.errors?.serviceType as unknown as FieldError)?.message}
                  options={partnerServiceTypesOptions}
                  {...field}
                  value={watch('serviceType') || []}
                  onChange={handleServiceTypeChange}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="icms" sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Somos contribuintes do ICMS?
          </FormLabel>
          <Controller
            name="icms"
            control={control}
            defaultValue={watch('icms') || false}
            render={({ field }) => (
              <Switch
                className={companyFormclasses.switchStyle}
                focusVisibleClassName=".Mui-focusVisible"
                disableRipple
                checked={field.value}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} key="taxRegime">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Regime Tributário
            <Controller
              name="taxRegime"
              control={control}
              defaultValue="Simples Nacional"
              render={({ field }) => (
                <InputSelect2
                  id="taxRegime"
                  label=""
                  errorText={(formState.errors?.taxRegime as unknown as FieldError)?.message}
                  options={taxRegimeOptions}
                  value={field.value}
                  onChange={handleTaxRegimeChange}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="branch">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Código de Unidade
          </FormLabel>
          <Controller
            name="branch"
            control={control}
            defaultValue={getValues('branch') || 0}
            render={({ field }) => (
              <div style={{ display: 'flex' }}>
                <InputText
                  id="branch"
                  label=""
                  errorText={(formState.errors?.branch as unknown as FieldError)?.message}
                  {...field}
                />
              </div>
            )}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default PartnerPrimaryInfo
