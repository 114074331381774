import React from 'react'
import InputText from './Input'
import ShowPassword from '../../ShowPassword/ShowPassword'

interface InputPasswordProps {
  id: string
  isTypePassword: boolean
  changePasswordView: () => void
  errorText?: string
  field: any
  className?: string
}

const InputPassword: React.FC<InputPasswordProps> = ({
  id,
  isTypePassword,
  changePasswordView,
  errorText,
  field,
  className,
}) => {
  return (
    <InputText
      id={id}
      label=""
      className={className}
      type={isTypePassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: <ShowPassword showPassword={isTypePassword} handleShowPassword={changePasswordView} />,
      }}
      errorText={errorText}
      autoComplete="new-password"
      {...field}
    />
  )
}

export default InputPassword
