import Card from '@mui/material/Card'
import React from 'react'
import { useParams } from 'react-router-dom'
import AppContext, { AppContextType } from '../../../AppContext'
import { useStyles } from './ReportViewerStyle'
import { getReportByIdentifier } from '../../../services/Report/ReportService'
import Report from '../../../models/Report/report'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'

const ReportViewer: React.FC = () => {
  const classes = useStyles()
  const { id } = useParams<{ id: string | undefined }>()
  const { setTitle, setCustomHeaderContent, setIsShowLoading, showAlert, setItemsBreadCrumb } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const [report, setReport] = React.useState<Report | undefined>(undefined)

  const handleInit = React.useCallback((): void => {
    setTitle('Visualizar Relatório')
    setIsShowLoading(true)
    getReportByIdentifier(id as string)
      .then((reportData) => {
        setReport(reportData)
        setTitle(`Visualizar Relatório ${reportData.name}`)
        setIsShowLoading(false)
      })
      .catch(() => {
        setIsShowLoading(false)
        showAlert('error', 'Erro ao carregar relatório. Tente novamente mais tarde.')
      })
    setCustomHeaderContent(<div />)
  }, [id, setCustomHeaderContent, setIsShowLoading, setTitle, showAlert])

  React.useEffect(() => {
    handleInit()
  }, [handleInit])

  React.useEffect(() => {
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar relatórios', path: '/main/report/list' },
      { label: 'Visualizar relatório', path: `/main/report/${id}` },
    ])
  }, [])

  return (
    <Card className="card" style={{ position: 'relative', height: '100vh' }} variant="outlined">
      <iframe className={classes.responsiveIframe} src={report?.url} frameBorder="0" title="report" allowFullScreen />
    </Card>
  )
}
export default ReportViewer
