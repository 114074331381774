import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import React from 'react'
import { GatheringRequest } from '../../../../models/Gathering/Gathering'
import GatheringCompanies from './GatheringCompanies'
import GatheringGeneralInfo from './GatheringGeneralInfo'
import GatheringResidues from './GatheringResidues'
import useStyles from './styles/GatheringConfirmationInfoModalStyle'

interface InfoConfirmationDialogProps {
  open: boolean
  handleOpen: (opened: boolean) => void
  onSubmit: () => void
  gatheringInfo: GatheringRequest
}

const InfoConfirmationDialog: React.FC<InfoConfirmationDialogProps> = ({
  open,
  handleOpen,
  onSubmit,
  gatheringInfo,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={() => handleOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      className={classes.dialog}
    >
      <DialogTitle sx={{ backgroundColor: '#43b849', color: 'white' }}>Confirmação de Dados do MTR</DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <GatheringCompanies gatheringInfo={gatheringInfo} />
        {(gatheringInfo.driverName || gatheringInfo.vehicleName || gatheringInfo.mtrObservation) && (
          <>
            <Divider />
            <GatheringGeneralInfo gatheringInfo={gatheringInfo} />
          </>
        )}
        <GatheringResidues wasteList={gatheringInfo.waste} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOpen(false)} data-cy="cancel-gathering-info">
          Cancelar
        </Button>
        <Button variant="contained" onClick={onSubmit} color="primary" autoFocus data-cy="confirm-gathering-info">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoConfirmationDialog
