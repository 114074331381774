import { Card, Button, Grid, FormLabel, Typography } from '@mui/material'
import React from 'react'
import InputText from '../../../Form/Input/Input'
import { useStyles } from '../../CompanyFormStyle'
import notesStyles from './NotesStyle'
import { ObservationsInfo } from '../../../../models/Company/Company'
import treatGMTZeroDates from '../../../../utils/masks/treatGMTZeroDates'

interface NotesProps {
  notes: ObservationsInfo[]
  postNote: (event: React.SyntheticEvent<any>, value: string) => void
  noteInProgress: string
  setNoteInProgress: (value: string) => void
}

const Notes: React.FC<NotesProps> = ({ postNote, notes, noteInProgress, setNoteInProgress }) => {
  const companyFormClasses = useStyles()
  const notesStyle = notesStyles()

  return (
    <Card className="form-card" variant="outlined">
      <h2 className={companyFormClasses.tabTitle}>Observações</h2>
      <Grid container>
        {notes.length > 0 ? (
          notes.map((note, i) => {
            return (
              <Grid item xs={12} sm={12} className={notesStyle.noteContainerStyle} key={`note-${note.createdAt}`}>
                <Typography>{note.observation}</Typography>
                <Typography className={notesStyle.noteDateStyle}>
                  {note.createdBy} - {note.createdAt}
                </Typography>
              </Grid>
            )
          })
        ) : (
          <Grid item xs={12} sm={12} className={notesStyle.noteContainerStyle} key="no-note">
            <Typography>Nenhuma observação</Typography>
          </Grid>
        )}
      </Grid>
      <Grid container className={notesStyle.newNoteGridStyle}>
        <Grid item xs={12} sm={12} key="note">
          <FormLabel component="label" className={companyFormClasses.labelStyle}>
            Nova Observação
            <InputText
              id="note"
              label=""
              value={noteInProgress}
              onChange={({ target }) => setNoteInProgress(target.value)}
              multiline
              rows={4}
            />
          </FormLabel>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '24px',
          }}
        >
          <Button
            variant="outlined"
            type="button"
            onClick={() => setNoteInProgress('')}
            sx={{
              width: '150px',
              borderRadius: '40px',
              marginRight: '20px',
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => postNote(e, noteInProgress)}
            sx={{
              width: '150px',
              borderRadius: '40px',
            }}
          >
            Criar
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Notes
