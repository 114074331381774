import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noteContainerStyle: {
      '&.MuiGrid-root': {
        height: '15%',
        padding: '15px',
        backgroundColor: '#F8FBF9',
        border: '1px solid #E9E9E8',
        borderRadius: '6px',
        marginLeft: '0',
        marginBottom: '20px',
      },
    },
    noteStyle: {
      '&.MuiTypography-root': {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#484946',
      },
    },
    noteDateStyle: {
      '&.MuiTypography-root': {
        textAlign: 'right',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        padding: '10px',
        color: '#A3A4A3',
      },
    },
    newNoteGridStyle: {
      marginTop: '20px',
    },
  })
)

export default useStyles
