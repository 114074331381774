import { Chip, CircularProgress } from '@mui/material'
import React from 'react'
import { CreateCSSProperties } from '@mui/styles'
import { retrieveFeamStatus } from '../../services/FEAM/FeamService'
import AppContext, { AppContextType } from '../../AppContext'

interface FeamStatusSignProps {
  customerCnpj: string
  style: React.CSSProperties | CreateCSSProperties
}

const FeamStatusSign: React.FC<FeamStatusSignProps> = ({ customerCnpj, style }) => {
  const [feamStatus, setFeamStatus] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const { showAlert } = React.useContext(AppContext as React.Context<AppContextType>)

  const feamStatusHandler = async (): Promise<void> => {
    setLoading(true)
    try {
      const statusResponse = await retrieveFeamStatus({ cnpj: customerCnpj })
      switch (statusResponse.code) {
        case 200:
          setFeamStatus(true)
          break
        default:
          setFeamStatus(false)
      }
    } catch (error) {
      showAlert('error', 'Erro ao verificar status do sistema FEAM')
    } finally {
      setLoading(false)
    }
  }

  const renderFeamStatusInfo = (): JSX.Element => {
    if (loading) {
      return <CircularProgress sx={{ marginRight: '50px' }} />
    }
    return feamStatus ? (
      <Chip sx={style} label="Sistema FEAM: No ar" color="primary" />
    ) : (
      <Chip sx={style} label="Sistema FEAM: Fora do ar" color="error" />
    )
  }

  React.useEffect(() => {
    feamStatusHandler()
  }, [])

  return renderFeamStatusInfo()
}

export default FeamStatusSign
