import React from 'react'
import { FormLabel, Grid } from '@mui/material'
import { ClientResponseI } from '../../../models/Client/Client'
import Contact from '../utils/Contact'
import useStyles from '../Styles/ViewInfoModalStyle'

interface ClientContactDataProps {
  client: ClientResponseI
}

const ClientContactData: React.FC<ClientContactDataProps> = ({ client }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} key="contact-title" sx={{ '&.MuiGrid-item': { height: '65px' } }}>
        <h2 className={classes.tabTitle}>Contatos</h2>
      </Grid>
      {client.contacts.length > 0 ? (
        <Grid item xs={12} key="contacts-list">
          {client.contacts.map((contact) => (
            <Contact contact={contact} />
          ))}
        </Grid>
      ) : (
        <Grid item xs={12} key="no-contact">
          <FormLabel>Nenhum contato cadastrado</FormLabel>
        </Grid>
      )}
    </Grid>
  )
}

export default ClientContactData
