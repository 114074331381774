import { Order } from '../../utils/sort'
import { WasteModel } from '../MtrModel/MtrModel'

export enum mtrStateEnum {
  WAITING_FEAM = 'Aguardando Feam',
  CANCELED = 'Cancelado',
  SAVED = 'Salvo',
  INTERNAL_ERROR = 'Erro Interno',
  SERVER_ERROR = 'Servidor instável',
  FEAM_ERROR = 'Servidor da FEAM instável',
  UNKNOWN_ERROR = 'Erro desconhecido',
  IP_ERROR = 'IP não cadastrado',
  BAD_CREDENTIALS = 'Usuário incompatível com o cadastrado na FEAM',
  FEAM_COMMUNICATION = 'Erro de comunicação com a FEAM',
}

export default interface Gathering {
  barCode?: string
  urlPdf?: string
  urlPdfCdf?: string // inserido novo
  cdfCod?: string
  cdfEmissionDate?: Date
  generatorAccountableName: string
  generatorAccountablePosition: string
  generatorIdentifier: string
  generatorDocument: string
  generatorName: string
  generatorUnit?: string
  idCollections?: number
  manifestCod?: string
  manifestEmissionDate?: Date
  manifestReceivedDate?: Date
  mtrObservation?: string
  mtrState: mtrStateEnum
  mtrStateDetails?: string
  receiverIdentifier: string
  receiverDocument: string
  receiverName: string
  receiverUnit?: string
  transporterIdentifier: string
  transporterDocument: string
  transporterName: string
  transporterDriverName: string
  transporterExpeditionDate?: Date
  transporterUnit?: string
  transporterVehiclePlaque: string
  waste: WasteModel[]
}

export interface GatheringRequest {
  idCollections?: number
  accountable: string
  generatorIdentifier: string
  transporterIdentifier: string
  receiverIdentifier: string
  driverName: string
  vehicleName: string
  mtrState: mtrStateEnum
  manifestEmissionDate?: Date
  mtrObservation?: string
  waste: WasteModel[]
}

export interface GatheringPaginated {
  content: Gathering[]
  totalElements: number
  pageable: {
    pageNumber: number
  }
}

export interface GatheringToDatatable {
  content: Gathering[]
  currentPage: number
}

export interface CancelGathering {
  generatorDocument: string
  justify: string
  manifestCode: string
}

export interface DateRangeInterface {
  startDate?: Date
  endDate?: Date
}

export interface DateRangeString {
  startDate?: string
  endDate?: string
}

export interface GatheringParamsString {
  range?: DateRangeString
  company?: string
  sortBy?: string
  sort?: Order
}

export interface GatheringParamsDate {
  range?: DateRangeInterface
  company?: string
}
