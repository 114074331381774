import { FinancialTransactionInfo } from '../Client/Client'
import { PartnerDocumentInfos, PartnersDocuments, Documents3 } from '../Partner/Partner'

export enum CompanyType {
  CLIENTE = 'CLIENTE',
  PARCEIRO = 'PARCEIRO',
}

export const partnerTypes = [
  { value: 'TRANSPORTADOR', label: 'Transportador' },
  { value: 'DESTINADOR', label: 'Destinador' },
  { value: 'ARMAZENADOR_TEMPORARIO', label: 'Armazenador Temporário' },
]

export const companyTypes = [{ value: 'GERADOR', label: 'Gerador' }, ...partnerTypes]

export interface CompanyMinimalInfo {
  identifier: string
  name: string
  cnpj: string
}

export interface Branch {
  branchCode: number
  name: string
  idBranch: number
}

export interface CompanyAddress {
  cep: string
  state: string
  city: string
  district: string
  street: string
  number: number
  complement: string
}

export interface CompanyContactsInfo {
  name: string
  surname: string
  telephone: string
  email: string
  type?: string
  identifier?: string
}

export interface FormContacts {
  financeContact: CompanyContactsInfo
  operationalContact: CompanyContactsInfo
  commercialContact: CompanyContactsInfo
  legalContact: CompanyContactsInfo
  managementContact: CompanyContactsInfo
}

export interface ObservationsInfo {
  createdAt: Date | string
  createdBy: string
  observation: string
  idOberservation?: number
}

export interface CompanyFormType extends CompanyAddress {
  branch: number
}

interface PartnerFormPrimaryInfo {
  name: string
  cnpj: string
  corporateName: string
  serviceType: string[]
  icms: boolean
  taxRegime: string
}

export interface activeCityInfo {
  id: string
  name: string
  state: string
}

interface PartnerFormTechnicalInfo {
  residues?: string[]
  operationStatus: string
  homologationStatus: string
  companysFacade?: File
  companysFacadeLink?: string
  statesActive: string[]
  citiesActive: string[]
  trucksQuantity: number
  employeesQuantity: number
}

interface PartnerFormFinancialInfo {
  bankCode: number
  bankBranch: number
  accountNumber: number
  maxPaymentTerm: number
}

interface PartnersFormDocuments {
  documentationLink: string
  documents: PartnersDocuments
}

interface PartnersFormDocuments3 {
  documents3: Documents3
}

export interface PartnerFormType
  extends CompanyFormType,
    PartnerFormPrimaryInfo,
    PartnerFormTechnicalInfo,
    PartnerFormFinancialInfo,
    FormContacts,
    PartnersFormDocuments,
    PartnersFormDocuments3 {
  observations?: ObservationsInfo[] | null
}

interface ClientFormPrimaryInfo {
  companyName: string
  cnpj: string
  corporateName: string
  companysFacade?: File
  companysFacadeLink?: string
  greenMonitorLink?: string
}

interface ClientFormContractInfo {
  cashbackPercentage: number
  paymentTime: number
  purchasedPlan: string
  status: boolean
  minimumValue: number
  expirationDate: Date | string
  expirationStatus: string
  contractFile?: File
  contractFileLink?: string
  contractLatestVersion?: number
  contractIdentifier?: string
}

interface ClientFormSystemInfo {
  system: string
  cpf: string
  password: string
}

export interface ClientFormType
  extends CompanyFormType,
    ClientFormPrimaryInfo,
    FinancialTransactionInfo,
    ClientFormContractInfo,
    ClientFormSystemInfo {
  contacts: FormContacts
}

export interface BranchesInfo {
  branchCode: number
  name?: string
  idBranch?: number
  idCompany?: number
}

export interface CitiesListInfo {
  idCode: number | string
  name: string
  state: string
}

export interface CompanyInfo {
  name: string
  idCompany?: number
  corporateName: string
  cnpj: string
  greenMonitorLink?: string
  address: CompanyAddress
  contacts: CompanyContactsInfo[]
  branches: BranchesInfo[] | undefined
  customer: {
    name: string
  } | null
  observation: ObservationsInfo[] | null
  partner: {
    documentationLink: string
    documents: PartnerDocumentInfos[] | null
    financialInformation: PartnerFormFinancialInfo
    icmsTaxpayer: boolean
    taxRegime: string
    technicalData: {
      cities: CitiesListInfo[]
      companyFacadeFile: File | null
      companyFacadeLink?: string
      employeeQuantity: number
      homologStatus: string
      operationStatus: string
      residues: {
        name: string
      }[]
      truckQuantity: number
    }
  }
  type: string[]
  oldCompanyType?: string
}
