import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ClientFormType } from '../../../../../models/Company/Company'
import ClientContacts from './ClientContacts'

interface ClientContactsTabProps {
  form: UseFormReturn<ClientFormType>
}

const ClientContactsTab: React.FC<ClientContactsTabProps> = ({ form }) => {
  return <ClientContacts form={form} />
}

export default ClientContactsTab
