import { CompanyMinimalInfo } from '../models/Company/Company'

export const getCompanyByIdentifier = (
  companies: CompanyMinimalInfo[],
  companyIdentifier: string
): CompanyMinimalInfo | undefined => {
  return companies.find((c) => c.identifier === companyIdentifier)
}

const isDefaultCompanyIncluded = (companies: CompanyMinimalInfo[], defaultCompany: CompanyMinimalInfo): boolean => {
  return companies.some((company) => company.identifier === defaultCompany.identifier)
}

const getInitialSelectedCompany = (
  companies: CompanyMinimalInfo[],
  defaultCompany: CompanyMinimalInfo | undefined
): CompanyMinimalInfo => {
  return defaultCompany && isDefaultCompanyIncluded(companies, defaultCompany) ? defaultCompany : companies[0]
}

export default getInitialSelectedCompany
