import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import PartnerDocuments from './components/PartnerDocuments'
import { PartnerFormType } from '../../../../models/Company/Company'

interface PartnerDocumentsProps {
  form: UseFormReturn<PartnerFormType>
}

const PartnerDocumentsTab: React.FC<PartnerDocumentsProps> = ({ form }) => {
  return <PartnerDocuments form={form} />
}

export default PartnerDocumentsTab
