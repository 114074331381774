import { IconButton, Typography } from '@mui/material'
import Popover from '@mui/material/Popover'
import React from 'react'

export type PopOverProps = {
  element: JSX.Element | null
  popOverMessage: string
  handleClick?: (item: any) => void
  item?: any
}

const PopOverMessage: React.FC<PopOverProps> = ({ element, popOverMessage, handleClick, item }) => {
  const [opened, setOpened] = React.useState<boolean>(false)
  const [anchor, setAnchor] = React.useState<HTMLElement | SVGSVGElement | null>(null)

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement | SVGSVGElement>): void => {
    setOpened(true)
    setAnchor(event.currentTarget)
  }

  const handleMouseLeave = (): void => {
    setOpened(false)
    setAnchor(null)
  }

  const renderPopOver = (open: boolean, message: string): JSX.Element => {
    return (
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleMouseLeave}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{message}</Typography>
      </Popover>
    )
  }

  return (
    <IconButton onClick={handleClick && (() => handleClick(item))}>
      <Typography
        aria-owns={opened ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {element}
        {renderPopOver(opened, popOverMessage)}
      </Typography>
    </IconButton>
  )
}

export default PopOverMessage
