import React from 'react'
import { Card, FormLabel, Grid, Switch } from '@mui/material'
import { Controller, FieldError, UseFormReturn } from 'react-hook-form'
import { CompanyFormType } from '../../../../models/Company/Company'
import InputText from '../../../Form/Input/Input'
import InputSelect2Multiple, { Select2Options } from '../../../Form/Select/Select2Multiple'
import InputSelect2 from '../../../Form/Select/Select2'
import { getAddressData } from '../../../../services/Correios/CorreiosSevice'
import InputMaskCEP from '../../../Form/Input/InputMaskCEP'
import { useStyles } from '../../CompanyFormStyle'

interface AddressProps {
  form: UseFormReturn<any>
}

const Address: React.FC<AddressProps> = ({ form }) => {
  const { control, formState, setValue } = form
  const companyFormclasses = useStyles()

  const executeAddressSearch = (cep: string): void => {
    getAddressData(cep).then((data) => {
      setValue('district', data.bairro)
      setValue('street', data.logradouro)
      setValue('city', data.localidade)
      setValue('state', data.uf)
      setValue('complement', data.complemento)
    })
  }

  const handleCepChange = (event: React.FormEvent<HTMLDivElement>): void => {
    let cep = (event.target as HTMLInputElement).value
    cep = cep.replace(/[-.]/g, '')
    if (cep.length === 8) {
      executeAddressSearch(cep)
    }
  }

  return (
    <Card className="form-card" variant="outlined">
      <h2 className={companyFormclasses.tabTitle}>Endereço</h2>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} key="cep">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            CEP
            <Controller
              name="cep"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputMaskCEP
                  id="cep"
                  label=""
                  errorText={formState.errors?.cep?.message}
                  {...field}
                  onInput={handleCepChange}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="state">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Estado
            <Controller
              name="state"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="state" label="" errorText={formState.errors?.state?.message} {...field} />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="city">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Cidade
            <Controller
              name="city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="city" label="" errorText={formState.errors?.city?.message} {...field} />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="district">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Bairro
            <Controller
              name="district"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="district" label="" errorText={formState.errors?.district?.message} {...field} />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} key="street">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Logradouro
            <Controller
              name="street"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="street" label="" errorText={formState.errors?.street?.message} {...field} />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="number">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Número
            <Controller
              name="number"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <InputText id="number" label="" errorText={formState.errors?.number?.message} {...field} />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="complement">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Complemento
            <Controller
              name="complement"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="complement" label="" errorText={formState.errors?.complement?.message} {...field} />
              )}
            />
          </FormLabel>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Address
