import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    containerGrid: {
      display: 'flex',
    },
    typography: {
      marginRight: '10px',
    },
    separator: {
      color: 'white',
      margin: '0 -2px',
    },
  })
)

const smoothWhite = 'rgb(255, 255, 255, 0.5)'
const white = 'rgb(255, 255, 255)'

export default useStyles
export { smoothWhite, white }
