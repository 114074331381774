import axios, { AxiosResponse } from 'axios'
import getEndpointUrl from '../../utils/url-resolver'
import { FinancialTransactionInfo, FinancialTransactionRequest } from '../../models/Client/Client'

const baseUrl = `${getEndpointUrl('company')}/v1/financial-transactions`

const getFinancialInfoByClient = async (identifier: string): Promise<FinancialTransactionInfo[]> => {
  return axios.get(`${baseUrl}/customer/${identifier}`).then((response) => response.data)
}

const getPlannedFinancialInfo = async (identifier: string): Promise<FinancialTransactionInfo[]> => {
  return axios.get(`${baseUrl}/customer/${identifier}/planned`).then((response) => response.data)
}

const getExecutedFinancialInfo = async (identifier: string): Promise<FinancialTransactionInfo[]> => {
  return axios.get(`${baseUrl}/customer/${identifier}/executed/current`).then((response) => response.data)
}

const getExecutedFinancialInfoByMonth = async (
  identifier: string,
  month: number
): Promise<FinancialTransactionInfo[]> => {
  return axios.get(`${baseUrl}/customer/${identifier}/executed/current/${month}`).then((response) => response.data)
}

const createFinancialTransaction = async (request: FinancialTransactionRequest): Promise<FinancialTransactionInfo> => {
  return axios.post(`${baseUrl}`, request).then((response) => response.data)
}

const updateFinancialTransaction = async (
  request: FinancialTransactionRequest,
  identifier: string
): Promise<FinancialTransactionInfo> => {
  return axios.put(`${baseUrl}/${identifier}`, request).then((response) => response.data)
}

const deleteFinancialTransaction = async (identifier: string): Promise<AxiosResponse> => {
  return axios.delete(`${baseUrl}/${identifier}`).then((response) => response.data)
}

export {
  getFinancialInfoByClient,
  getPlannedFinancialInfo,
  getExecutedFinancialInfo,
  getExecutedFinancialInfoByMonth,
  createFinancialTransaction,
  updateFinancialTransaction,
  deleteFinancialTransaction,
}
