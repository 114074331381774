import ReactGA from 'react-ga4'
import { getEnv } from './url-resolver'
import AnalyticsEvent from '../models/GoogleAnalytics/Event'

const handleReactGA = async (env: string): Promise<void> => {
  if (env === 'localhost') return
  const id = env === 'prod' ? 'G-6ZNPP6PQGD' : 'G-4MWNF72WVJ'
  ReactGA.initialize(id)
}

export const handleReactGAEvent = (event: AnalyticsEvent): void => {
  const env = getEnv(window.location.host)
  if (env === 'localhost') return
  ReactGA.event(event)
}

export default handleReactGA
