import { getUserCargos, getUserFeatures } from '../services/Auth/TokenService'
import allPagesInfo, { IPageInfo } from '../models/Pages/AllPagesInfo'

export const getUserAllowedPages = (): IPageInfo[] => {
  const features = getUserFeatures()
  const userCargos = getUserCargos()!.map((cargo) => cargo.name)

  const allowedPages = allPagesInfo.filter((page) => features?.includes(page.feature))

  if (userCargos.includes('Clientes')) {
    return allowedPages.filter((page) => page.feature !== 'customer-read' && page.feature !== 'partner-read')
  }

  return allowedPages
}

export const isUserAllowedInPage = (location: string): IPageInfo[] => {
  return getUserAllowedPages().filter((page) => location.match(page.link))
}

const isRouteAuthorized = (location: string): boolean => {
  return !!isUserAllowedInPage(location).length
}

export const featureAccessCheck = (feature: string): boolean => {
  const features = getUserFeatures()
  return features?.includes(feature) || false
}

export const iconAccessCheck = (feature: string): boolean => {
  if (featureAccessCheck(feature)) {
    return true
  }
  return false
}

export default isRouteAuthorized
