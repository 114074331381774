import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ClientFormType } from '../../../../models/Company/Company'
import ClientContractData from './components/ClientContractData'

interface ClientContractsTabProps {
  form: UseFormReturn<ClientFormType>
}

const ClientContractsTab: React.FC<ClientContractsTabProps> = ({ form }) => {
  return <ClientContractData form={form} />
}

export default ClientContractsTab
