import React from 'react'
import { Grid } from '@mui/material'
import * as yup from 'yup'

interface NewPasswordGuideProps {
  validationStatus: {
    length: boolean
    number: boolean
    uppercase: boolean
    lowercase: boolean
    specialChar: boolean
  }
}

const NewPasswordGuide: React.FC<NewPasswordGuideProps> = ({ validationStatus }) => {
  return (
    <div>
      <Grid item>
        <h3>Sua nova senha deve conter:</h3>
      </Grid>
      <Grid item>
        <ul>
          <li data-cy="minimum-character-guide" style={{ color: validationStatus.length ? '#33B652' : '#AA4A44' }}>
            No mínimo 12 caracteres
          </li>
          <li data-cy="minimum-one-number" style={{ color: validationStatus.number ? '#33B652' : '#AA4A44' }}>
            Pelo menos um número
          </li>
          <li
            data-cy="minimum-one-upper-character"
            style={{ color: validationStatus.uppercase ? '#33B652' : '#AA4A44' }}
          >
            Pelo menos uma letra maiúscula
          </li>
          <li
            data-cy="minimum-one-lower-character"
            style={{ color: validationStatus.lowercase ? '#33B652' : '#AA4A44' }}
          >
            Pelo menos uma letra minúscula
          </li>
          <li
            data-cy="minimum-one-special-character"
            style={{ color: validationStatus.specialChar ? '#33B652' : '#AA4A44' }}
          >
            Pelo menos um caractere especial
          </li>
        </ul>
      </Grid>
    </div>
  )
}

export default NewPasswordGuide
