import React from 'react'
import Card from '@mui/material/Card'
import AppContext, { AppContextType } from '../../AppContext'
import Menu from '../../components/Menu/Menu'
import useStylesHome from './HomeStyles'
import { defaultBreadCrumbItems } from '../../components/BreadCrumb/BreadCrumb'
import InputSelect2, { Select2Options } from '../../components/Form/Select/Select2'
import { getAllClients, getAllGreenMonitorUrl, getGreenMonitorUrl } from '../../services/Client/ClientService'
import { ClientGreenMonitor } from '../../models/Client/Client'
import NotificationModal from '../../components/NotificationModal/NotificationModal'

const Home: React.FC = () => {
  const { showAlert, setTitle, setCustomHeaderContent, setItemsBreadCrumb } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const classes = useStylesHome()
  const [url, setUrl] = React.useState<ClientGreenMonitor | undefined>()
  const [allUrls, setAllUrls] = React.useState<ClientGreenMonitor[]>([])
  const [companyOptions, setCompanyOptions] = React.useState<Select2Options[]>([])
  const [showFilter, setShowFilter] = React.useState<boolean>(false)

  const randomHomeImages = `https://s3.amazonaws.com/teste.recicla.club/home/${Math.floor(Math.random() * 5) + 1}.png`

  const handleChangeCompany = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    setUrl(allUrls.find((c) => c.customerIdentifier === value?.value) as ClientGreenMonitor)
  }

  const formatCompanyOptions = (): void => {
    getAllGreenMonitorUrl().then((response) => {
      const options = response.map((c) => ({
        value: c.customerIdentifier,
        label: c.customerName,
      }))
      setCompanyOptions(options)
    })
  }

  const handleGreenMonitor = (): void => {
    getAllGreenMonitorUrl()
      .then((response) => {
        setUrl(response[0])
        setAllUrls(response)
        setShowFilter(response.length > 1)
      })
      .catch(() => {
        setUrl(undefined)
        setShowFilter(false)
        showAlert('error', 'Erro ao carregar links dos monitores verdes. Tente novamente mais tarde.')
      })
  }

  React.useEffect(() => {
    setTitle('Início')
    setCustomHeaderContent(<div />)
    setItemsBreadCrumb(defaultBreadCrumbItems)
    handleGreenMonitor()
    formatCompanyOptions()
  }, [])

  return (
    <>
      <NotificationModal />
      <Card className={url ? classes.homePageContainer : ''}>
        {showFilter && (
          <InputSelect2
            id="companies"
            label="Cliente"
            onChange={handleChangeCompany}
            options={companyOptions}
            value={url?.customerIdentifier}
            sx={{
              maxWidth: '20%',
              marginLeft: '0.5vw',
              position: 'absolute',
              right: '0',
              color: 'white',
              paddingTop: '1vh',
              paddingRight: '3vh',
            }}
            textFieldStyle={{
              variant: 'filled',
              focused: true,
            }}
          />
        )}
        {url ? (
          <iframe
            title="green-monitor"
            src={url.link}
            allowFullScreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          />
        ) : (
          <img className="home-card-img" src={randomHomeImages} alt="home-pic" />
        )}
        <div className="mobile--visible" style={{ width: '100%', marginTop: '16px' }}>
          <Menu />
        </div>
      </Card>
    </>
  )
}

export default Home
