import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    generalContainer: {
      height: '100%',
      padding: '30px',
      width: '40vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '&.MuiPaper-root': {
        overflowY: 'scroll',
      },
    },
    nameImput: { marginBottom: '20px', width: '100%' },
    featsContainer: { border: '1px solid lightgrey', borderRadius: '10px', marginBottom: '20px', marginTop: '10px' },
    accordionContainer: {
      boxShadow: 'none',
      width: '100%',
      margin: 'none',
      '&.Mui-expanded': {
        marginBottom: '0px',
      },
    },
    accordionSummary: {
      display: 'flex',
      alignItems: 'center',
      '&.Mui-expanded': {
        borderBottom: '1px solid #d1d1d1',
      },
    },
    checkBoxSummaryContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' },
    checkBox: {
      color: 'lightgrey',
      '&.Mui-checked': {
        color: '#43b849',
      },
    },
    featContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
      alignItems: 'center',
      borderBottom: '1px solid #d1d1d1',
    },
    feat: { display: 'flex', justifyContent: 'space-between', width: '100%' },
    submitButton: {
      borderRadius: '50px',
      width: '250px',
      fontSize: '12px',
      marginRight: '10%',
      height: '50px',
    },
  })
)
