import axios from 'axios'

import getEndpointUrl from '../../utils/url-resolver'
import { featuresType } from '../../models/Setting/Settings'

const baseUrl = `${getEndpointUrl('company')}/v1/feature`

const getAllFeatures = (): Promise<featuresType[]> => {
  return axios.get<featuresType[]>(`${baseUrl}/`).then((response) => response.data)
}

const getFeatureById = (id: number): Promise<featuresType> => {
  return axios.get<featuresType>(`${baseUrl}/${id}`).then((response) => response.data)
}

const updateFeature = (featureBody: featuresType): Promise<void> => {
  return axios.put(`${baseUrl}/`, featureBody).then((response) => response.data)
}

export { getAllFeatures, getFeatureById, updateFeature }
