export const partnerServiceTypesOptions = [
  {
    value: 'TRANSPORTADOR',
    label: 'Transportador',
  },
  {
    value: 'DESTINADOR',
    label: 'Destinador',
  },
  {
    value: 'ARMAZENADOR_TEMPORARIO',
    label: 'Armazenador Temporário',
  },
]

export const taxRegimeOptions = [
  {
    value: 'Simples Nacional',
    label: 'Simples Nacional',
  },
  {
    value: 'Lucro Presumido',
    label: 'Lucro Presumido',
  },
  {
    value: 'Lucro Real',
    label: 'Lucro Real',
  },
]
