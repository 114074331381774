import { Button } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import * as yup from 'yup'

import AppContext, { AppContextType } from '../../../AppContext'
import DataTable from '../../../components/DataTable/DataTable'
import InputText from '../../../components/Form/Input/Input'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import { GatheringRequest, mtrStateEnum } from '../../../models/Gathering/Gathering'
import MtrModel, { type MtrModelMinimalResponse, MtrModelResponse, WasteModel } from '../../../models/MtrModel/MtrModel'
import { createGathering } from '../../../services/Gathering/GatheringService'
import useYupValidationResolver from '../../../utils/yup-validator-resolver'
import WasteFormDialog from '../WasteFormDialog/WasteFormDialog'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import Icons from '../../../assets/icons/svgIcons'
import EditDeletePopover from '../../../components/PopOver/EditDeletePopover'
import { ApplicationColors } from '../../../utils/applicationColors'
import { PartnerMinimalResponse } from '../../../models/Partner/Partner'
import { getPartnersByCustomerIdentifier } from '../../../services/Client/ClientPartnerService'
import Maintenance from '../../../components/Maintenance/Maintenance'
import { getModelsByCustomerIdentifier, getMtrModelByIdentifier } from '../../../services/MtrModel/MtrModelService'
import { getAllCustomers } from '../../../services/Companies/CompaniesService'
import type { CompanyMinimalInfo } from '../../../models/Company/Company'
import { getTokenCompany } from '../../../services/Auth/TokenService'
import getInitialSelectedCompany, { getCompanyByIdentifier } from '../../../utils/getInitialSelectedCompany'
import { handleReactGAEvent } from '../../../utils/handleReactAnalytics'
import FeamStatusSign from '../../../components/FeamStatusSign/FeamStatusSign'
import InfoConfirmationDialog from './GatheringInfoConfirmationDialog/InfoConfirmationDialog'
import { ItemType } from '../../../utils/sort'

interface GatheringFormProps {
  isPageInMaintenance: boolean
}

const GatheringForm: React.FC<GatheringFormProps> = ({ isPageInMaintenance }) => {
  const {
    setTitle,
    setCustomHeaderContent,
    setIsShowLoading,
    showAlert,
    setItemsBreadCrumb,
    defaultCompany,
    setDefaultCompany,
  } = React.useContext(AppContext as React.Context<AppContextType>)

  const validationSchema = yup.object({
    generatorIdentifier: yup.string().required('campo obrigatório'),
    receiverIdentifier: yup
      .string()
      .required('campo obrigatório')
      .test('receiver-check', 'Adicione um Destinador', function (value) {
        if (!value) {
          showAlert('warning', 'Adicione os parceiros')
          return false
        }
        return true
      }),
    transporterIdentifier: yup
      .string()
      .required('campo obrigatório')
      .test('transporter-check', 'Adicione um Transportador', function (value) {
        if (!value) {
          showAlert('warning', 'Adicione os parceiros')
          return false
        }
        return true
      }),
    driverName: yup.string().nullable(),
    vehicleName: yup.string().nullable(),
    mtrObservation: yup.string(),
    mtrState: yup.string().required('campo obrigatório'),
    waste: yup
      .array()
      .required('campo obrigatório')
      .min(1, 'Deve haver pelo menos um item')
      .test('waste-array-check', 'Adicione ao menos um resíduo', function (value) {
        if (!value || value.length < 1) {
          showAlert('warning', 'Adicione ao menos um resíduo')
          return false
        }
        return true
      }),
  })

  const resolver = useYupValidationResolver(validationSchema)
  const { control, formState, handleSubmit, setValue, getValues, reset, watch } = useForm<GatheringRequest>({
    resolver,
  })
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [anchor, setAnchor] = React.useState<any>(null)
  const [itemToChange, setItemToChange] = React.useState<WasteModel>()
  const [wasteDialogOpen, setOpen] = React.useState<boolean>(false)
  const [customers, setCustomers] = React.useState<CompanyMinimalInfo[]>([])
  const [customersOptions, setCustomersOptions] = React.useState<{ value: string; label: string }[]>([])
  const [partnersOptions, setPartnersOptions] = React.useState<{ value: string; label: string }[]>([])

  const [waste, setWaste] = React.useState<WasteModel | null>(null)
  const [models, setModels] = React.useState<MtrModelMinimalResponse[]>([])
  const [selectedModel, setSelectedModel] = React.useState<string>('')
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState<boolean>(false)

  const handleClickOpen = (): void => {
    setWaste(null)
    setOpen(true)
  }

  const handleClose = (): void => {
    setWaste(null)
    setOpen(false)
  }

  const handleClosePopover = (): void => {
    setIsOpen(false)
  }

  const handleEditWaste = (item: WasteModel): void => {
    setOpen(true)
    setWaste(item as WasteModel)
    setIsOpen(false)
  }

  const handleEditWasteClickItem = (item: ItemType<string>): void => {
    setOpen(true)
    setWaste(item as unknown as WasteModel)
  }

  const handleDeleteWaste = (item: WasteModel): void => {
    const selectedWastes = getValues('waste')
    if (selectedWastes.length === 1) {
      setValue('waste', [])
    } else {
      const wasteWithoutThis = selectedWastes.filter((w) => item.wasteCode !== w.wasteCode)
      setValue('waste', wasteWithoutThis)
    }
    setIsOpen(false)
  }

  const handleSaveWaste = (wasteData: WasteModel): void => {
    const selectedWastes = getValues('waste')
    const wasteObj = { ...wasteData }
    wasteObj.densityType = Number(wasteData.densityType) as number
    if (waste) {
      const newValue = selectedWastes.map((item) => {
        if (waste.wasteCode === item.wasteCode) {
          return wasteObj
        }
        return item
      })
      setValue('waste', newValue)
    } else if (selectedWastes) {
      const newWasteData = [...selectedWastes, wasteObj]
      setValue('waste', newWasteData)
    } else {
      setValue('waste', [wasteObj])
    }

    setWaste(null)
  }

  const setGeneratorValues = (generatorIdentifier: string): void => {
    const customer = getCompanyByIdentifier(customers, generatorIdentifier)
    if (customer) {
      setDefaultCompany(customer)
    }
    setValue('generatorIdentifier', generatorIdentifier)
  }

  const setTransporterValues = (transporterIdentifier: string): void => {
    setValue('transporterIdentifier', transporterIdentifier)
  }

  const setReceiverValues = (receiverIdentifier: string): void => {
    setValue('receiverIdentifier', receiverIdentifier)
  }

  const handleCustomerData = (customersList: CompanyMinimalInfo[]): void => {
    setCustomersOptions(customersList.map((c) => ({ value: c.identifier as string, label: c.name })))
  }

  const handlePartnersData = (partnersList: PartnerMinimalResponse[]): void => {
    setPartnersOptions(partnersList.map((p) => ({ value: p.identifier, label: p.name })))
  }

  const setPartnersFromSelectedCustomer = async (customerIdentifier: string): Promise<void> => {
    const partners = await getPartnersByCustomerIdentifier(customerIdentifier)
    handlePartnersData(partners)
    const partnerIdentifier = partners[0]?.identifier
    if (partnerIdentifier) {
      setTransporterValues(partnerIdentifier)
      setReceiverValues(partnerIdentifier)
    } else {
      setTransporterValues('')
      setReceiverValues('')
      showAlert('info', 'Nenhum parceiro vinculado a este cliente.')
    }
  }

  const setModelsByCustomerIdentifier = async (identifier: string): Promise<void> => {
    try {
      const modelsFromCustomer = await getModelsByCustomerIdentifier(identifier)

      if (!modelsFromCustomer) {
        showAlert('error', 'Erro ao carregar modelos de MTR.')
        setModels([])
      } else if (modelsFromCustomer.length === 0) {
        showAlert('info', 'Não há modelos de MTR cadastrados para este cliente.')
        setModels([])
      } else {
        setModels(modelsFromCustomer)
      }
    } catch (error) {
      showAlert('error', 'Erro ao carregar modelos de MTR.')
      setModels([])
    }
  }

  const handleTransporterChange = async (event: React.SyntheticEvent, value: Select2Options | null): Promise<void> => {
    setIsShowLoading(true)
    const transporterIdentifier = value?.value as string
    setTransporterValues(transporterIdentifier)
    setIsShowLoading(false)
  }

  const handleReceiverChange = async (event: React.SyntheticEvent, value: Select2Options | null): Promise<void> => {
    setIsShowLoading(true)
    setReceiverValues(value?.value as string)
    setIsShowLoading(false)
  }

  const handleGeneratorChange = async (event: React.SyntheticEvent, value: Select2Options | null): Promise<void> => {
    setIsShowLoading(true)
    try {
      const generatorIdentifier = value?.value as string
      setGeneratorValues(generatorIdentifier)
      await setPartnersFromSelectedCustomer(generatorIdentifier as string)
      await setModelsByCustomerIdentifier(generatorIdentifier)
    } catch (error) {
      showAlert('warning', 'Parceiros não encontrados.')
    } finally {
      setIsShowLoading(false)
    }
  }

  const loadFields = async (): Promise<void> => {
    const customersList = await getAllCustomers()
    setCustomers(customersList)
    handleCustomerData(customersList)
    const initialSelectedCustomer = getInitialSelectedCompany(customersList, defaultCompany)
    setGeneratorValues(initialSelectedCustomer.identifier)
    setModelsByCustomerIdentifier(initialSelectedCustomer.identifier)
    setPartnersFromSelectedCustomer(initialSelectedCustomer.identifier)
    setValue('mtrState', mtrStateEnum.WAITING_FEAM)
  }

  const handleInit = React.useCallback(async (): Promise<void> => {
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar MTRs', path: '/main/gathering/list' },
      { label: 'Novo MTR', path: '/main/gathering/form' },
    ])
    await loadFields()
    setTitle('Novo MTR')
    setCustomHeaderContent(<div />)
  }, [setCustomHeaderContent, setItemsBreadCrumb, setTitle])

  React.useEffect(() => {
    setIsShowLoading(true)
    handleInit().finally(() => setIsShowLoading(false))
  }, [setIsShowLoading, handleInit])

  const setFieldsOfChosenMtrModel = async (m: MtrModelResponse | undefined): Promise<void> => {
    const fields = {
      generatorIdentifier: m?.generatorIdentifier,
      transporterIdentifier: m?.transporterIdentifier,
      receiverIdentifier: m?.receiverIdentifier,
      driverName: m?.driverName,
      vehicleName: m?.vehiclePlate,
      mtrObservation: m && m.mtrObservation ? m.mtrObservation : '',
      mtrState: getValues().mtrState ?? mtrStateEnum.WAITING_FEAM,
      waste: m?.wasteModel,
      name: m?.name,
    }
    reset(fields)
  }

  const resetModelFields = (): void => {
    setSelectedModel('')
    const defaultValues = {
      generatorIdentifier: '',
      transporterIdentifier: '',
      receiverIdentifier: '',
      driverName: '',
      vehicleName: '',
      mtrObservation: '',
      mtrState: mtrStateEnum.WAITING_FEAM,
      waste: [],
      name: '',
    }
    reset(defaultValues)
  }

  const setModelFields = async (
    event: React.SyntheticEvent<Element, Event>,
    value: Select2Options | null
  ): Promise<void> => {
    setIsShowLoading(true)
    if (value?.value === 'clear' || !value?.value) {
      resetModelFields()
      loadFields()
      handleReactGAEvent({
        category: 'Modelo MTR',
        action: 'Modelo MTR desselecionado',
        label: 'Modelo MTR desselecionado',
      })
    } else {
      setSelectedModel(value?.value as string)
      const m = await getMtrModelByIdentifier(value?.value as string)
      await handleGeneratorChange(event, { value: m.generatorIdentifier, label: m.generatorName })
      setFieldsOfChosenMtrModel(m)

      handleReactGAEvent({
        category: 'Modelo MTR',
        action: 'Modelo MTR selecionado',
        label: 'Modelo MTR selecionado',
      })
    }
    setIsShowLoading(false)
  }

  const handleMoreOptions = (item: WasteModel, index?: number, anchorEl?: EventTarget & HTMLButtonElement): void => {
    setIsOpen(true)
    setAnchor(anchorEl)
    setItemToChange(item)
  }

  const handleCreateGatheringRegistry = (gathering: GatheringRequest): void => {
    createGathering(gathering)
      .then((response) => {
        if (response.manifestCod) {
          showAlert('success', 'MTR gerado com sucesso.')

          setTimeout(() => {
            const link = document.createElement('a')
            link.href = response.urlPdf as string
            link.download = 'manifesto.pdf'
            link.target = '_blank'
            link.dispatchEvent(new MouseEvent('click'))
            navigate('/main/gathering/list')
          }, 1500)
        } else {
          navigate('/main/gathering/list')
          showAlert('success', 'MTR salvo com sucesso, mas sem envio para o órgão ambiental.')
        }
        handleReactGAEvent({
          category: 'MTR',
          action: 'Criação bem sucedida de MTR',
          label: 'MTR criado com sucesso',
        })
      })
      .catch((error) => {
        if (error.response?.data?.errorType) {
          navigate('/main/gathering/list')
          showAlert('warning', error.response?.data?.errorMessage ?? 'Erro ao salvar MTR. Tente novamente mais tarde.')
        } else {
          showAlert('error', 'Erro ao salvar MTR. Tente novamente mais tarde.')
        }
        handleReactGAEvent({
          category: 'MTR',
          action: 'Criação falha de MTR',
          label: 'MTR não foi criado/foi criado com falha',
        })
      })
      .finally(() => {
        setIsShowLoading(false)
      })
  }

  const onSubmit: SubmitHandler<GatheringRequest> = (data: GatheringRequest): void => {
    if (!confirmationModalOpen) {
      setConfirmationModalOpen(true)
      return
    }

    setIsShowLoading(true)
    const gathering = { ...data }
    gathering.waste.map((w) => {
      const wasteItem = { ...w }
      delete (wasteItem as unknown as MtrModel).mtrModelIdentifier
      return wasteItem
    })
    gathering.manifestEmissionDate = new Date()

    const user = getTokenCompany()
    if (user) gathering.accountable = user

    handleCreateGatheringRegistry(gathering)

    setConfirmationModalOpen(false)
  }

  const wasteDataTableCustomColumns = [
    {
      label: 'Quantidade',
      content: (item: WasteModel): JSX.Element => (
        <InputText
          id={`quantity-${item.wasteCode}`}
          type="number"
          label=""
          value={item.quantity}
          sx={{ width: '150px' }}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            const wasteModelListWithWasteEditedQuantity = getValues('waste').map((wasteInfo) => {
              if (wasteInfo.wasteCode === item.wasteCode) {
                return { ...wasteInfo, quantity: Number(e.target.value) }
              }
              return wasteInfo
            })
            setValue('waste', wasteModelListWithWasteEditedQuantity)
          }}
        />
      ),
    },
  ]

  return isPageInMaintenance ? (
    <Maintenance />
  ) : (
    <>
      <InfoConfirmationDialog
        open={confirmationModalOpen}
        handleOpen={setConfirmationModalOpen}
        onSubmit={handleSubmit(onSubmit)}
        gatheringInfo={getValues()}
      />
      <Card className="card card--form card-forms-styles" variant="outlined">
        {models?.length > 0 && (
          <Grid style={{ marginBottom: '24px', border: '1px solid #E9E9E8', borderRadius: '16px', padding: '20px' }}>
            Utilize um modelo de MTR para agilizar o preenchimento dos dados.
            <div
              style={{
                display: 'flex',
                gap: '8px',
                marginTop: '8px',
                flexWrap: 'wrap',
              }}
            >
              <InputSelect2
                id="model"
                label=""
                options={[...models.map((m) => ({ label: m.name, value: m.identifier }))]}
                value={selectedModel}
                clearable
                onChange={setModelFields}
                sx={{
                  width: '50.5%',
                }}
                popupIcon={<SearchIcon />}
              />
            </div>
          </Grid>
        )}
        <WasteFormDialog waste={waste} open={wasteDialogOpen} handleClose={handleClose} handleSave={handleSaveWaste} />
        <form autoComplete="none" onSubmit={handleSubmit(onSubmit)}>
          <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60%' }}>
            <Typography className="card-title" sx={{ margin: '23px 0px', fontSize: '20px' }}>
              Dados primários
            </Typography>
            {customers && customers[0] && (
              <FeamStatusSign
                customerCnpj={customers[0].cnpj}
                style={{
                  '&.MuiChip-root': {
                    borderRadius: '100px',
                    color: 'white',
                  },
                }}
              />
            )}
          </section>
          {customersOptions.length > 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="generatorIdentifier"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputSelect2
                      id="generatorIdentifier"
                      label="Geradora"
                      errorText={formState.errors?.generatorIdentifier?.message}
                      options={customersOptions}
                      onChange={handleGeneratorChange}
                      value={field.value}
                    />
                  )}
                />
              </Grid>
              {partnersOptions.length > 0 && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="transporterIdentifier"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <InputSelect2
                          id="transporterIdentifier"
                          label="Transportadora"
                          errorText={formState.errors?.transporterIdentifier?.message}
                          options={partnersOptions}
                          value={field.value}
                          onChange={handleTransporterChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="receiverIdentifier"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <InputSelect2
                          id="receiverIdentifier"
                          label="Destinadora"
                          errorText={formState.errors?.receiverIdentifier?.message}
                          options={partnersOptions}
                          value={field.value}
                          onChange={handleReceiverChange}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6}>
                <Controller
                  name="driverName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      id="driverName"
                      label="Motorista"
                      errorText={formState.errors?.driverName?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="vehicleName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      id="vehicleName"
                      label="Veículo"
                      errorText={formState.errors?.vehicleName?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="mtrObservation"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      id="mtrObservation"
                      type="text"
                      label="Observação"
                      errorText={formState.errors?.mtrObservation?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}

          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '24px',
            }}
          >
            <Button
              className="anti-card"
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
              data-cy="new-residue-button"
            >
              Adicionar Resíduo
            </Button>
          </Grid>
          <Grid style={{ margin: '-80px -16px 0px -16px', marginTop: '40px' }}>
            <DataTable
              data={watch('waste') || []}
              dataMap={[{ key: 'name', value: 'Nome' }]}
              customColumns={wasteDataTableCustomColumns}
              loading={false}
              compact
              actions={[
                {
                  label: 'opções',
                  icon: <Icons.MoreVertical fill={ApplicationColors.primaryColor} />,
                  fn: handleMoreOptions,
                },
              ]}
              config={{
                showPagination: false,
              }}
              handleOpenInfoModal={handleEditWasteClickItem}
            />
            <EditDeletePopover
              editFunction={handleEditWaste}
              deleteFunction={handleDeleteWaste}
              open={isOpen}
              item={itemToChange}
              handleClose={handleClosePopover}
              anchor={anchor}
            />
          </Grid>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '24px',
            }}
          >
            <Button variant="contained" color="primary" type="submit" data-cy="confirm-data-button">
              Salvar
            </Button>
          </Grid>
        </form>
      </Card>
    </>
  )
}

export default GatheringForm
