import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ListItemIcon, ListItemButton, useMediaQuery, Icon, Typography } from '@mui/material'
import Icons from '../../assets/icons/svgIcons'

import AppContext, { AppContextType } from '../../AppContext'
import { getLoggedUser, signOut } from '../../services/Auth/TokenService'
import { smoothWhite, useStyles } from './HeaderStyles'
import BreadCrumb from '../BreadCrumb/BreadCrumb'
import { getUpdatesByUserPermissions } from '../../routes/SystemUpdates2/systemUpdates'

const Header: React.FC = () => {
  const { title, customHeaderContent, itemsBreadCrumb, isSidebarOpened } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false)
  const [userInitials, setUserInitials] = React.useState<string>('')
  const [popOverType, setPopOverType] = React.useState<string>('moreOptions')
  const userInfo = getLoggedUser()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const classes = useStyles()
  const navigate = useNavigate()
  const isLargeScreen = useMediaQuery('(min-width: 600px)')
  const isMobilePortraitMode = useMediaQuery(
    '((min-width:600px) and (max-height:600px)) or ((max-width:600px) and (min-height:600px))'
  )
  const location = useLocation()

  React.useEffect(() => {
    if (userInfo?.employeeName) {
      setUserInitials(userInfo.employeeName.substring(0, 1))
    }
  }, [userInfo?.employeeName])

  const canGoBack = !location.pathname.includes('home')

  const handleBackButton = (): void => {
    window.history.back()
  }

  const handleLogout = (): void => {
    signOut()
  }

  const handleEditPassword = (): void => {
    setAnchorEl(null)
    navigate('/main/change-password/form')
  }

  const handleOpenNotification = (identifier: string): void => {
    setAnchorEl(null)
    navigate(`/main/system-updates2/${identifier}`)
  }

  const getOpenMoreOptionsContent = (): JSX.Element => {
    return (
      <List component="div" aria-label="other options">
        <ListItemButton className={classes.listItem} onClick={handleEditPassword} data-cy="update-password-option">
          <ListItemText primary="Editar Senha" />
        </ListItemButton>
        <ListItemButton className={classes.listItem} onClick={handleLogout} data-cy="logout-button-option">
          <ListItemIcon>
            <Icons.Logout fill="#000" width={32} height={32} />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItemButton>
      </List>
    )
  }

  const getNotificationsContent = (): JSX.Element => {
    const userCargos = getLoggedUser()?.cargos || []
    const fiveLastRecentUpdates = getUpdatesByUserPermissions(userCargos)
    return (
      <List component="div" aria-label="notifications">
        {fiveLastRecentUpdates.length > 0 ? (
          fiveLastRecentUpdates.map((update) => (
            <ListItemButton
              key={update.title}
              className={classes.listItem}
              data-cy={`notification-${update.identifier}`}
              onClick={() => handleOpenNotification(update.identifier)}
            >
              <ListItemText primary={update.title} secondary={update.description} />
            </ListItemButton>
          ))
        ) : (
          <ListItemButton className={classes.listItem}>
            <ListItemText primary="Sem notificações" />
          </ListItemButton>
        )}
      </List>
    )
  }

  const setPopoverContent = (): JSX.Element => {
    switch (popOverType) {
      case 'notifications':
        return getNotificationsContent()
      default:
        return getOpenMoreOptionsContent()
    }
  }

  const handleOpenMoreOptions = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setPopOverType('moreOptions')
    setAnchorEl(event.currentTarget)
  }

  const handleOpenNotifications = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setPopOverType('notifications')
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopOver = (): void => {
    setAnchorEl(null)
  }

  const toggleFullScreen = (): void => {
    const doc = document.documentElement
    if (!isFullScreen) {
      if (doc.requestFullscreen) {
        doc.requestFullscreen()
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
    }
    setIsFullScreen(!isFullScreen)
  }

  return (
    <header className={classes.header}>
      <div className={classes.headerContainer}>
        <div className={classes.headerCustomContent}>
          {canGoBack && (
            <div className="mobile--visible">
              <IconButton color="primary" aria-label="menu mobile" onClick={handleBackButton} size="large">
                <Icons.ChevronLeft fill="#FFF" width={24} height={24} />
              </IconButton>
            </div>
          )}
          <h1
            className={classes.headerTitle}
            data-cy="header-title"
            style={{ fontSize: isLargeScreen && !isMobilePortraitMode ? '35px' : '30px' }}
          >
            {title}
          </h1>
        </div>
        <div className={classes.headerRight}>
          <div style={{ marginLeft: '8px' }}>
            {customHeaderContent && customHeaderContent}
            <IconButton
              color="primary"
              aria-label="fullscreen"
              onClick={toggleFullScreen}
              size="large"
              className="mobile--hidden"
            >
              {isFullScreen ? (
                <Icons.CloseFullScreen fill="#FFF" width={24} height={24} />
              ) : (
                <Icons.FullScreen fill="#FFF" width={24} height={24} />
              )}
            </IconButton>
            <IconButton
              id="notifications"
              type="button"
              size="large"
              aria-label="notifications"
              data-cy="notifications-button"
              onClick={handleOpenNotifications}
            >
              <Icon>notifications</Icon>
            </IconButton>
            <IconButton
              color="primary"
              type="button"
              className={classes.headerLeft}
              onClick={handleOpenMoreOptions}
              data-cy="user-initials-button"
            >
              <p>{userInitials}</p>
            </IconButton>
            <Popover
              id="popover opcoes"
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClosePopOver}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {setPopoverContent()}
            </Popover>
          </div>
        </div>
      </div>
      <div className={classes.headerSystemInfoDiv}>
        <BreadCrumb items={itemsBreadCrumb} />
        <Typography key="system-version" color={smoothWhite} className={classes.systemVersion}>
          Beta v0.9.0
        </Typography>
      </div>
    </header>
  )
}

export default Header
