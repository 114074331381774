import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'
import App from './App'
import reportWebVitals from './reportWebVitals'
import AuthenticationRoute from './routes/Authentication/AuthenticationRoute'
import { getRole } from './services/Auth/TokenService'
import LoginForm from './routes/Authentication/Login/Login'
import RecoveryPassword from './routes/Authentication/RecoveryPassword/RecoveryPassword'
import theme from './utils/createDefaultTheme'

const role = getRole()

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/auth" element={<AuthenticationRoute />}>
          <Route path="/auth" element={<Navigate to="/auth/login" />} />
          <Route path="/auth/login" element={<LoginForm />} />
          <Route path="/auth/password" element={<RecoveryPassword />} />
        </Route>
        <Route path="/main/*" element={<App />} />
        {/* TODO: decodificar o token */}
        <Route path="/" element={<Navigate to={role ? '/main' : '/auth'} />} />
        <Route path="/main" element={<Navigate to={role ? '/main/home' : '/auth'} />} />
      </Routes>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
