import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import ISystemUpdates, { ISystemUpdates2 } from '../../models/SystemUpdate/update'
import { getLatestUpdate } from '../../routes/SystemUpdates2/systemUpdates'
import useStyles from './NotificationModalStyle'
import { getLoggedUser } from '../../services/Auth/TokenService'

type ILastUpdateInfo = {
  [key: string]: string
}

const NotificationModal: React.FC = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const userInfo = getLoggedUser()
  const userName = userInfo?.username
  localStorage.removeItem('lastUpdateInfo')
  localStorage.removeItem('lastUpdateRegistry')

  const getUpdateInfosFromLocalStorage = (): ILastUpdateInfo | null => {
    const lastUpdateRegistered = localStorage.getItem('updatesRegistry')
    if (!lastUpdateRegistered) return null

    return JSON.parse(lastUpdateRegistered)
  }

  const lastSeenLocalStorage = getUpdateInfosFromLocalStorage() || {}
  const userCargos = userInfo?.cargos || []
  const lastUpdate = getLatestUpdate(userCargos)

  const showNotification = (update: ISystemUpdates2): boolean => {
    if (!update) return false
    if (lastSeenLocalStorage[userName! as keyof ILastUpdateInfo] === update.identifier) return false
    return true
  }
  const shouldShowNotification = showNotification(lastUpdate)
  const [open, setOpen] = React.useState(shouldShowNotification)

  const setNewLastSeenRegistry = (updatedIdentifier: string): void => {
    const newLastSeenRegistry = { ...lastSeenLocalStorage, [userName!]: updatedIdentifier }
    localStorage.setItem('updatesRegistry', JSON.stringify(newLastSeenRegistry))
  }

  const handleCloseNotificationModal = (): void => {
    setOpen(false)
    setNewLastSeenRegistry(lastUpdate!.identifier)
  }

  const handleNavigateToUpdate = (): void => {
    setNewLastSeenRegistry(lastUpdate!.identifier)
    navigate(`/main/system-updates2/${lastUpdate!.identifier}`)
  }

  return (
    <div>
      {lastUpdate && (
        <Dialog open={open} className={classes.modal} data-cy="notification-modal-container">
          <DialogTitle sx={{ backgroundColor: '#43b849', color: 'white' }}>
            Atualização - {lastUpdate.title}
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="body2">{lastUpdate.description} Veja o que foi alterado:</Typography>
            <ul>
              {lastUpdate.changesMade?.map((change) => (
                <li key={change}>
                  <Typography variant="body2" key={lastUpdate.identifier}>
                    {change}
                  </Typography>
                </li>
              ))}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNavigateToUpdate}>Ver mais</Button>
            <Button onClick={handleCloseNotificationModal} data-cy="notification-modal-close-button">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default NotificationModal
