import React from 'react'
import useStyles from '../SystemUpdates/SystemUpdatesStyle'
import brokenRobot from '../../assets/images/BrokenRobot.png'

const NotFound: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.noUpdate}>
      <img src={brokenRobot} alt="Robô quebrado" />
      <h3 style={{ textAlign: 'center' }}>Atualização indisponível</h3>
    </div>
  )
}

export default NotFound
