import IconButton from '@mui/material/IconButton'
import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import Icons from '../../assets/icons/svgIcons'
import { useStyles } from './PaginationActionsStyles'
import { ApplicationColors } from '../../utils/applicationColors'

type DataTableActionProps = {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
}
const CustomTablePaginationActions: React.FC<DataTableActionProps> = ({ count, page, rowsPerPage, onPageChange }) => {
  const firstPage: boolean = page === 0
  const lastPage: boolean = page >= Math.ceil(count / rowsPerPage) - 1

  const handlePreviousPage = (event: React.MouseEvent<HTMLButtonElement> | null): void => {
    onPageChange(event, page - 1)
  }

  const handleNextPage = (event: React.MouseEvent<HTMLButtonElement> | null): void => {
    onPageChange(event, page + 1)
  }
  const classes = useStyles()
  return (
    <List className={classes.container}>
      <ListItem className={classes.item}>
        <IconButton className={classes.iconButton} onClick={handlePreviousPage} disabled={firstPage}>
          <Icons.ChevronLeft
            fill={firstPage ? ApplicationColors.disabledColor : ApplicationColors.primaryColor}
            style={{ height: '48px', width: '48px' }}
          />
        </IconButton>
      </ListItem>
      <ListItem className={classes.item}>
        <IconButton className={classes.iconButton} onClick={handleNextPage} disabled={lastPage}>
          <Icons.ChevronRight
            fill={lastPage ? ApplicationColors.disabledColor : ApplicationColors.primaryColor}
            style={{ height: '48px', width: '48px' }}
          />
        </IconButton>
      </ListItem>
    </List>
  )
}
export default CustomTablePaginationActions
