/* eslint-disable import/no-duplicates */
import React from 'react'
import Card from '@mui/material/Card'
import { DateRange } from 'react-date-range'
import pt from 'date-fns/locale/pt'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Icons from '../../../assets/icons/svgIcons'
import useStyles from './DateRangeStyles'
import { DateRangeInterface } from '../../../models/Gathering/Gathering'

type DateRangeProps = {
  onRangeUpdate: (range: DateRangeInterface) => void
  position?: 'left' | 'right'
  defaultSelectionRange?: { startDate: Date; endDate: Date }
}

const DateRangeInput: React.FC<DateRangeProps> = ({
  onRangeUpdate,
  position,
  defaultSelectionRange,
}: DateRangeProps) => {
  const [selectedDate, setSelectedDate] = React.useState<{ startDate: string; endDate: string } | undefined>()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const classes = useStyles()

  const [selectionRange, setSelectionRange] = React.useState<{
    startDate?: Date
    endDate?: Date
    key?: string
  }>({
    startDate: defaultSelectionRange?.startDate,
    endDate: defaultSelectionRange?.endDate,
    key: 'selection',
  })

  const clear = (): void => {
    setSelectedDate(undefined)
    setSelectionRange({ startDate: undefined, endDate: undefined, key: 'selection' })
    onRangeUpdate({ startDate: undefined, endDate: undefined })
  }

  const onSubmitDate = (range: DateRangeInterface): void => {
    if (range.startDate || range.endDate) {
      let sDate = range.startDate
      const eDate = range.endDate as Date
      if (!sDate) sDate = range.endDate as Date
      const { startDate, endDate } = range
      setIsOpen(false)
      setSelectedDate({ startDate: sDate.toLocaleDateString('pt-BR'), endDate: eDate.toLocaleDateString('pt-BR') })
      onRangeUpdate({ startDate, endDate })
    } else {
      clear()
    }
  }

  const setCardCalendarPosition = (): string => {
    if (position === 'left') {
      return classes.cardCalendarLeft
    }
    return classes.cardCalendarRight
  }

  const handleDate = (): void => {
    if (defaultSelectionRange?.startDate && defaultSelectionRange?.endDate) {
      const defaultSelectionRangeString = {
        startDate: defaultSelectionRange.startDate.toLocaleDateString('pt-BR'),
        endDate: defaultSelectionRange.endDate.toLocaleDateString('pt-BR'),
      }
      setSelectedDate(defaultSelectionRangeString)
      return
    }
    setSelectedDate(undefined)
  }

  React.useEffect(() => {
    handleDate()
  }, [defaultSelectionRange])

  return (
    <>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icons.Calendar />
            </InputAdornment>
          ),
        }}
        className={classes.textField}
        data-cy="date-range-input"
        id="datepickrange"
        label="Filtrar por data de emissão"
        value={selectedDate ? `${selectedDate?.startDate} - ${selectedDate?.endDate}` : ''}
        defaultValue={selectedDate ? `${selectedDate?.startDate} - ${selectedDate?.endDate}` : ''}
        sx={{ border: 'none', width: '100%', marginBottom: '3vh' }}
        autoComplete="off"
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <Card className={setCardCalendarPosition()} variant="outlined">
          <DateRange
            locale={pt}
            startDatePlaceholder="Data inicial"
            endDatePlaceholder="Data final"
            editableDateInputs
            ranges={[selectionRange]}
            color="#43b849"
            rangeColors={['#43b849']}
            onChange={(item) => setSelectionRange(item.selection)}
          />
          <Grid container>
            <Button
              variant="contained"
              data-cy="date-range-close-button"
              color="primary"
              type="submit"
              sx={{ marginRight: '10px' }}
              onClick={() => setIsOpen(false)}
            >
              Fechar
            </Button>
            <Button
              variant="contained"
              data-cy="date-range-submit-button"
              color="primary"
              type="submit"
              onClick={() => {
                onSubmitDate(selectionRange)
                setIsOpen(false)
              }}
            >
              Aplicar
            </Button>
          </Grid>
        </Card>
      )}
    </>
  )
}

export default DateRangeInput
