import axios, { AxiosResponse } from 'axios'
import getEndpointUrl from '../../utils/url-resolver'
import ClientObservation, { ClientObservationRequest } from '../../models/Client/ClientObservation'

const baseUrl = `${getEndpointUrl('company')}/v1/customer-observations`

const getObservationsByCustomerIdentifier = (customerIdentifier: string): Promise<ClientObservation[]> => {
  return axios.get(`${baseUrl}/customer/${customerIdentifier}`).then((response) => response.data)
}

const saveObservation = (customerObservation: ClientObservationRequest): Promise<ClientObservation> => {
  return axios.post(`${baseUrl}`, customerObservation).then((response) => response.data)
}

export { getObservationsByCustomerIdentifier, saveObservation }
