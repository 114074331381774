import React from 'react'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import AppContext, { AppContextType } from '../../../AppContext'

import DataTable from '../../../components/DataTable/DataTable'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import { getAllAccessLevels } from '../../../services/Settings/acessLevelService'
import { getAllFeatures } from '../../../services/Settings/featureService'
import { acessLevel, featuresType } from '../../../models/Setting/Settings'
import { useStyles } from './SettingsStyle'
import { AcessLevelDrawer } from '../newAcessLevelDrawer/acessLevelDrawer'
import { UpdateFeatDrawer } from '../updateFeatDrawer/updateFeatDrawer'
import { EditDeletePopover } from '../editDeletePopover/editDeletePopover'
import { DeleteModal } from '../deleteModal/deleteModal'

const Settings: React.FC = () => {
  const [loadingFeat, setLoadingFeat] = React.useState<boolean>(false)
  const [loadingAL, setLoadingAL] = React.useState<boolean>(false)
  const [acessLevels, setAcessLevels] = React.useState<acessLevel[]>([])
  const [features, setFeatures] = React.useState<featuresType[]>([])
  const [openDrawerAL, setOpenDrawerAL] = React.useState<boolean>(false)
  const [openDrawerFeat, setOpenDrawerFeat] = React.useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false)
  const [handleUpdateAL, setHandleUpdateAL] = React.useState<boolean>(false)
  const [handleUpdateFeat, setHandleUpdateFeat] = React.useState<boolean>(false)
  const [acessLevelToAction, setAcessLevelToAction] = React.useState<acessLevel>()
  const [featureToEdit, setFeatureToEdit] = React.useState<featuresType>()
  const [anchorEl, setAnchorEl] = React.useState<EventTarget & Element>()
  const [titleDrawer, setTitleDrawer] = React.useState<string>('')
  const { setTitle, showAlert, setItemsBreadCrumb } = React.useContext(AppContext as React.Context<AppContextType>)
  const featuresMap = [
    { key: 'name', value: 'Funcionalidade' },
    { key: 'category', value: 'Categoria' },
  ]
  const acessLevelsMap = [{ key: 'name', value: 'Nivel de acesso' }]
  const classes = useStyles()
  const allowDelete = false

  const handlePromise = (
    promise: Promise<featuresType[] | acessLevel[]>,
    setPromise: React.Dispatch<React.SetStateAction<any>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    message: string
  ): void => {
    promise
      .then((res: featuresType[] | acessLevel[]) => {
        setPromise(res)
      })
      .catch(() => {
        showAlert('error', `Erro ao carregar ${message}`)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleInit = React.useCallback((): void => {
    setLoadingAL(true)
    setLoadingFeat(true)
    setTitle('Configurações')
    handlePromise(getAllAccessLevels(), setAcessLevels, setLoadingAL, 'niveis de acesso')
    handlePromise(getAllFeatures(), setFeatures, setLoadingFeat, 'features')
  }, [setTitle, showAlert])

  const handleClickEditFeat = (feat: featuresType): void => {
    setFeatureToEdit(feat)
    setOpenDrawerFeat(true)
  }

  const handleCloseALDrawer = (): void => {
    setOpenDrawerAL(false)
  }

  const handleCloseFeatDrawer = (): void => {
    setOpenDrawerFeat(false)
  }

  const handleCloseModal = (): void => {
    setOpenDeleteModal(false)
  }

  const handleClickPopover = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = (): void => {
    setAnchorEl(undefined)
  }

  const openPopover = Boolean(anchorEl)

  React.useEffect(() => {
    handleInit()
  }, [handleInit])

  React.useEffect(() => {
    setLoadingAL(true)
    handlePromise(getAllAccessLevels(), setAcessLevels, setLoadingAL, 'niveis de acesso')
    setAcessLevelToAction(undefined)
  }, [handleUpdateAL])

  React.useEffect(() => {
    setLoadingFeat(true)
    handlePromise(getAllFeatures(), setFeatures, setLoadingFeat, 'features')
    setFeatureToEdit(undefined)
  }, [handleUpdateFeat])

  React.useEffect(() => {
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Painel de configurações', path: '/main/settings/panel' }])
  }, [])

  return (
    <>
      {openDrawerAL && (
        <AcessLevelDrawer
          open={openDrawerAL}
          title={titleDrawer}
          features={features}
          handleClose={handleCloseALDrawer}
          acessLevel={acessLevelToAction}
          updateHandler={setHandleUpdateAL}
          updateAux={handleUpdateAL}
        />
      )}
      {openDrawerFeat && (
        <UpdateFeatDrawer
          open={openDrawerFeat}
          handleClose={handleCloseFeatDrawer}
          feature={featureToEdit}
          updateHandler={setHandleUpdateFeat}
          updateAux={handleUpdateFeat}
        />
      )}
      {openDeleteModal && allowDelete && (
        <DeleteModal
          open={openDeleteModal}
          handleClose={handleCloseModal}
          acessLevelToDelete={acessLevelToAction}
          updateHandler={setHandleUpdateAL}
          updateAux={handleUpdateAL}
        />
      )}
      <Card className={`${classes.containerAll} card`} sx={{ boxShadow: 'none' }}>
        <div className={`${classes.containerButton}`}>
          <Button
            className={`${classes.newAcessLevelButton}`}
            sx={{ borderRadius: '50px' }}
            variant="contained"
            data-testid="new-acess-level-button"
            color="primary"
            type="submit"
            onClick={() => {
              setOpenDrawerAL(true)
              setTitleDrawer('new')
            }}
          >
            + Novo nivel de acesso
          </Button>
        </div>
        <Card className={`${classes.settingsContainer}`} sx={{ boxShadow: 'none' }}>
          <Card className={`${classes.dataTableAcessLevel}`} sx={{ boxShadow: 'none' }}>
            <DataTable
              settings
              config={{ showPagination: false }}
              data={acessLevels}
              dataMap={acessLevelsMap}
              loading={loadingAL}
              customColumns={[
                {
                  label: 'Opções',
                  content: (item: acessLevel) =>
                    (
                      <>
                        {allowDelete && (
                          <>
                            <MoreVertIcon onClick={handleClickPopover} sx={{ cursor: 'pointer' }} />
                            <EditDeletePopover
                              open={openPopover}
                              anchorEl={anchorEl}
                              handleClose={handleClosePopover}
                              openEditAcessLevel={setOpenDrawerAL}
                              openDeleteAcessLevel={setOpenDeleteModal}
                              setAcessLevelToChange={setAcessLevelToAction}
                              acessLevelToChange={item}
                              setEditTitle={setTitleDrawer}
                            />
                          </>
                        )}
                        <EditIcon
                          onClick={() => {
                            setAcessLevelToAction(item)
                            setOpenDrawerAL(true)
                            setTitleDrawer('edit')
                          }}
                          sx={{ cursor: 'pointer' }}
                          id={`edit-access-level-${item.name}`}
                        />
                      </>
                    ) as JSX.Element,
                },
              ]}
            />
          </Card>
          <Card className={`${classes.dataTableFeatures}`} sx={{ boxShadow: 'none' }}>
            <DataTable
              settings
              data={features}
              dataMap={featuresMap}
              loading={loadingFeat}
              customColumns={[
                {
                  label: 'Opções',
                  content: (item: featuresType) => (
                    <EditIcon sx={{ cursor: 'pointer' }} onClick={() => handleClickEditFeat(item)} />
                  ),
                },
              ]}
            />
          </Card>
        </Card>
      </Card>
    </>
  )
}

export default Settings
