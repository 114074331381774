import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

interface ChangePasswordModalProps {
  open: boolean
  handleClose: () => void
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ backgroundColor: '#43b849', color: 'white' }}>Novos Padrões de Senha</DialogTitle>
      <DialogContent dividers>
        <Typography>
          Para sua segurança, atualizamos os padrões de exigência na criação de novas senhas. Atualize para voltar ao
          uso do sistema.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangePasswordModal
