import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ClientFormType } from '../../../../models/Company/Company'
import ClientNotes from './components/ClientNotes'

interface ClientNotesTabProps {
  form: UseFormReturn<ClientFormType>
}

const ClientNotesTab: React.FC<ClientNotesTabProps> = ({ form }) => {
  return <ClientNotes />
}

export default ClientNotesTab
