import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    sidebarBottomCards: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      width: '100%',
      position: 'absolute',
      bottom: '0',
      borderRadius: '0',
      borderRight: 'none',
      margin: '0',
    },
    sidebarCard: {
      display: 'flex',
      alignItems: 'center',
      height: '60px',
      maxWidth: '100%',
      padding: '10px',
      '&:hover': {
        backgroundColor: '#f5f5f5',
        cursor: 'pointer',
      },
    },
    aside: {
      zIndex: 999,
      top: '0',
      width: '300px',
      height: '100%',
      backgroundColor: '#FFF',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      transition: 'width 0.3s',
    },
    asideHeader: {
      display: 'flex',
      alignItems: 'center',
      height: '64px',
      position: 'relative',
      cursor: 'pointer',
      '& ::after': {
        content: '""',
        backgroundColor: '#CCC',
        height: '1px',
        width: '80%',
        position: 'absolute',
        bottom: '0px',
        left: '10%',
      },
      '& img': {
        width: 'auto',
        height: '35px',
      },
      '& img.short': {
        width: 'auto',
        height: '25px',
      },
    },
    asideHeaderButtons: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    navMenu: {
      marginTop: '16px',
    },
    toggleButton: {
      background: 'transparent',
      outline: '0px',
      border: '0px',
      cursor: 'pointer',
      paddingTop: '6px',
    },
    searchBox: {
      position: 'relative',
      margin: '0px 16px 16px 16px',
      '& svg': {
        position: 'absolute',
        top: '16px',
        left: '8px',
      },
      '& input': {
        paddingLeft: '40px',
      },
    },
  })
)
