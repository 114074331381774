import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    mainContainer: {
      margin: '20px 50px',
    },
    title: {
      fontSize: '1.5w',
      width: '50%',
      paddingBottom: '20px',
      [theme.breakpoints.down('md')]: {
        width: '80%',
        textAlign: 'justify',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '5.0vw',
      },
    },
    paragraph: {
      fontSize: '1.0vw',
      paddingBottom: '20px',
      [theme.breakpoints.down('md')]: {
        width: '80%',
        fontSize: '2.0vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '4.0vw',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '40%',
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
    },
    buttonRight: {
      height: '50px',
      width: '230px',
      borderRadius: 20,
      backgroundColor: '#ffffff',
      fontSize: 'small',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    buttonLeft: {
      height: '50px',
      width: '230px',
      borderRadius: 10,
      fontSize: 'small',
      [theme.breakpoints.down('sm')]: {
        padding: '20px',
      },
    },
    brokenRobotGrid: {
      justifyContent: 'start',
      [theme.breakpoints.down('sm')]: {
        width: '80%',
      },
    },
    brokenRobotImage: {
      width: '80%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  })
})

export default useStyles
