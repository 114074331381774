import axios from 'axios'
import { CitiesResponse } from '../../models/Company/Cities'
import { Select2Options } from '../../components/Form/Select/Select2'

const getCitiesByState = async (UF: string): Promise<Select2Options[]> => {
  const response = await axios.get<CitiesResponse[]>(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${UF}/municipios`
  )
  const cities = response.data.map((city) => ({
    label: `${city.nome} - ${city.microrregiao.mesorregiao.UF.sigla}`,
    value: `${city.nome} - ${city.microrregiao.mesorregiao.UF.sigla} - ${city.id}`,
  }))
  return cities
}

export default getCitiesByState
