import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext, { AppContextType } from '../../../AppContext'
import { ApplicationColors } from '../../../utils/applicationColors'
import { ReactComponent as Meeting } from '../../../assets/images/meeting.svg'

const NoPartnersPage: React.FC = () => {
  const { setTitle, setCustomHeaderContent } = React.useContext(AppContext as React.Context<AppContextType>)
  const navigate = useNavigate()

  React.useEffect(() => {
    setTitle('Acesso Negado')
    setCustomHeaderContent(<div />)
  }, [setCustomHeaderContent, setTitle])

  return (
    <Card className="newCard" sx={{ borderRadius: 0 }}>
      <Grid sx={{ width: '50%' }}>
        <Typography variant="h5" sx={{ marginTop: '50px', marginBottom: '25px' }}>
          Desculpe, mas parece que você está tentando emitir um MTR para uma empresa que não possui parceiros
          cadastrados.
        </Typography>
        <Typography variant="h6">Tente cadastrar algum parceiro para a empresa selecionada na aba Empresas.</Typography>
        <Typography variant="h6">Depois vincule este parceiro recém criado à empresa na aba Contratos.</Typography>
        <Grid sx={{ marginTop: '30px' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'white',
              border: `1px solid ${ApplicationColors.primaryColor}`,
              borderRadius: '50px',
              color: 'black',
              boxShadow: 'none',
              marginRight: '20px',
            }}
            onClick={() => navigate('/main/home')}
          >
            Voltar para home
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: '50px', boxShadow: 'none' }}
            onClick={() => navigate('/main/companies')}
          >
            Cadastrar parceiro
          </Button>
        </Grid>
        <Meeting />
      </Grid>
    </Card>
  )
}

export default NoPartnersPage
