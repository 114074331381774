import React from 'react'
import { Grid, FormLabel } from '@mui/material'
import Contact from '../utils/Contact'
import { PartnerResponse } from '../../../models/Partner/Partner'
import useStyles from '../Styles/ViewInfoModalStyle'

interface PartnerContactsProps {
  partner: PartnerResponse
}

const PartnerContacts: React.FC<PartnerContactsProps> = ({ partner }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} key="contact-title" sx={{ marginBottom: '0px' }}>
        <h2 className={classes.tabTitle}>Contatos</h2>
      </Grid>
      {partner.contacts.length > 0 ? (
        <Grid item xs={12} key="contacts-list">
          {partner.contacts.map((contact) => (
            <Contact contact={contact} />
          ))}
        </Grid>
      ) : (
        <Grid item xs={12} key="no-contact">
          <FormLabel>Nenhum contato cadastrado</FormLabel>
        </Grid>
      )}
    </Grid>
  )
}

export default PartnerContacts
