import React from 'react'
import * as yup from 'yup'
import NewPasswordForm from './NewPasswordForm/NewPasswordForm'
import NewPasswordGuide from './NewPasswordGuide/NewPasswordGuide'
import useStyles from './NewPasswordStyle'

const validationSchema = yup.object({
  oldPassword: yup.string().required('Senha atual é obrigatória'),
  newPassword: yup
    .string()
    .matches(/[0-9]/, 'A senha tem que ter, no mínimo, um número')
    .matches(/[a-z]/, 'A senha tem que ter, no mínimo, uma letra minúscula')
    .matches(/[A-Z]/, 'A senha tem que ter, no mínimo, uma letra maiúscula')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'A senha tem que ter, no mínimo, um caractere especial')
    .min(12, 'A senha tem que ter, no mínimo, 12 caracteres')
    .test('not_equal', 'Nova senha deve ser diferente da antiga', (newPass, testContext) => {
      if (newPass === testContext.parent.oldPassword) return false
      return true
    })
    .required('Nova senha é obrigatória'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Confirmação incorreta')
    .required('Confirmação de senha é obrigatória'),
})

const NewPassword: React.FC = () => {
  const classes = useStyles()
  const [validationStatus, setValidationStatus] = React.useState({
    length: false,
    number: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
  })

  return (
    <div className={classes.mainContainer}>
      <NewPasswordForm validationSchema={validationSchema} setValidationStatus={setValidationStatus} />
      <NewPasswordGuide validationStatus={validationStatus} />
    </div>
  )
}

export default NewPassword
