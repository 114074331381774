export enum EmployeeType {
  MOTORISTA = 'MOTORISTA',
  RESPONSAVEL = 'RESPONSAVEL',
  AUXILIAR_DE_PRODUCAO = 'AUXILIAR_DE_PRODUCAO',
  TECNICO_AMBIENTAL = 'TECNICO_AMBIENTAL',
  ESTAGIARIO = 'ESTAGIARIO',
  SUCESSO_DO_CLIENTE = 'SUCESSO_DO_CLIENTE',
}

export const employeeTypes = [
  { value: 'MOTORISTA', label: 'Motorista' },
  { value: 'RESPONSAVEL', label: 'Responsável' },
  { value: 'AUXILIAR_DE_PRODUCAO', label: 'Auxiliar de Produção' },
  { value: 'TECNICO_AMBIENTAL', label: 'Técnico Ambiental' },
  { value: 'ESTAGIARIO', label: 'Estagiária (o)' },
  { value: 'SUCESSO_DO_CLIENTE', label: 'Sucesso do Cliente' },
]

export default interface Employee {
  cpf: string
  driverLicense?: string
  employeeType: EmployeeType | undefined
  idBranches: number[] | string[]
  idEmployees?: number
  name: string
}

// Nova estrutura

export interface EmployeeMinimalResponse {
  id: number
  name: string
}

export interface DriverResponse {
  cpf: string
  driverLicense: string
  identifier: string
  name: string
  partnerIdentifier: string
}
