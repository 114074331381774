import React from 'react'
import { FormLabel, Grid } from '@mui/material'
import ClientObservation from '../../../models/Client/ClientObservation'
import { getObservationsByCustomerIdentifier } from '../../../services/Client/ClientObservationsService'
import Observation from '../utils/Observation'
import useStyles from '../Styles/ViewInfoModalStyle'
import Loading from '../../Loading/Loading'

interface ClientObservationsProps {
  clientIdentifier: string
}

const ClientObservations: React.FC<ClientObservationsProps> = ({ clientIdentifier }) => {
  const [observations, setObservations] = React.useState<ClientObservation[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const classes = useStyles()

  const getClientObservations = async (identifier: string): Promise<ClientObservation[]> => {
    return getObservationsByCustomerIdentifier(identifier)
  }

  const setClientObservations = async (identifier: string): Promise<void> => {
    setLoading(true)
    const clientObservations = await getClientObservations(identifier)
    setObservations(clientObservations)
    setLoading(false)
  }

  React.useEffect(() => {
    setClientObservations(clientIdentifier)
  }, [clientIdentifier])

  return (
    <Grid container spacing={2} className={classes.mainCard}>
      {loading ? (
        <Loading isShowLoading />
      ) : (
        <>
          <Grid item xs={12} key="observation-title">
            <h2 className={classes.tabTitle}>Observações</h2>
          </Grid>
          {observations.length > 0 ? (
            observations.map((observation: ClientObservation) => <Observation observation={observation} />)
          ) : (
            <Grid item xs={12} key="no-observation">
              <FormLabel>Nenhuma observação cadastrada</FormLabel>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export default ClientObservations
