export type Env = 'localhost' | 'test' | 'prod'

export type Service = 'company' | 'collection'

export function getEnv(url: string): Env {
  if (url.indexOf('localhost') > -1) {
    return 'localhost'
  }

  if (url.indexOf('app.recicla.club') > -1) {
    return 'prod'
  }

  return 'test'
}

export function getCollectionToken(): string {
  const env: Env = getEnv(window.location.host)
  let token

  switch (env) {
    case 'localhost':
      token = '59560088-891c-4db2-8583-a0a265b935b3'
      break

    case 'test':
      token = '2b0d7c74-0072-430b-b57d-6a4ba895b161'
      break

    case 'prod':
      token = '30a634c8-9e6d-485f-a801-5a792d9fcd3d'
      break

    default:
      token = '30a634c8-9e6d-485f-a801-5a792d9fcd3d'
  }

  return token
}

function getLocalhostUrl(service: Service): string {
  let url

  switch (service) {
    case 'company':
      url = 'http://localhost:8080/api'
      break

    case 'collection':
      url = 'http://localhost:8081/api'
      break

    default:
      url = 'http://localhost:8080/api'
  }

  return url
}

function getTestUrl(service: Service): string {
  let url

  switch (service) {
    case 'company':
      url = 'https://company.api-homolog.recicla.club/api'
      break

    case 'collection':
      url = 'https://collection.api-homolog.recicla.club/api'
      break

    default:
      url = 'https://company.api-homolog.recicla.club/api'
  }

  return url
}

function getProdUrl(service: Service): string {
  let url

  switch (service) {
    case 'company':
      url = 'https://company.api.recicla.club/api'
      break

    case 'collection':
      url = 'https://collection.api.recicla.club/api'
      break

    default:
      url = 'https://company.api.recicla.club/api'
  }

  return url
}

const getEndpointUrl = (service: Service): string => {
  const env: Env = getEnv(window.location.host)
  let url

  switch (env) {
    case 'localhost':
      url = getLocalhostUrl(service)
      break

    case 'test':
      url = getTestUrl(service)
      break

    case 'prod':
      url = getProdUrl(service)
      break

    default:
      url = getTestUrl(service)
  }

  return url
}

export default getEndpointUrl
