import React from 'react'
import { Grid, Typography } from '@mui/material'
import ClientObservation from '../../../models/Client/ClientObservation'
import useStyles from '../Styles/ObservationStyle'
import treatGMTZeroDates from '../../../utils/masks/treatGMTZeroDates'

interface ObservationProps {
  observation: ClientObservation
}

const Observation: React.FC<ObservationProps> = ({ observation }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={12} className={classes.noteContainerStyle} key={`note-${observation.createdAt}`}>
      <Typography>{observation.observation}</Typography>
      <Typography className={classes.noteDateStyle}>
        {observation.createdBy} - {treatGMTZeroDates(observation.createdAt)}
      </Typography>
    </Grid>
  )
}

export default Observation
