import React from 'react'
import { Grid, Typography } from '@mui/material'
import gatheringDataConfirmation from '../../../assets/images/updates/gathering-data-confirmation-update/gathering-data-confirmation.png'

const gatheringDataConfirmationUpdateContent = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Uma caixa de confirmação será mostrada ao usuário antes de gerar um novo MTR. Nela terá informações sobre as
          partes envolvidas, dados gerais e opcionais do Manifesto e dos resíduos inclusos. Veja abaixo:
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={gatheringDataConfirmation} alt="Demonstração da caixa de confirmação de dados do MTR" />
        <div>
          <Typography variant="caption">Demonstração da caixa de confirmação de dados do MTR</Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default gatheringDataConfirmationUpdateContent
