import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  cardContainerRight: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: '16px',
  },
  cardContainerLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: '16px',
  },
  cardCalendarRight: {
    position: 'absolute',
    top: '95px',
    right: '15px',
    zIndex: '101',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  cardCalendarLeft: {
    position: 'absolute',
    top: '95px',
    left: '15px',
    zIndex: '101',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    border: 'none',
    paddingRight: 32,
  },
  closeButtonRight: {
    position: 'absolute',
    right: '42px',
    zIndex: '100',
  },
  closeButtonLeft: {
    marginTop: '15px',
    marginLeft: '10px',
  },
})

export default useStyles
