import { UserFullResponse } from './models/User/user'
import { getCustomersByEmployeeId } from './services/Employee/EmployeeService'

const handleAmplifiqueMeResearch = async (userInfo: UserFullResponse): Promise<boolean> => {
  const userCompanies = (await getCustomersByEmployeeId(Number(userInfo?.employeeId)))
    .map((customer) => customer.name)
    .join(', ')

  return new Promise((resolve, reject) => {
    const tag = document.createElement<any>('ampl-survey')
    tag.identifier = '669a995827bc8399eb459976'
    tag.debug = true
    tag.force = false
    tag.anonymous = false
    document.body.appendChild(tag)
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://cdn.amplifique.me/amplifiqueme-inapp-survey.js'
    script.defer = true
    document.body.appendChild(script)
    ;(window as any).Amplifiqueme = {}
    ;(window as any).Amplifiqueme.onLoad = () => {
      if ((window as any).Amplifiqueme.loaded) {
        ;(window as any).Amplifiqueme.identify(
          {
            name: userInfo?.employeeName,
            email: userInfo?.username,
            createdAt: new Date(userInfo?.createdAt).getTime() || undefined,
            company: userCompanies[0],
            custom_fields: {
              versao: '1.0',
              nivel: 'administrador',
            },
          },
          true
        )
      }
      return resolve(true)
    }
  })
}

export default handleAmplifiqueMeResearch
