import axios, { AxiosResponse } from 'axios'

import getEndpointUrl from '../../utils/url-resolver'
import { CompanyContactsInfo } from '../../models/Company/Company'

const baseUrl = `${getEndpointUrl('company')}/v1/partners`

export const getPartnerContacts = async (partnerIdentifier: string): Promise<CompanyContactsInfo[]> => {
  return axios.get(`${baseUrl}/${partnerIdentifier}/contacts`).then((response) => response.data)
}

export const createPartnerContact = async (
  partnerIdentifier: string,
  contact: CompanyContactsInfo
): Promise<CompanyContactsInfo> => {
  return axios.post(`${baseUrl}/${partnerIdentifier}/contacts`, contact)
}

export const updatePartnerContact = async (
  partnerIdentifier: string,
  contactIdentifier: string,
  contact: CompanyContactsInfo
): Promise<CompanyContactsInfo> => {
  return axios.put(`${baseUrl}/${partnerIdentifier}/contacts/${contactIdentifier}`, contact)
}
