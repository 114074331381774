import axios from 'axios'

import getEndpointUrl from '../../utils/url-resolver'
import { Vehicle } from '../../models/Vehicle/Vehicle'

const baseUrl = `${getEndpointUrl('company')}/v1/vehicles`

const getVehicleByIdentifier = (identifier: string): Promise<Vehicle> =>
  axios.get<Vehicle>(`${baseUrl}/${identifier}`).then((response) => response.data)

const getVehiclesByPartnerIdentifier = (partnerIdentifier: string): Promise<Vehicle[]> =>
  axios.get<Vehicle[]>(`${baseUrl}/partner/${partnerIdentifier}`).then((response) => response.data)

const createVehicle = (vehicle: Vehicle): Promise<void> =>
  axios.post(`${baseUrl}/`, vehicle).then((response) => response.data)

const updateVehicle = (identifier: string, vehicle: Vehicle): Promise<void> =>
  axios.put(`${baseUrl}/${identifier}`, vehicle).then((response) => response.data)

const deleteVehicle = (identifier: string): Promise<void> => axios.delete(`${baseUrl}/${identifier}`)

export { createVehicle, updateVehicle, deleteVehicle, getVehicleByIdentifier, getVehiclesByPartnerIdentifier }
