import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import {
  Drawer,
  Typography,
  Card,
  Button,
  Checkbox,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  IconButton,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'
import useYupValidationResolver from '../../../utils/yup-validator-resolver'
import {
  featuresType,
  acessLevelCreated,
  acessLevel as acessLevelType,
  acessLevelUpdatedToSubmit,
} from '../../../models/Setting/Settings'
import { createAcessLevel, updateAcessLevel } from '../../../services/Settings/acessLevelService'
import AppContext, { AppContextType } from '../../../AppContext'
import Input from '../../../components/Form/Input/Input'
import { useStyles } from './acessLevelDrawerStyles'

export type AcessLevelDrawerProps = {
  open: boolean
  title: string
  handleClose: () => void
  features: featuresType[]
  acessLevel?: acessLevelType
  updateHandler: (b: boolean) => void
  updateAux: boolean
}

const acessLevelValidationSchema = yup.object({
  name: yup.string().required('Campo obrigatório'),
})

export const AcessLevelDrawer: React.FC<AcessLevelDrawerProps> = ({
  open,
  title,
  handleClose,
  features,
  acessLevel,
  updateHandler,
  updateAux,
}: AcessLevelDrawerProps) => {
  const classes = useStyles()
  const resolver = useYupValidationResolver(acessLevelValidationSchema)
  const [selectedFeatures, setSelectedFeatures] = React.useState<string[]>([])
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>([])
  const { handleSubmit, setValue, control, formState } = useForm<acessLevelCreated>({
    resolver,
  })
  const { showAlert, setIsShowLoading } = React.useContext(AppContext as React.Context<AppContextType>)

  const featuresCategories = features.reduce<string[]>((acc, curr) => {
    if (!acc.find((c) => c === curr.category)) {
      acc.push(curr.category)
    }
    return acc
  }, [])

  const countRepeated = (array: string[]): { [key: string]: number } => {
    const elementosRepetidos: { [key: string]: number } = {}

    array.forEach((elemento) => {
      elementosRepetidos[elemento] = elementosRepetidos[elemento] ? elementosRepetidos[elemento] + 1 : 1
    })

    const chavesFaltantes = [
      'geral',
      'mtr',
      'veículos',
      'relátorios',
      'documentação ambiental',
      'ajuda',
      'usuários',
      'empresas',
      'funcionários',
      'contratos',
      'relátorios',
    ]

    chavesFaltantes.forEach((chave) => {
      if (!elementosRepetidos[chave]) {
        elementosRepetidos[chave] = 0
      }
    })

    return elementosRepetidos
  }

  const handleCheckboxCategoryChange = (category: React.ChangeEvent<HTMLInputElement>): void => {
    const categoryName = category.target.value
    if (category.target.checked) {
      if (!selectedCategories.find((c) => c === categoryName)) {
        setSelectedCategories([...selectedCategories, categoryName])
        setSelectedFeatures([
          ...selectedFeatures.filter((f, i, arr) => arr.indexOf(f) === i),
          ...features.filter((f) => f.category === categoryName).map((f) => `${f.name}-${f.category}`),
        ])
      }
    } else {
      setSelectedCategories(selectedCategories.filter((c) => c !== categoryName))
      setSelectedFeatures(selectedFeatures.filter((f) => f.split('-')[1] !== categoryName))
    }
  }

  const handleCheckboxChange = (featEvent: React.ChangeEvent<HTMLInputElement>): void => {
    const feat = featEvent.target.value
    if (featEvent.target.checked) {
      if (!selectedFeatures.find((f) => f === feat)) {
        setSelectedFeatures([...selectedFeatures, feat])
        const cats: string[] = selectedFeatures
          .filter((f) => {
            const selectedFeats = countRepeated(selectedFeatures.map((selectedFeat) => selectedFeat.split('-')[1]))
            const allFeats = countRepeated(features.map((singleFeat) => singleFeat.category))
            return selectedFeats[f.split('-')[1]] === allFeats[f.split('-')[1]]
          })
          .map((f) => f.split('-')[1])
        setSelectedCategories(cats)
      }
    } else {
      setSelectedFeatures(selectedFeatures.filter((f) => f !== feat))
      setSelectedCategories(selectedCategories.filter((c) => c !== feat.split('-')[1]))
    }
  }

  const onSubmit = (data: acessLevelCreated): void => {
    setIsShowLoading(true)
    let featsIDs: number[] = []
    setSelectedFeatures(data.features.filter((value, index, arr) => arr.indexOf(value) === index))
    data.features.forEach((feat) => {
      const featID = features.filter((f) => feat.split('-')[0] === f.name).map((f) => f.idFeature)[0]
      if (featID) featsIDs.push(featID)
    })
    featsIDs = featsIDs.filter((value, index, arr) => arr.indexOf(value) === index).sort()
    const newData = {
      name: data.name,
      features: featsIDs,
    }
    if (acessLevel) {
      const acessLevelEdited: acessLevelUpdatedToSubmit = {
        idCargo: acessLevel.idCargo,
        name: data.name,
        features: featsIDs as number[],
      }
      updateAcessLevel(acessLevelEdited)
        .then(() => {
          showAlert('success', 'Cargo atualizado com sucesso')
        })
        .catch(() => {
          showAlert('error', 'Erro ao atualizar cargo')
        })
        .finally(() => {
          updateHandler(!updateAux)
          handleClose()
          setIsShowLoading(false)
        })
    } else {
      createAcessLevel(newData)
        .then(() => {
          showAlert('success', 'Cargo criado com sucesso')
        })
        .catch(() => {
          showAlert('error', 'Erro ao criar cargo')
        })
        .finally(() => {
          updateHandler(!updateAux)
          handleClose()
          setIsShowLoading(false)
        })
    }
  }

  useEffect(() => {
    if (acessLevel) {
      setValue('name', acessLevel.name)
      const feats = acessLevel.features.map((f) => `${f.name}-${f.category}`)
      const cats: string[] = acessLevel.features
        .filter((f) => {
          const selectedFeats = countRepeated(feats.map((feat) => feat.split('-')[1]))
          const allFeats = countRepeated(features.map((feat) => feat.category))
          return selectedFeats[f.category] === allFeats[f.category]
        })
        .map((f) => f.category)
      setSelectedFeatures(feats)
      setSelectedCategories(cats)
    }
  }, [acessLevel])

  useEffect(() => {
    setValue('features', selectedFeatures)
    // eslint-disable-next-line no-useless-return
    return
  }, [selectedFeatures, selectedCategories])

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <CloseIcon sx={{ position: 'absolute', top: '10px', right: '10px' }} onClick={() => handleClose()} />
      <Card className={classes.generalContainer}>
        <Typography sx={{ marginBottom: '20px' }}>
          {title === 'new' ? 'Novo nivel de acesso' : 'Editar nivel de acesso'}
        </Typography>
        <form style={{ width: '80%' }} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                className={classes.nameImput}
                id="acessLevelName"
                label="Nome do cargo"
                errorText={formState.errors?.name?.message}
                {...field}
              />
            )}
          />
          <Grid className={classes.featsContainer}>
            {featuresCategories.map((name, i) => (
              <Accordion className={classes.accordionContainer} key={name}>
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ExpandMoreIcon sx={{ color: '#43b849' }} />}
                >
                  <Grid className={classes.checkBoxSummaryContainer}>
                    <Typography sx={{ color: '#43b849' }}>{name}</Typography>
                    <Checkbox
                      value={name}
                      id={`checkbox-${name}`}
                      checked={!!selectedCategories.find((c) => c === name)}
                      onChange={(e) => handleCheckboxCategoryChange(e)}
                      className={classes.checkBox}
                    />
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0' }}>
                  {features.map(
                    (feature) =>
                      feature.category === name && (
                        <Grid className={classes.featContainer} key={feature.name}>
                          <Grid className={classes.feat}>
                            <Grid>{feature.name}</Grid>
                            <Tooltip title={feature.description}>
                              <IconButton>
                                <InfoOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid sx={{ borderLeft: '1px solid lightgrey' }}>
                            <Checkbox
                              size="small"
                              value={`${feature.name}-${name}`}
                              checked={selectedFeatures.includes(`${feature.name}-${name}`)}
                              onChange={(e) => handleCheckboxChange(e)}
                              className={classes.checkBox}
                            />
                          </Grid>
                        </Grid>
                      )
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
          <Button
            sx={{
              borderRadius: '50px',
              width: '250px',
              fontSize: '12px',
              marginRight: '10%',
              height: '50px',
            }}
            variant="contained"
            data-testid="submit-button"
            color="primary"
            type="submit"
          >
            {title === 'new' ? 'Criar nivel de acesso' : 'Atualizar nivel de acesso'}
          </Button>
        </form>
      </Card>
    </Drawer>
  )
}
