const ApplicationColors = {
  primaryColor: '#33B652',
  disabledColor: '#D1D2D1',
  success90: '#DAFFBB',
  Success60: '#93C84C',
  warning60: '#F2B208',
  warning80: '#FCE679',
  error50: '#9B1E19',
  error80: '#DE9993',
  error90: '#F9DEDC',
  neutral90: '#E9E9E8',
}
export { ApplicationColors }
