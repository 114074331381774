import { Autocomplete, AutocompleteChangeReason, AutocompleteRenderInputParams, Chip, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import React from 'react'

export interface Select2Options {
  value: string | number
  label: string | number
  disabled?: boolean
  id?: string
}

export type InputSelect2PropsMultiple = {
  id: string
  label: string
  onChange: (event: React.SyntheticEvent, value: Select2Options[] | null, reason: AutocompleteChangeReason) => void
  options: Select2Options[]
  errorText?: string
  clearable?: boolean
  value: (number | string)[] | undefined
}

const InputSelect2Multiple: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, InputSelect2PropsMultiple> = (
  { id, label, onChange, options, errorText, clearable = false, ...rest },
  ref
) => {
  const hasError = !!errorText
  const disabledValues = options.filter((option) => option.disabled).map((option) => option.value)
  const checkValue = (option: Select2Options, value: Select2Options | null): boolean => {
    return option.value === value?.value
  }
  const disableOption = (option: Select2Options): boolean => {
    return disabledValues.includes(option.value)
  }
  const initialValue = options.filter((option) => {
    if (rest.value === undefined) return false
    return rest.value.includes(option.value)
  })

  return (
    <>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          fullWidth
          id={id}
          onChange={onChange}
          renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
            <TextField inputRef={ref} label={label} error={hasError} {...params} />
          )}
          data-cy={`input-select-multiple-${id}`}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.value}
              data-cy={`input-multiple-option-${option.label.toString().replaceAll(' ', '')}`}
            >
              {option.label}
            </li>
          )}
          options={options}
          isOptionEqualToValue={checkValue}
          getOptionDisabled={disableOption}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.label}
                data-cy={`input-multiple-tag-${option.label.toString().replaceAll(' ', '')}`}
                sx={{ backgroundColor: '#E3F6E8', color: '#1A1C18', margin: '2px' }}
                {...getTagProps({ index })}
              />
            ))
          }
          noOptionsText="Nenhum resultado encontrado"
          value={initialValue}
          disableClearable={!clearable}
          multiple
        />
      </FormControl>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </>
  )
}

export default React.forwardRef(InputSelect2Multiple)
