import React from 'react'
import { Grid, Typography } from '@mui/material'
import allPartnersButton from '../../../assets/images/updates/all-partners-option-employee-form-update/all-partners-button-employee-form.png'

const allPartnersButtonEmployeeFormUpdateContent = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Agora é possível associar um funcionário a todas as empresas com apenas um clique. Nesta atualização,
          adicionamos dois botões no formulário de funcionários: um para selecionar todas as empresas e outro para
          desmarcar todas as empresas. Veja:
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={allPartnersButton} alt="Novos botões para associar e desassociar todas as empresas ao funcionário." />
        <div>
          <Typography variant="caption">
            Novos botões para associar e desassociar todas as empresas ao funcionário.
          </Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default allPartnersButtonEmployeeFormUpdateContent
