import React, { Suspense } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Grid, Typography } from '@mui/material'
import AppContext, { AppContextType } from '../../AppContext'
import useStyles from '../SystemUpdates/SystemUpdatesStyle'
import { getUpdateByIdentifier } from './systemUpdates'
import NotFound from './NotFound'
import { defaultBreadCrumbItems } from '../../components/BreadCrumb/BreadCrumb'
import { ISystemUpdates2 } from '../../models/SystemUpdate/update'
import { getLoggedUser } from '../../services/Auth/TokenService'

const SystemUpdates2: React.FC = () => {
  const { setIsShowLoading, setTitle, setItemsBreadCrumb } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const { identifier } = useParams<{ identifier: string }>()
  const classes = useStyles()
  const systemUpdate = getUpdateByIdentifier(identifier ?? '')
  const navigate = useNavigate()

  const UpdateComponent = React.lazy(() =>
    import(`./Updates/${identifier}`).catch(() => import('./NotFound')).finally(() => setIsShowLoading(false))
  )

  const checkUserPermission = React.useCallback(
    (selectedUpdate: ISystemUpdates2 | undefined): void => {
      const userCargos = getLoggedUser()?.cargos || []
      const isUserAllowed = selectedUpdate
        ? userCargos.some((cargo) => selectedUpdate.allowedRoles.includes(cargo.name))
        : true
      if (!isUserAllowed) navigate('/main/access-denied')
    },
    [navigate]
  )

  React.useEffect(() => {
    setTitle('Ver Atualização')
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Ver atualização', path: `/main/system-updates2/${identifier}` },
    ])
    if (systemUpdate) setIsShowLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    checkUserPermission(systemUpdate)
  }, [checkUserPermission, systemUpdate])

  return systemUpdate ? (
    <div className={classes.mainContainer}>
      <Grid container>
        <Grid item xs={12}>
          <Card className="card-filters" variant="outlined">
            <div>
              <div className={classes.updateHeader}>
                <h2 data-cy="update-title" className={classes.titleStyle}>
                  {systemUpdate.title}
                </h2>
                <Typography variant="caption" data-cy="update-date">
                  {systemUpdate.date}
                </Typography>
              </div>
              <Suspense fallback={<div>Carregando...</div>}>
                <UpdateComponent />
              </Suspense>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  ) : (
    <NotFound />
  )
}

export default SystemUpdates2
