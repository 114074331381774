import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStylesHome = makeStyles(() =>
  createStyles({
    homePageContainer: {
      height: '100%',
      width: '100%',
      position: 'relative',
      borderRadius: '0px !important',
      '& iframe:nth-child(1)': {
        width: 'inherit',
        height: 'inherit',
        borderWidth: '0px',
      },
      '& iframe:nth-child(2)': {
        width: 'inherit',
        height: '92%',
        borderWidth: '0px',
      },
    },
    homePageContainerEmpty: {
      height: '100%',
      width: '100%',
      position: 'relative',
      borderRadius: '0px !important',
    },
  })
)

export default useStylesHome
