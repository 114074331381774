import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import PartnerContact from './components/PartnerContact'
import { PartnerFormType } from '../../../../models/Company/Company'

interface PartnerContactProps {
  form: UseFormReturn<PartnerFormType>
}

const PartnerContactTab: React.FC<PartnerContactProps> = ({ form }) => {
  return <PartnerContact form={form} />
}

export default PartnerContactTab
