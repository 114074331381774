import React from 'react'
import { Grid, Typography } from '@mui/material'
import residuesQuantityField from '../../../assets/images/updates/residues-quantity-field-update/residues-quantity-field.png'

const residuesQuantityFieldUpdateContent = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Adicionamos um campo de edição de quantidade na listagem de resíduos na geração de MTR. Tal alteração trás
          mais agilidade de edição de dados para aqueles que estão usando Modelos de MTR salvos e precisam apenas
          ajustar quanto cada resíduo pesa. Veja:
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={residuesQuantityField} alt="Demonstração do novo campo exterior de quantidade do resíduo." />
        <div>
          <Typography variant="caption">Demonstração do novo campo exterior de quantidade do resíduo.</Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default residuesQuantityFieldUpdateContent
