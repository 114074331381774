import React from 'react'

import Drawer from '@mui/material/Drawer'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'

import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import Input from '../../../components/Form/Input/Input'
import { featuresType } from '../../../models/Setting/Settings'
import useYupValidationResolver from '../../../utils/yup-validator-resolver'
import AppContext, { AppContextType } from '../../../AppContext'
import { updateFeature } from '../../../services/Settings/featureService'
import { useStyles } from './updateFeatDrawerStyles'

interface UpdateFeatDrawerProps {
  open: boolean
  handleClose: () => void
  feature?: featuresType
  updateHandler: (b: boolean) => void
  updateAux: boolean
}

const updateFeatValidationSchema = yup.object({
  name: yup.string(),
  description: yup.string(),
})

export const UpdateFeatDrawer: React.FC<UpdateFeatDrawerProps> = ({
  open,
  handleClose,
  feature,
  updateHandler,
  updateAux,
}) => {
  const resolver = useYupValidationResolver(updateFeatValidationSchema)
  const { showAlert } = React.useContext(AppContext as React.Context<AppContextType>)
  const {
    formState: { isDirty },
    setValue,
    handleSubmit,
    control,
  } = useForm<featuresType>({
    resolver,
    defaultValues: {
      name: feature?.name,
      description: feature?.description,
    },
  })
  const classes = useStyles()

  React.useEffect(() => {
    if (feature) {
      setValue('name', feature.name)
      setValue('description', feature.description)
    }
  }, [])

  const onSubmit = (data: featuresType): void => {
    if (feature) {
      const featEdited: featuresType = {
        idFeature: feature.idFeature,
        name: data.name,
        description: data.description,
        authority: feature.authority,
        category: feature.category,
      }
      if (isDirty) {
        updateFeature(featEdited)
          .then(() => {
            updateHandler(!updateAux)
            handleClose()
            showAlert('success', 'Feature atualizada com sucesso')
          })
          .catch(() => {
            showAlert('error', 'Erro ao atualizar feature')
          })
      } else {
        showAlert('warning', 'Nenhuma alteração foi feita')
      }
    }
  }

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue('name', e.target.value, { shouldDirty: true })
  }
  const onChangeDescription = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue('description', e.target.value, { shouldDirty: true })
  }

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <CloseIcon sx={{ position: 'absolute', top: '5px', right: '5px' }} onClick={() => handleClose()} />
      <Card className={classes.generalContainer}>
        <Typography sx={{ marginBottom: '30px' }}>Editar feature</Typography>
        <form className={classes.containerForm} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                id="featureName"
                sx={{ marginBottom: '10px' }}
                label="Nome da feature"
                {...field}
                onChange={onChangeName}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                id="featureDescription"
                label="Descrição da feature"
                sx={{ marginBottom: '20px' }}
                {...field}
                onChange={onChangeDescription}
              />
            )}
          />
          <Button
            className={classes.updateButton}
            sx={{ borderRadius: '50px' }}
            variant="contained"
            color="primary"
            type="submit"
          >
            Atualizar
          </Button>
        </form>
      </Card>
    </Drawer>
  )
}
