import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      background: '#43b849',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      maxWidth: '600px',
      padding: '24px',
      display: 'flex',
      flexFlow: 'column nowrap',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
      },
    },
    logo: {
      height: '50%',
      width: '50%',
      marginBottom: '40px',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        height: '100px',
      },
    },
  })
)

export default useStyles
