import slugfyString from '../../../utils/SlugfyString'

export const residuesOptions = [
  {
    value: 'Alumínio',
    label: 'Alumínio',
  },
  {
    value: 'Dedetizadora e desentupidora',
    label: 'Dedetizadora e desentupidora',
  },
  {
    value: 'Efluentes',
    label: 'Efluentes',
  },
  {
    value: 'Entulho',
    label: 'Entulho',
  },
  {
    value: 'Embalagens de óleo lubrificante',
    label: 'Embalagens de óleo lubrificante',
  },
  {
    value: 'Filtros de ar condicionado',
    label: 'Filtros de ar condicionado',
  },
  {
    value: 'Gordura',
    label: 'Gordura',
  },
  {
    value: 'Inservíveis',
    label: 'Inservíveis',
  },
  {
    value: 'Isopor',
    label: 'Isopor',
  },
  {
    value: 'Kraft',
    label: 'Kraft',
  },
  {
    value: 'Lâmpadas',
    label: 'Lâmpadas',
  },
  {
    value: 'Lodo',
    label: 'Lodo',
  },
  {
    value: 'Lixo comum',
    label: 'Lixo comum',
  },
  {
    value: 'Madeira',
    label: 'Madeira',
  },
  {
    value: 'Metal',
    label: 'Metal',
  },
  {
    value: 'Óleo',
    label: 'Óleo',
  },
  {
    value: 'Óleo (refino interno do cliente)',
    label: 'Óleo (refino interno do cliente)',
  },
  {
    value: 'Orgânico',
    label: 'Orgânico',
  },
  {
    value: 'Papelão',
    label: 'Papelão',
  },
  {
    value: 'Pallets (paletes)',
    label: 'Pallets (paletes)',
  },
  {
    value: 'Papel',
    label: 'Papel',
  },
  {
    value: 'Papel siliconado',
    label: 'Papel siliconado',
  },
  {
    value: 'Plástico',
    label: 'Plástico',
  },
  {
    value: 'Pneus',
    label: 'Pneus',
  },
  {
    value: 'Podas',
    label: 'Podas',
  },
  {
    value: 'Produto impróprio e recicláveis contaminados',
    label: 'Produto impróprio e recicláveis contaminados',
  },
  {
    value: 'Ráfia',
    label: 'Ráfia',
  },
  {
    value: 'Resíduos da construção civil',
    label: 'Resíduos da construção civil',
  },
  {
    value: 'Resíduos de saúde',
    label: 'Resíduos de saúde',
  },
  {
    value: 'Recicláveis',
    label: 'Recicláveis',
  },
  {
    value: 'Resíduos perigosos',
    label: 'Resíduos perigosos',
  },
  {
    value: 'Resíduos não perigosos',
    label: 'Resíduos não perigosos',
  },
  {
    value: 'Sucatas',
    label: 'Sucatas',
  },
  {
    value: 'Sucata Metálica',
    label: 'Sucata Metálica',
  },
  {
    value: 'Sucata Metálica contaminada',
    label: 'Sucata Metálica contaminada',
  },
  {
    value: 'Tambores',
    label: 'Tambores',
  },
  {
    value: 'Tambores contaminados',
    label: 'Tambores contaminados',
  },
  {
    value: 'Tambores para reutilização',
    label: 'Tambores para reutilização',
  },
  {
    value: 'Vidro',
    label: 'Vidro',
  },
]

export const operationOptions = [
  {
    value: 'Ativo',
    label: 'Ativo',
  },
  {
    value: 'Adormecido',
    label: 'Adormecido',
  },
  {
    value: 'Inativo',
    label: 'Inativo',
  },
  {
    value: 'Bloqueado',
    label: 'Bloqueado',
  },
]

export const homologationStatusOptions = [
  {
    value: 'Homologado',
    label: 'Homologado',
  },
  {
    value: 'Não Homologado',
    label: 'Não Homologado',
  },
  {
    value: 'Aguardando homologação',
    label: 'Aguardando homologação',
  },
]
