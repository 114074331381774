import React from 'react'
import { Card } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { ClientFormType } from '../../../../../models/Company/Company'
import { useStyles } from '../../../CompanyFormStyle'
import ContactForm from '../../../Components/Contact/ContactForm'

interface ClientContactsProps {
  form: UseFormReturn<ClientFormType>
}

const ClientContacts: React.FC<ClientContactsProps> = ({ form }) => {
  const { formState } = form
  const companyFormclasses = useStyles()

  return (
    <Card className="form-card" variant="outlined">
      <h2 className={companyFormclasses.tabTitle}>Contatos</h2>
      <h3 style={{ color: '#33B652', fontWeight: 'lighter', paddingTop: '30px' }}>Financeiro</h3>
      <ContactForm form={form} contactType="contacts.financeContact" />
      <h3 style={{ color: '#33B652', fontWeight: 'lighter', paddingTop: '30px' }}>Operação</h3>
      <ContactForm form={form} contactType="contacts.operationalContact" />
      <h3 style={{ color: '#33B652', fontWeight: 'lighter', paddingTop: '30px' }}>Comercial</h3>
      <ContactForm form={form} contactType="contacts.commercialContact" />
      <h3 style={{ color: '#33B652', fontWeight: 'lighter', paddingTop: '30px' }}>Diretoria</h3>
      <ContactForm form={form} contactType="contacts.managementContact" />
    </Card>
  )
}

export default ClientContacts
