import React from 'react'
import { Grid, Typography } from '@mui/material'
import viewModal from '../../../assets/images/updates/partner-info-modal-update/partner-infomodal-visualization.gif'

const partnerInfoVisualizationContent = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          A visualização detalhada de cada empresa agora também é possível na listagem de parceiros. Tal detalhamento
          inclui dados primários, dados técnicos e a lista de contatos disponível.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          O acesso a edição do parceiro também se tornou mais fácil através do botão &quot;Editar&quot;, localizado no
          canto inferior direito da caixa de informações aberta.
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={viewModal} alt="Visualização de dados do cliente" style={{ width: '90%' }} />
        <div>
          <Typography variant="caption">Navegação pela caixa de visualização de dados do parceiro.</Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default partnerInfoVisualizationContent
