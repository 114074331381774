import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    headerButton: {
      color: 'white !important',
      borderRadius: '100px !important',
      boxShadow: 'none !important',
      padding: '10px 20px !important',
      marginRight: '10px !important',
      border: '2px solid white !important',
      transition: 'none !important',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    companyFilter: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexBasis: '50%',
    },
    actionButtonContainer: {
      flexBasis: '50%',
      justifyContent: 'flex-end',
      paddingRight: '16px',
    },
    actionButton: {
      '&.MuiButton-root': {
        padding: '10px 20px',
        marginRight: '10px',
        marginTop: 'max(min(550px - 100vw, 2px), 0px)',
        marginBottom: 'max(min(550px - 100vw, 2px), 0px)',
        color: '#1A1C18',
        border: '1px solid #33B652',
        borderRadius: '25px',
      },
    },
  })
)

export default useStyles
