import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tutorial: {
      marginBottom: '20px',
    },
    title: {
      fontSize: '1.5w',
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '80%',
        textAlign: 'justify',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '5.0vw',
      },
    },
    paragraph: {
      paddingBottom: '20px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '40%',
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
    },
    button: {
      height: '50px',
      width: '230px',
      borderRadius: 10,
      fontSize: 'small',
      [theme.breakpoints.down('sm')]: {
        padding: '20px',
      },
    },
  })
)
