import FormHelperText from '@mui/material/FormHelperText'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import React from 'react'

export type InputTextProps = {
  id: string
  label?: string
  type?: string
  helperText?: string
  errorText?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
} & TextFieldProps

const InputMaskCEP: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, InputTextProps> = (
  { id, label, type, helperText, errorText, ...rest },
  ref
) => {
  const hasError = !!errorText

  const maskCEP = (value: string): string => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{1})/, '$1.$2')
      .replace(/(\d{3})(\d{1})/, '$1-$2')
      .replace(/(-\d{3})(\d+?)$/, '$1')
  }

  return (
    <>
      <TextField
        fullWidth
        autoComplete="nope"
        variant="outlined"
        type={type || 'text'}
        error={hasError}
        data-cy="input-zipcode"
        id={id}
        label={label}
        helperText={errorText}
        inputRef={ref}
        {...rest}
        onChange={(e) => {
          e.target.value = maskCEP(e.target.value)
          rest.onChange(e)
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  )
}

export default React.forwardRef(InputMaskCEP)
