import React from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ClientForm from '../ClientForm'
import { ClientFormType } from '../../../../models/Company/Company'
import AppContext, { AppContextType } from '../../../../AppContext'
import { defaultBreadCrumbItems } from '../../../../components/BreadCrumb/BreadCrumb'
import useYupValidationResolver from '../../../../utils/yup-validator-resolver'
import getValidationSchema from '../utils/companyValidationSchema'
import setRequestData, { setClientContactData } from '../utils/setRequestData'
import {
  getClientByIdentifier,
  createClientContact,
  saveClienteFacade,
  updateClient,
  updateClientContact,
} from '../../../../services/Client/ClientService'
import { ClientResponseI } from '../../../../models/Client/Client'
import { ClientContract, ClientContractFileInfo } from '../../../../models/Client/ClientContract'
import {
  createClientContract,
  getCustomerContractsFilesList,
  getLatestContract,
  saveCustomerContractFile,
  updateClientContract,
} from '../../../../services/Client/ClientContractService'
import maskCNPJ from '../../../../utils/masks/maskCNPJ'

const EditClientForm: React.FC = () => {
  const { identifier } = useParams<{ identifier: string }>()
  const validationSchema = getValidationSchema(identifier)
  const resolver = useYupValidationResolver(validationSchema)
  const form = useForm<ClientFormType>({ resolver, mode: 'onChange' })
  const { reset, setValue, getValues } = form
  const { setItemsBreadCrumb, setTitle, setIsShowLoading, showAlert } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const [hasPurchasePlan, setHasPurchasePlan] = React.useState<boolean>(false)

  const setContractFileLinkByContractIdentifier = async (contractIdentifier: string): Promise<void> => {
    const contractFileInfo = await getLatestContract(identifier as string, contractIdentifier)
    if (contractFileInfo) {
      setValue('contractFileLink', contractFileInfo.link)
      setValue('contractLatestVersion', contractFileInfo.version_number)
    }
  }

  const setPrimaryInfos = (companyInfo: ClientResponseI): void => {
    reset({
      companyName: companyInfo.primaryData.companyName,
      cnpj: maskCNPJ(companyInfo.primaryData.cnpj),
      corporateName: companyInfo.primaryData.name,
      greenMonitorLink: companyInfo.primaryData.greenMonitorLink,
      cep: companyInfo.primaryData.address.zipcode,
      state: companyInfo.primaryData.address.state,
      city: companyInfo.primaryData.address.city,
      district: companyInfo.primaryData.address.district,
      street: companyInfo.primaryData.address.street,
      number: companyInfo.primaryData.address.number,
      complement: companyInfo.primaryData.address.complement,
      cashbackPercentage: Number((companyInfo.contract.cashbackPercentage * 100).toFixed(2)),
      paymentTime: companyInfo.contract.paymentTime,
      purchasedPlan: companyInfo.contract.purchasedPlan,
      status: companyInfo.contract.status === 1 && true,
      expirationDate: companyInfo.contract.expirationDate,
      expirationStatus: companyInfo.contract.expirationStatus,
      minimumValue: companyInfo.contract.minimumValue,
      contractIdentifier: companyInfo.contract.identifier,
      branch: companyInfo.primaryData.mtrSystemAccess.mtrSystemUnitCode,
      system: companyInfo.primaryData.mtrSystemAccess.mtrSystemName,
      cpf: companyInfo.primaryData.mtrSystemAccess.mtrSystemUser,
      password: companyInfo.primaryData.mtrSystemAccess.mtrSystemPass,
    })

    if (companyInfo.contract.purchasedPlan) {
      setHasPurchasePlan(true)
    }

    setContractFileLinkByContractIdentifier(companyInfo.contract.identifier)
  }

  const contactTypeToPrefixMap = {
    Financeiro: 'contacts.financeContact',
    Operacional: 'contacts.operationalContact',
    Comercial: 'contacts.commercialContact',
    Diretoria: 'contacts.managementContact',
  }

  const setClientContacts = (companyInfo: ClientResponseI): void => {
    companyInfo.contacts.forEach((contact) => {
      const prefix = contactTypeToPrefixMap[contact.type as keyof typeof contactTypeToPrefixMap]
      if (prefix) {
        setValue(`${prefix}.name` as keyof ClientFormType, contact.name)
        setValue(`${prefix}.surname` as keyof ClientFormType, contact.surname)
        setValue(`${prefix}.email` as keyof ClientFormType, contact.email)
        setValue(`${prefix}.telephone` as keyof ClientFormType, contact.telephone)
        setValue(`${prefix}.identifier` as keyof ClientFormType, contact.identifier)
      }
    })
  }

  const setCompanysInfo = React.useCallback((): void => {
    setIsShowLoading(true)
    getClientByIdentifier(identifier as string)
      .then((companyInfo: ClientResponseI) => {
        setPrimaryInfos(companyInfo)
        setClientContacts(companyInfo)
      })
      .catch(() => {
        showAlert('error', 'Erro ao capturar informações da empresa.')
      })
      .finally(() => {
        setIsShowLoading(false)
      })
  }, [identifier, reset, showAlert])

  const requestUpdateClient = async (data: ClientFormType): Promise<string> => {
    setIsShowLoading(true)
    try {
      const companyUpdateRequestInfo = setRequestData(data)
      await updateClient(identifier as string, companyUpdateRequestInfo)
      showAlert('success', 'Cliente atualizado com sucesso')
      return identifier as string
    } catch {
      setIsShowLoading(false)
      throw new Error('Erro ao atualizar cliente')
    }
  }

  const requestUpdateFacade = async (idtf: string, file: File): Promise<void> => {
    setIsShowLoading(true)
    try {
      await saveClienteFacade(idtf, file)
      showAlert('success', 'Fachada atualizada com sucesso.')
    } catch {
      setIsShowLoading(false)
      throw new Error('Erro ao atualizar fachada')
    }
  }

  const requestUpdateContract = async (data: ClientFormType): Promise<void> => {
    const contractInfo: ClientContract = {
      cashbackPercentage: data.cashbackPercentage / 100,
      paymentTime: data.paymentTime,
      purchasedPlan: data.purchasedPlan,
      status: data.status ? 1 : 0,
      minimumValue: data.minimumValue,
      expirationDate: data.expirationDate,
    }
    try {
      if (hasPurchasePlan) {
        await updateClientContract(contractInfo, identifier as string)
      } else {
        await createClientContract(contractInfo, identifier as string)
        setHasPurchasePlan(true)
      }
    } catch {
      setIsShowLoading(false)
      throw new Error('Erro ao salvar informações do contrato')
    }
  }

  const requestSaveContractFile = async (): Promise<void> => {
    setIsShowLoading(true)
    const file = form.getValues('contractFile')
    const contractIdentifier = form.getValues('contractIdentifier') as string
    if (file) {
      try {
        await saveCustomerContractFile(identifier as string, contractIdentifier, file)
      } catch {
        throw new Error('Erro ao salvar contrato')
      } finally {
        setIsShowLoading(false)
      }
    }
  }

  const requestUpdateContacts = async (customerIdentifier: string, data: ClientFormType): Promise<void> => {
    const clientContacts = setClientContactData(data, true)
    try {
      await Promise.all(
        clientContacts.map(async (contact) => {
          const { identifier: contactIdentifier, ...contactData } = contact
          if (contactIdentifier) {
            await updateClientContact(customerIdentifier, contactIdentifier as string, contactData)
          } else if (contactData.name !== undefined) {
            await createClientContact(customerIdentifier, contactData)
          }
        })
      )
    } catch {
      setIsShowLoading(false)
      throw new Error('Erro ao salvar contatos')
    }
  }

  const onSubmit = (data: ClientFormType): void => {
    requestUpdateClient(data)
      .then((idtf) => {
        const file = form.getValues('companysFacade')
        if (file) {
          requestUpdateFacade(idtf, file)
        }
      })
      .then(() => requestUpdateContract(data))
      .then(() => requestSaveContractFile())
      .then(() => requestUpdateContacts(identifier as string, data))
      .catch((error: Error) => showAlert('error', error.message))
      .finally(() => {
        setIsShowLoading(false)
        window.location.reload()
      })
  }

  React.useEffect(() => {
    setCompanysInfo()
    setTitle('Editar cliente')
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar Clientes', path: '/main/clients/list' },
      { label: 'Editar Cliente', path: '/main/clients/form' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return getValues('branch') !== undefined || getValues('branch') !== null ? (
    <ClientForm form={form} onSubmit={onSubmit} identifier={identifier} />
  ) : (
    <div />
  )
}

export default EditClientForm
