import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: '70%',
      maxWidth: 'calc(100% - 32px)',
      margin: '0 0',
      padding: '16px 0px 0px 0px',
      marginLeft: '15px',
      marginTop: '40px',
      maxHeight: 'calc(100vh - 112px)',
      [theme.breakpoints.down(1300)]: {
        width: '800px',
      },
    },
    inputStyle: {
      marginTop: '5px',
    },
    buttonsContainer: {
      width: '62%',
      paddingTop: '50px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonStyle: {
      width: '217px',
    },
    mainContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '85%',
      maxWidth: 'calc(100% - 32px)',
      margin: '0 0',
      padding: '16px 0px 0px 0px',
      marginLeft: '15px',
      maxHeight: 'calc(100vh - 112px)',
      [theme.breakpoints.down(1300)]: {
        width: '800px',
      },
    },
  })
)

export default useStyles
