import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CancelGathering } from '../../../../models/Gathering/Gathering'

// import AppContext, { AppContextType } from '../../../AppContext';
import InputText from '../../../../components/Form/Input/Input'
import useYupValidationResolver from '../../../../utils/yup-validator-resolver'

const wasteValidationSchema = yup.object({
  generatorDocument: yup.string().required(),
  justify: yup.string().required(),
  manifestCode: yup.string().required(),
})

export type CancelGatheringDialogProps = {
  cancelGatheringDialogData: CancelGathering | undefined
  handleSave: (cancelGathering: CancelGathering) => void
  handleClose: () => void
  isOpen: boolean
}

const CancelGatheringDialog: React.FC<CancelGatheringDialogProps> = ({
  cancelGatheringDialogData,
  handleSave,
  handleClose,
  isOpen,
}: CancelGatheringDialogProps) => {
  const resolver = useYupValidationResolver(wasteValidationSchema)
  const { control, formState, handleSubmit, setValue, reset } = useForm<CancelGathering>({ resolver })

  const handleCloseDialog = (): void => {
    reset()
    handleClose()
  }

  const onSubmit: SubmitHandler<CancelGathering> = (data: CancelGathering) => {
    handleSave(data)
    handleClose()
  }

  const formatDocument = (value: string): string => {
    return value.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5')
  }

  React.useEffect(() => {
    if (cancelGatheringDialogData) {
      setValue('generatorDocument', cancelGatheringDialogData.generatorDocument)
      setValue('manifestCode', cancelGatheringDialogData.manifestCode)
    }
  }, [cancelGatheringDialogData, setValue])

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Deseja cancelar esse MTR?</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="generatorDocument"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    disabled
                    id="generatorDocument"
                    type="text"
                    label="Documento do gerador"
                    value={formatDocument(field.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="manifestCode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText disabled id="manifestCode" type="text" label="Código do MTR" {...field} />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="justify"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    id="justify"
                    type="text"
                    label="Justificativa"
                    errorText={formState.errors?.justify?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '16px',
              margin: '16px 0px 24px 0px',
            }}
          >
            <Button onClick={handleClose} color="primary">
              Não
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Sim
            </Button>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  )
}

export default CancelGatheringDialog
