import React from 'react'
import { Grid, Typography } from '@mui/material'
import financeView from '../../../assets/images/updates/finance-tab-in-client-info-view/finance-view.gif'

const financeTabInClientInfoView = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Agora é possível consultar os dados financeiros de cada cliente, na caixa de visualização rápida, na página de
          listagem. A aba consiste de informações sobre Custos, Receitas, Comissões e uma tabela com os valores totais.
          Veja abaixo um exemplo:
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={financeView} alt="Demonstração da aba de dados financeiros." />
        <div>
          <Typography variant="caption">Demonstração da aba de dados financeiros.</Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default financeTabInClientInfoView
