import React from 'react'
import { Grid, Typography } from '@mui/material'
import newResiduesFields from '../../../assets/images/updates/new-residues-fields-update/newResiduesFields.png'

const newResiduesFieldContent = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Com objetivo de melhorar a identificação de cada resíduo, adicionamos dois novos campos para sua descrição na
          emissão de MTR. O usuário pode, a partir de agora, inserir o codigo e descrição internos do resíduo de acordo
          com seu Gerador. Veja abaixo.
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={newResiduesFields} alt="Demonstração do formulário de resíduos com novos campos." />
        <div>
          <Typography variant="caption">Demonstração do formulário de resíduos com novos campos.</Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default newResiduesFieldContent
