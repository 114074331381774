import React from 'react'
import {
  Card,
  Grid,
  FormLabel,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Switch,
} from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import { PartnerFormType } from '../../../../../models/Company/Company'
import InputSelect2Multiple, { Select2Options } from '../../../../Form/Select/Select2Multiple'
import { documentsOptionsList } from '../../../../../models/Company/FormOptions/DocumentsOptions'
import InputText from '../../../../Form/Input/Input'
import { useStyles } from '../../../CompanyFormStyle'
import { documentStyles } from '../DocumentsStyle'
import InputFile from '../../../../Form/Input/InputFile'
import { Documents, PartnerDocumentInfos, PartnersDocuments } from '../../../../../models/Partner/Partner'
import { extractDocumentsList } from '../../../../../routes/Partners/PartnersForm/utils/setDocumentsList'

interface PartnerDocumentsProps {
  form: UseFormReturn<PartnerFormType>
}

const PartnerDocuments: React.FC<PartnerDocumentsProps> = ({ form }) => {
  const { control, formState, setValue, watch } = form
  const [selectedDocuments, setSelectedDocuments] = React.useState<string[]>([])
  const partnerDocuments: PartnersDocuments = watch('documents')
  const companyFormClasses = useStyles()
  const documentsStyle = documentStyles()

  const filterValidDocuments = (documents: PartnerDocumentInfos[]): string[] => {
    return documents
      .filter(
        (document) =>
          (document.exempt && document.link) ||
          (!document.exempt && document.expirationDate) ||
          document.link ||
          document.exempt
      )
      .map((document) => document.name as string)
  }

  React.useEffect(() => {
    const documents = partnerDocuments || {}
    const documentsList: PartnerDocumentInfos[] = extractDocumentsList(documents as PartnersDocuments)
    const selectedDocsList: string[] = filterValidDocuments(documentsList)
    if (selectedDocsList.length > 0) setSelectedDocuments(selectedDocsList)
  }, [])

  const documentNulled = (documentName: string): PartnerDocumentInfos => ({
    name: documentName,
    expirationDate: '',
    link: '',
    status: 'NA',
    exempt: null,
    documentFile: undefined,
  })

  const handleRemoveDocument = (documents: string[]): void => {
    const removedDocuments = selectedDocuments.filter((document) => !documents.includes(document))
    removedDocuments.forEach((removedDocument) => {
      partnerDocuments[`${removedDocument}` as keyof Documents] = documentNulled(
        removedDocument
      ) as PartnerDocumentInfos
    })
    setValue('documents', partnerDocuments)
  }

  const handleDocumentsListChange = (
    _event: React.SyntheticEvent<any> | Event,
    value: Select2Options[] | null
  ): void => {
    const documents = value ? (value.map((document) => document.value) as string[]) : []

    handleRemoveDocument(documents)
    setSelectedDocuments(documents)
  }

  const handleDocumentInfoChange = (changeType: string, documentName: string, value: boolean | string | File): void => {
    switch (changeType) {
      case 'exempt':
        partnerDocuments[documentName as keyof Documents].exempt = value as boolean
        break
      case 'expirationDate':
        partnerDocuments[documentName as keyof Documents].expirationDate = value as string
        break
      default:
        break
    }
    setValue('documents', partnerDocuments)
  }

  const handleDocumentFileChange = (documentName: string, file: File | undefined): void => {
    partnerDocuments[documentName as keyof Documents].documentFile = file
    setValue('documents', partnerDocuments)
  }

  const renderDocument = (document: PartnerDocumentInfos, index: number): JSX.Element => {
    return (
      <TableRow key={document.name}>
        <TableCell className={documentsStyle.nameTableCellStyle}>
          <Typography variant="body1" gutterBottom className={documentsStyle.nameTypographyStyle}>
            {documentsOptionsList.find((doc) => doc.value === document.name)?.label}
          </Typography>
        </TableCell>
        <TableCell className={documentsStyle.dischargTableCellStyle}>
          <FormLabel component="label">
            <Switch
              checked={document.exempt === true}
              onChange={() => handleDocumentInfoChange('exempt', document.name, !document.exempt)}
              className={companyFormClasses.switchStyle}
            />
            {document.exempt ? 'Dispensado' : 'Não Dispensado'}
          </FormLabel>
        </TableCell>
        {!document.exempt ? (
          <TableCell sx={{ width: '30%' }}>
            <FormLabel component="label">
              Validade
              <InputText
                id="expirationDate"
                type="date"
                value={document.expirationDate}
                onChange={(event) => handleDocumentInfoChange('expirationDate', document.name, event.target.value)}
              />
            </FormLabel>
          </TableCell>
        ) : (
          <TableCell sx={{ width: '30%' }}>
            <div />
          </TableCell>
        )}
        <TableCell sx={{ width: '30%' }}>
          <FormLabel component="label" sx={{ display: 'flex', flexDirection: 'column' }}>
            Arquivo
            <InputFile
              id={`documentFile${document.name}`}
              file={document.documentFile as File}
              fileLink={document.link as string}
              documentName={document.name}
              isPartnerDoc
              onChange={handleDocumentFileChange}
            />
          </FormLabel>
        </TableCell>
      </TableRow>
    )
  }

  const getDocumentForRender = (): JSX.Element[] => {
    return selectedDocuments.map((document, index) => {
      const documentInfo = partnerDocuments && partnerDocuments[document as keyof Documents]
      return renderDocument(documentInfo as PartnerDocumentInfos, index)
    })
  }

  return (
    <Card className="form-card" variant="outlined">
      <h2 className={companyFormClasses.tabTitle}>Documentação</h2>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} key="documentationLink">
          <FormLabel component="label" className={companyFormClasses.labelStyle}>
            Link para os documentos
            <Controller
              name="documentationLink"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText
                  id="documentsLink"
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                  error={!!formState.errors.documentationLink}
                  helperText={formState.errors.documentationLink?.message}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} key="listOfDocuments">
          <FormLabel component="label" className={companyFormClasses.labelStyle}>
            Tipo de documentos necessários
            <InputSelect2Multiple
              id="listOfDocuments"
              label=""
              options={documentsOptionsList}
              value={selectedDocuments}
              onChange={handleDocumentsListChange}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} key="documents-table">
          <TableContainer>
            <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
              <TableBody className={documentsStyle.tableStyle}>
                {selectedDocuments.length > 0 ? (
                  <>{getDocumentForRender()}</>
                ) : (
                  <TableRow>
                    <TableCell>
                      <p style={{ textAlign: 'center' }}>Sem documentos selecionados</p>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Card>
  )
}

export default PartnerDocuments
