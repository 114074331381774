import { ReactComponent as Add } from './add.svg'
import { ReactComponent as ArrowDropDown } from './arrowDropDown.svg'
import { ReactComponent as ArrowDropUp } from './arrowDropUp.svg'
import { ReactComponent as Calendar } from './calendar.svg'
import { ReactComponent as Cancel } from './cancel.svg'
import { ReactComponent as Check } from './check.svg'
import { ReactComponent as ChevronLeft } from './chevronLeft.svg'
import { ReactComponent as ChevronRight } from './chevronRight.svg'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as CloseFullScreen } from './closeFullScreen.svg'
import { ReactComponent as Company } from './company.svg'
import { ReactComponent as Compost } from './compost.svg'
import { ReactComponent as Contract } from './contracts.svg'
import { ReactComponent as Delete } from './delete.svg'
import { ReactComponent as Doc } from './doc.svg'
import { ReactComponent as Download } from './download.svg'
import { ReactComponent as Eco } from './eco.svg'
import { ReactComponent as Edit } from './edit.svg'
import { ReactComponent as Employee } from './employee.svg'
import { ReactComponent as Error } from './error.svg'
import { ReactComponent as ExpandLess } from './expandLess.svg'
import { ReactComponent as ExpandMore } from './expandMore.svg'
import { ReactComponent as EyeClosed } from './eyeClosed.svg'
import { ReactComponent as EyeOpen } from './eyeOpen.svg'
import { ReactComponent as Filter } from './filter.svg'
import { ReactComponent as Help } from './help.svg'
import { ReactComponent as Home } from './home.svg'
import { ReactComponent as Info } from './info.svg'
import { ReactComponent as FullScreen } from './fullscreen.svg'
import { ReactComponent as Indeterminate } from './indeterminate.svg'
import { ReactComponent as Logout } from './logout.svg'
import { ReactComponent as MoreHorizontal } from './moreHor.svg'
import { ReactComponent as MoreVertical } from './moreVert.svg'
import { ReactComponent as MtrList } from './mtrList.svg'
import { ReactComponent as Plant } from './plant.svg'
import { ReactComponent as Report } from './report.svg'
import { ReactComponent as Retry } from './retry.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Settings } from './setting.svg'
import { ReactComponent as Truck } from './truck.svg'
import { ReactComponent as User } from './user.svg'
import { ReactComponent as Recycle } from './recycle.svg'
import { ReactComponent as Sort } from './sort.svg'
import { ReactComponent as Warning } from './warning.svg'
import { ReactComponent as Wait } from './wait.svg'
import { ReactComponent as FileCanceled } from './fileCanceled.svg'
import { ReactComponent as DownloadOff } from './downloadOff.svg'
import { ReactComponent as Upload } from './upload.svg'

const Icons = {
  Add,
  ArrowDropDown,
  ArrowDropUp,
  Calendar,
  Cancel,
  Check,
  ChevronLeft,
  ChevronRight,
  Close,
  CloseFullScreen,
  Company,
  Compost,
  Contract,
  Doc,
  Download,
  Eco,
  Employee,
  Error,
  ExpandLess,
  ExpandMore,
  EyeClosed,
  EyeOpen,
  Filter,
  Help,
  Home,
  Info,
  FullScreen,
  Indeterminate,
  Logout,
  MoreHorizontal,
  MoreVertical,
  MtrList,
  Plant,
  Report,
  Search,
  Settings,
  Truck,
  User,
  Recycle,
  Sort,
  Warning,
  Wait,
  Edit,
  Delete,
  Retry,
  FileCanceled,
  DownloadOff,
  Upload,
}

export default Icons
