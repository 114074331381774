import { ClientFormType } from '../../../../models/Company/Company'

const primaryTabMandatoryFields = [
  'cnpj',
  'companyName',
  'corporateName',
  'cep',
  'city',
  'state',
  'district',
  'street',
  'number',
  'branch',
]

const isPrimarytabIncomplete = (data: ClientFormType): boolean => {
  return primaryTabMandatoryFields.some((field) => data[field as keyof ClientFormType])
}

const getIncompleteTab = (data: ClientFormType): string => {
  if (isPrimarytabIncomplete(data)) return 'Dados Primários'
  return 'Dados do contrato'
}

export default getIncompleteTab
