import axios from 'axios'

import { CompanyInfo, CompanyMinimalInfo } from '../../models/Company/Company'
import getEndpointUrl from '../../utils/url-resolver'
import { ClientRequestI } from '../../models/Client/Client'

const baseUrlCustomers = `${getEndpointUrl('company')}/v1/customers`
const baseUrlPartners = `${getEndpointUrl('company')}/v1/partners`

const getAllCustomers = (): Promise<CompanyMinimalInfo[]> =>
  axios.get<CompanyMinimalInfo[]>(`${baseUrlCustomers}/`).then((response) => response.data)

const getAllPartners = (): Promise<CompanyMinimalInfo[]> =>
  axios.get<CompanyMinimalInfo[]>(`${baseUrlPartners}/`).then((response) => response.data)

const getCustomerByIdentifier = (identifier: string): Promise<ClientRequestI> =>
  axios.get<ClientRequestI>(`${baseUrlCustomers}/${identifier}`).then((response) => response.data)

const getPartnerByIdentifierMinimal = (identifier: string): Promise<CompanyMinimalInfo> =>
  axios.get<CompanyMinimalInfo>(`${baseUrlPartners}/${identifier}`).then((response) => response.data)

const createPartner = (partner: CompanyInfo): Promise<CompanyMinimalInfo[]> =>
  axios.post(`${baseUrlPartners}/`, partner).then((response) => response.data)

export { getAllCustomers, getAllPartners, getCustomerByIdentifier, getPartnerByIdentifierMinimal, createPartner }
