import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import PartnersForm from '../PartnersForm'

import { PartnerFormType } from '../../../../models/Company/Company'
import AppContext, { AppContextType } from '../../../../AppContext'
import { defaultBreadCrumbItems } from '../../../../components/BreadCrumb/BreadCrumb'

import useYupValidationResolver from '../../../../utils/yup-validator-resolver'
import validationSchema from '../utils/companyValidationSchema'
import setRequestData, { setContactData } from '../utils/setRequestData'
import { PartnerContact, PartnerRequest, PartnersDocuments } from '../../../../models/Partner/Partner'
import { savePartner } from '../../../../services/Partner/PartnerService'
import { savePartnerFacade } from '../../../../services/Partner/PartnerDocumentsService'
import { createPartnerContact } from '../../../../services/Partner/PartnerContactsService'

interface PartnersRequestsInfo {
  partnerIdentifier: string
  facadeFile: File
  documents: PartnersDocuments
}

const NewPartnerForm: React.FC = () => {
  const resolver = useYupValidationResolver(validationSchema)
  const form = useForm<PartnerFormType>({ resolver })
  const { setIsShowLoading, showAlert, setItemsBreadCrumb, setTitle } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const navigate = useNavigate()

  const requestCreatePartner = async (companyRequestInfo: PartnerRequest): Promise<PartnersRequestsInfo> => {
    setIsShowLoading(true)
    try {
      const response = await savePartner(companyRequestInfo)
      showAlert('success', 'Empresa criada com sucesso')

      return {
        partnerIdentifier: response.primaryData.identifier,
        facadeFile: form.getValues('companysFacade') as File,
        documents: form.getValues('documents') as PartnersDocuments,
      }
    } catch {
      setIsShowLoading(false)
      throw new Error('Erro ao criar empresa')
    }
  }

  const requestSavePartnerFacade = async (requestInfos: PartnersRequestsInfo): Promise<string> => {
    setIsShowLoading(true)
    if (requestInfos.facadeFile) {
      savePartnerFacade(requestInfos.partnerIdentifier, requestInfos.facadeFile)
        .catch(() => showAlert('error', 'Erro ao salvar fachada da empresa'))
        .finally(() => setIsShowLoading(false))
    }
    return requestInfos.partnerIdentifier
  }

  const requestSavePartnerContacts = async (partnerIdentifier: string, contacts: PartnerContact[]): Promise<string> => {
    setIsShowLoading(true)
    await contacts.reduce(async (previousPromise, contact) => {
      await previousPromise
      try {
        await createPartnerContact(partnerIdentifier as string, contact)
      } catch {
        throw new Error(`Erro ao editar contato ${contact.name} do parceiro. Contate o suporte.`)
      }
    }, Promise.resolve())
    setIsShowLoading(false)
    return Promise.resolve(partnerIdentifier)
  }

  const onSubmit = (data: PartnerFormType): void => {
    const companyRequestInfo = setRequestData(data)
    const partnerContacts = setContactData(data)

    requestCreatePartner(companyRequestInfo)
      .then((documentsInfo) => requestSavePartnerFacade(documentsInfo))
      .then((identifier: string) => requestSavePartnerContacts(identifier, partnerContacts))
      .then((identifier: string) => navigate(`/main/partners/form/${identifier}`))
      .catch((error: Error) => showAlert('error', error.message))
  }

  React.useEffect(() => {
    setTitle('Novo parceiro')
    form.setValue('branch', 0)
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar parceiros', path: '/main/partners/list' },
      { label: 'Novo parceiro', path: '/main/partners/form' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <PartnersForm form={form} onSubmit={onSubmit} />
}

export default NewPartnerForm
