import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      height: '125px',
      width: '100%',
      background: '#43b849',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingRight: '8px',
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '16px',
      boxSizing: 'border-box',
      position: 'relative',
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        paddingLeft: '16px',
      },
      '& > button': {
        margin: '0px 8px',
      },
      '& span': {
        color: '#FFF',
      },
    },
    headerContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    headerCustomContent: {
      display: 'flex',
      alignItems: 'center',
    },
    headerTitle: {
      margin: '0',
      fontSize: '1.5rem',
      color: '#FFF',
      fontFamily: 'nunitoSemiBold',
    },
    headerLeft: {
      height: '48px',
      width: '48px',
      backgroundColor: '#FFF !important',
      borderRadius: '50%',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0px 8px !important',
      cursor: 'pointer',
    },
    headerRight: {
      display: 'flex',
      alignItems: 'center',
      '& > p': {
        color: '#FFF',
        padding: '4px 8px',
        border: '1px solid #FFF',
        display: 'flex',
        alignItens: 'center',
        cursor: 'pointer',
      },
    },
    listItem: {
      padding: '0px 16px',
    },
    headerSystemInfoDiv: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    systemVersion: {
      paddingRight: '8px',
    },
  })
)

export const smoothWhite = 'rgb(255, 255, 255, 0.5)'
