import { AlertColor } from '@mui/material'
import React from 'react'
import { BreadCrumbItems } from './components/BreadCrumb/BreadCrumb'
import { BranchesInfo, CompanyInfo, CompanyMinimalInfo } from './models/Company/Company'

export interface AppContextType {
  userCompanies: CompanyInfo[]
  userBranches: BranchesInfo[]
  defaultBranch: BranchesInfo | undefined
  defaultCompany: CompanyMinimalInfo | undefined
  setDefaultCompany: (value: CompanyMinimalInfo | undefined) => void
  title: string
  setTitle: (value: string) => void
  customHeaderContent: JSX.Element | null
  setCustomHeaderContent: (value: JSX.Element) => void
  showAlert: (value: AlertColor, text: string) => void
  setIsShowLoading: (value: boolean) => void
  isSidebarOpened: boolean
  setIsSidebarOpened: (value: boolean) => void
  itemsBreadCrumb: BreadCrumbItems[]
  setItemsBreadCrumb: (value: BreadCrumbItems[]) => void
}

const AppContext = React.createContext<AppContextType | null>(null)

export default AppContext
