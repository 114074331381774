import React from 'react'
import { Grid, Typography } from '@mui/material'
import cnpjAvailabilityCheck from '../../../assets/images/updates/cnpj-availability-check-update/cnpj-availability-check.gif'

const cnpjCheckUpdateContent = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Uma checagem instantânea é executada sempre que você insere um novo valor no campo de CNPJ no cadastro do
          cliente. Esta checagem permite que o usuário evite contratempos ao tentar cadastrar ou editar um cliente com
          um CNPJ já existente no sistema. Veja abaixo:
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={cnpjAvailabilityCheck} alt="Demonstração da checagem instantânea de CNPJ já existente." />
        <div>
          <Typography variant="caption">Demonstração da checagem instantânea de CNPJ já existente.</Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default cnpjCheckUpdateContent
