import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    deleteBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: ' 510px',
      height: '314px',
      top: '340px',
      left: '428px',
    },
    titleSection: {
      display: 'flex',
    },
    closeDialogButton: {
      width: '65px',
      height: '20px',
      borderRadius: '100px',
      gap: '10px',
      backgroundColor: '#FFFFFF',
      border: 'none',
      alignSelf: 'flex-start',
      position: 'absolute',
      right: '15px',
      cursor: 'pointer',
    },
    deleteTextBox: {
      width: '320px',
      height: '39.5px',
      top: '100.72px',
      left: '107px',
      textAlign: 'center',
      fontSize: '16px',
      color: '#313330',
    },
    deleteButtonsBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
    },
    deleteButton: {
      width: '217px',
      height: '40px',
      top: '182px',
      left: '147px',
      borderRadius: '100px',
      gap: '8px',
      cursor: 'pointer',
    },
    deleteButtonYes: {
      backgroundColor: '#33B652',
      marginBottom: '10px',
      color: 'white',
      border: 'none',
    },
    deleteButtonNo: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #33B652',
    },
  })
)

export default useStyles
