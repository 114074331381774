import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    active: {
      backgroundColor: 'rgb(244,244,245)',
    },
    searchBox: {
      position: 'relative',
      margin: '0px 16px 16px 16px',
      '& svg': {
        position: 'absolute',
        top: '16px',
        left: '8px',
      },
      '& input': {
        paddingLeft: '40px',
      },
    },
    menuMobileItem: {
      height: '62px',
      paddingLeft: '16px',
      '&:not(:first-child) div': {
        borderTop: '1px solid rgb(242, 242, 242)',
      },
      '& img': {
        height: '40px',
        width: '40px',
        // borderRadius: '50%',
        objectFit: 'contain',
      },
    },
    menuMobileLink: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      textDecoration: 'none',
      cursor: 'pointer',
      paddingLeft: '8px',
      backgroundColor: 'transparent',
      border: '0px',
      width: '100%',
      textAlign: 'left',
      fontFamily: 'nunitoRegular !important',
      '&:hover': {
        backgroundColor: 'rgb(244,244,245)',
      },
    },
    menuMobileItemInfo: {
      flex: 1,
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      height: '100%',
      marginLeft: '8px',
      '& h6': {
        fontSize: '16px',
        fontWeight: 700,
        color: '#222',
        margin: '0',
      },
      '& p': {
        fontSize: '14px',
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.8)',
        marginTop: '2px',
        marginBottom: '0',
      },
    },
  })
)

export default useStyles
