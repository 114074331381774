import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    settingsContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'space-between',
    },
    containerAll: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
    },
    containerButton: {
      height: '20%',
      display: 'flex',
      alignItems: 'center',
    },
    newAcessLevelButton: {
      borderRadius: '50px',
      width: '250px',
      fontSize: '12px',
      marginRight: '10%',
      height: '40px',
      marginBottom: '20px',
    },
    dataTableAcessLevel: {
      boxShadow: 'none',
      width: '40%',
      height: '95%',
    },
    dataTableFeatures: {
      boxShadow: 'none',
      width: '60%',
    },
  })
)
