import React from 'react'
import { Grid, FormLabel, Typography, Chip } from '@mui/material'
import { Download } from '@mui/icons-material'
import { City, PartnerResponse } from '../../../models/Partner/Partner'
import useStyles from '../Styles/ViewInfoModalStyle'

interface PartnerTechnicalDataProps {
  partner: PartnerResponse
}

const PartnerTechnicalData: React.FC<PartnerTechnicalDataProps> = ({ partner }) => {
  const classes = useStyles()
  const gridFlex = { display: 'flex', flexDirection: 'column' }
  const {
    bankData,
    technicalData: { operationStatus, homologStatus },
  } = partner
  const cityInfo =
    partner.technicalData.cities.length > 0
      ? partner.technicalData.cities.map((city: City) => `${city.name} - ${city.state}`).join(', ')
      : 'Não informado'

  const citysGridSize = partner.technicalData.cities.length > 2 ? 12 : 6

  const residues =
    partner.technicalData.residues.length > 0
      ? partner.technicalData.residues.map((residue) => residue).join(', ')
      : 'Não informado'

  const getStatusColor = (status: string): 'success' | 'error' | 'warning' => {
    switch (status) {
      case 'Ativo':
      case 'Homologado':
        return 'success'
      case 'Inativo':
      case 'Não Homologado':
        return 'error'
      default:
        return 'warning'
    }
  }

  return (
    <Grid container spacing={2} className={classes.mainCard}>
      <Grid item xs={12} key="techinal-data-title">
        <h2 className={classes.tabTitle}>Dados Técnicos</h2>
      </Grid>
      <Grid item xs={6} key="operation-status" sx={gridFlex}>
        <FormLabel className={classes.labelStyle} component="label">
          Status de Operação
        </FormLabel>
        <Chip
          label={operationStatus || 'Não informado'}
          color={getStatusColor(operationStatus)}
          data-cy="view-modal-operation-status"
          sx={{ width: 'fit-content' }}
        />
      </Grid>
      <Grid item xs={6} key="homolog-status" sx={gridFlex}>
        <FormLabel className={classes.labelStyle} component="label">
          Status da Homologação
        </FormLabel>
        <Chip
          label={homologStatus || 'Não informado'}
          color={getStatusColor(homologStatus)}
          data-cy="view-modal-homolog-status"
          sx={{ width: 'fit-content' }}
        />
      </Grid>
      <Grid item xs={6} key="company-facade" sx={gridFlex}>
        <FormLabel className={classes.labelStyle} component="label">
          Fachada da Empresa
        </FormLabel>
        {partner.primaryData.companyFacade ? (
          <Chip
            label="Download"
            component="a"
            href={partner.primaryData.companyFacade}
            clickable
            deleteIcon={<Download />}
            data-cy="view-modal-company-facade"
            sx={{ width: 'fit-content' }}
          />
        ) : (
          <Typography variant="body1">Não informado</Typography>
        )}
      </Grid>
      <Grid item xs={6} key="truck-quantity">
        <FormLabel className={classes.labelStyle} component="label">
          Quantidade de caminhões
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-truck-quantity">
          {partner.technicalData.truckQuantity || 'Não informado'}
        </Typography>
      </Grid>
      <Grid item xs={6} key="employee-quantity">
        <FormLabel className={classes.labelStyle} component="label">
          Quantidade de Funcionários
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-employee-quantity">
          {partner.technicalData.employeeQuantity || 'Não informado'}
        </Typography>
      </Grid>
      <Grid item xs={citysGridSize} key="partner-cities">
        <FormLabel className={classes.labelStyle} component="label">
          Cidades atendidas
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-partner-cities" sx={{ display: 'flex' }}>
          {cityInfo}
        </Typography>
      </Grid>
      <Grid item xs={12} key="residues">
        <FormLabel className={classes.labelStyle} component="label">
          Tipos de Resíduos que trabalha:
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-residues" sx={{ display: 'flex' }}>
          {residues}
        </Typography>
      </Grid>
      <Grid item xs={12} key="bank-info-title">
        <h2 className={classes.tabTitle}>Dados Bancários</h2>
      </Grid>
      <Grid item xs={6} key="bank-code">
        <FormLabel className={classes.labelStyle} component="label">
          Código do Banco
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-bank-code">
          {bankData ? bankData.bankCode : 'Não informado'}
        </Typography>
      </Grid>
      <Grid item xs={6} key="bank-branch">
        <FormLabel className={classes.labelStyle} component="label">
          Agência
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-bank-branch">
          {bankData ? bankData.bankBranch : 'Não informado'}
        </Typography>
      </Grid>
      <Grid item xs={6} key="account-number">
        <FormLabel className={classes.labelStyle} component="label">
          Número da Conta
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-account-number">
          {bankData ? bankData.accountNumber : 'Não informado'}
        </Typography>
      </Grid>
      <Grid item xs={6} key="max-payment-term">
        <FormLabel className={classes.labelStyle} component="label">
          Prazo Máximo de Pagamento
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-max-payment-term">
          {bankData ? bankData.maxPaymentTerm : 'Não informado'}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default PartnerTechnicalData
