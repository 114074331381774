import React from 'react'
import { InputAdornment, IconButton } from '@mui/material'
import visibility from '../../assets/icons/eye.png'
import visibilityOff from '../../assets/icons/eye-off.png'

interface ShowPasswordProps {
  showPassword: boolean
  handleShowPassword: () => void
}

const ShowPassword: React.FC<ShowPasswordProps> = ({ showPassword, handleShowPassword }) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={handleShowPassword} aria-label="toggle password visibility">
        <img src={showPassword ? visibilityOff : visibility} alt="show password" />
      </IconButton>
    </InputAdornment>
  )
}

export default ShowPassword
