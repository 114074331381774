import { FormContacts, PartnerFormType } from '../../../../models/Company/Company'
import { PartnerContact, PartnerRequest } from '../../../../models/Partner/Partner'

const setRequestData = (data: PartnerFormType): PartnerRequest => {
  const partnerCitiesCodes = data.citiesActive?.map((city) => Number(city.split('-')[2].trim()))

  const partnerRequestBody: PartnerRequest = {
    cnpj: data.cnpj,
    name: data.name,
    companyName: data.corporateName,
    documentationLink: data.documentationLink ? data.documentationLink : '',
    address: {
      city: data.city,
      complement: data.complement,
      district: data.district,
      number: String(data.number),
      state: data.state,
      street: data.street,
      zipcode: data.cep,
    },
    mtrSystemAccess: {
      mtrSystemName: '',
      mtrSystemPass: '',
      mtrSystemUnitCode: data.branch,
      mtrSystemUser: '',
    },
    partnerTechnicalData: {
      cities: partnerCitiesCodes,
      employeeQuantity: Number(data.employeesQuantity),
      homologStatus: data.homologationStatus,
      operationStatus: data.operationStatus,
      residues: data.residues ? data.residues : [],
      truckQuantity: Number(data.trucksQuantity),
    },
    taxRegime: data.taxRegime,
    partnerType: data.serviceType,
    accountNumber: String(data.accountNumber),
    bankBranch: String(data.bankBranch),
    bankCode: String(data.bankCode),
    icmsTaxpayer: data.icms ? data.icms : false,
    maxPaymentTerm: data.maxPaymentTerm,
  }

  return partnerRequestBody
}

export const setContactData = (data: PartnerFormType): PartnerContact[] => {
  const contactTypes = [
    { label: 'Financeiro', value: 'finance' },
    { label: 'Operacional', value: 'operational' },
    { label: 'Comercial', value: 'commercial' },
    { label: 'Diretoria', value: 'management' },
  ]

  const mappedContacts = contactTypes.map((type) => {
    const contactInfo = data[`${type.value.toLowerCase()}Contact` as keyof FormContacts]
    return {
      name: contactInfo && contactInfo.name ? contactInfo.name : '',
      surname: contactInfo && contactInfo.surname ? contactInfo.surname : '',
      telephone: contactInfo && contactInfo.telephone ? contactInfo.telephone : '',
      email: contactInfo && contactInfo.email ? contactInfo.email : '',
      type: type.label,
      identifier: contactInfo && contactInfo.identifier,
    }
  })

  return mappedContacts
}

export default setRequestData
