import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    generalContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '30px',
      width: '30vw',
      heigth: '100%',
      alignItems: 'center',
      borderBottom: 'none',
      boxShadow: 'none',
    },
    containerForm: { display: 'flex', flexDirection: 'column', width: '100%' },
    updateButton: {
      borderRadius: '50px',
      width: '150px',
      fontSize: '12px',
      marginRight: '10%',
      height: '50px',
    },
  })
)
