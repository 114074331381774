import { Autocomplete, AutocompleteChangeReason, AutocompleteRenderInputParams, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { FilterOptionsState } from '@mui/base/AutocompleteUnstyled/useAutocomplete'
import Icons from '../../../assets/icons/svgIcons'
import { CompanyMinimalInfo } from '../../../models/Company/Company'

export interface SelectCompanyOptions {
  name: string
  cnpj: string
  identifier: string
  disabled?: boolean
}

export type InputSelectCompanyProps = {
  id: string
  label: string
  onChange: (event: React.SyntheticEvent, value: SelectCompanyOptions | null, reason: AutocompleteChangeReason) => void
  options: SelectCompanyOptions[]
  errorText?: string
  clearable?: boolean
  disabled?: boolean
  defaultCompany?: CompanyMinimalInfo
  company?: CompanyMinimalInfo
  filterOptions?: (opt: SelectCompanyOptions[], inputValue: SelectCompanyOptions) => SelectCompanyOptions[]
  sx?: React.CSSProperties
  popupIcon?: React.ReactNode
}

const InputSelectCompany: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, InputSelectCompanyProps> = (
  { id, label, onChange, options, errorText, clearable = false, disabled = false, ...rest },
  ref
) => {
  const hasError = !!errorText
  const disabledValues = options.filter((option) => option.disabled).map((option) => option.identifier)
  const checkValue = (option: SelectCompanyOptions, value: SelectCompanyOptions | null): boolean => {
    return option.identifier === value?.identifier
  }
  const disableOption = (option: SelectCompanyOptions): boolean => {
    return disabledValues.includes(option.identifier)
  }
  const initialValue = options.find((o) => o.identifier === rest.company?.identifier)
  const defaultValue = options.find((o) => o.identifier === rest.defaultCompany?.identifier)
  let autoCompleteValue = null
  let autoCompleteDefaultValue = null

  if (initialValue !== undefined && defaultValue === undefined) {
    autoCompleteValue = initialValue
  } else if (defaultValue !== undefined) {
    autoCompleteValue = undefined
  }

  if (defaultValue !== undefined) {
    autoCompleteDefaultValue = defaultValue
  }

  const searchOptions = (
    opt: SelectCompanyOptions[],
    { inputValue }: { inputValue: string }
  ): SelectCompanyOptions[] => {
    return opt.filter((option) => {
      const optLabel = option.name as string
      return optLabel.toLowerCase().includes(inputValue.toLowerCase())
    })
  }

  return (
    <FormControl fullWidth variant="outlined" error={hasError}>
      <Autocomplete
        fullWidth
        id={id}
        onChange={onChange}
        sx={{
          ...rest.sx,
          '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
        }}
        popupIcon={<Icons.Search />}
        renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
          <TextField inputRef={ref} label={label} error={hasError} {...params} />
        )}
        data-cy={`select-company-input-${id}`}
        renderOption={(props, option) => (
          <li {...props} key={option.identifier} data-cy={`company-option-${option.name}`}>
            {option.name}
          </li>
        )}
        options={options}
        isOptionEqualToValue={checkValue}
        getOptionDisabled={disableOption}
        filterOptions={searchOptions}
        noOptionsText="Nenhum resultado encontrado"
        value={autoCompleteValue}
        disableClearable={!clearable}
        multiple={false}
        disabled={disabled}
        defaultValue={autoCompleteDefaultValue}
        getOptionLabel={(option) => option.name || ''}
      />
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  )
}

export default React.forwardRef(InputSelectCompany)
