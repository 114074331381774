import axios from 'axios'

import getEndpointUrl from '../../utils/url-resolver'
import { acessLevel, acessLevelToSubmit } from '../../models/Setting/Settings'

const baseUrl = `${getEndpointUrl('company')}/v1/cargo`

const getAllAccessLevels = (): Promise<acessLevel[]> => {
  return axios.get<acessLevel[]>(`${baseUrl}/`).then((response) => response.data)
}

const getAcessLevelById = (id: number): Promise<acessLevel> => {
  return axios.get<acessLevel>(`${baseUrl}/${id}/`).then((response) => response.data)
}

const getAcessLevelByUserId = (id: number): Promise<acessLevel> => {
  return axios.get<acessLevel>(`${baseUrl}/user/${id}/`).then((response) => response.data)
}

const createAcessLevel = (acessLevelBody: acessLevelToSubmit): Promise<acessLevel> => {
  return axios.post(`${baseUrl}/`, acessLevelBody).then((response) => response.data)
}

const updateAcessLevel = (acessLevelBody: acessLevelToSubmit): Promise<void> => {
  return axios.put(`${baseUrl}/`, acessLevelBody).then((response) => response.data)
}

const deleteAcessLevel = (id: number): Promise<void> => axios.delete(`${baseUrl}/${id}`)

export {
  getAllAccessLevels,
  getAcessLevelById,
  getAcessLevelByUserId,
  createAcessLevel,
  updateAcessLevel,
  deleteAcessLevel,
}
