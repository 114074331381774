import React from 'react'
import { Button, Grid } from '@mui/material'
import brokenRobot from '../../assets/images/BrokenRobot.png'
import useStyles from './MaintenanceStyle'

const Maintenance: React.FC = () => {
  const styles = useStyles()

  return (
    <div>
      <Grid container className={styles.mainContainer}>
        <Grid item xs={12}>
          <h1 className={styles.title}>Desculpe, esta página está em manutenção.</h1>
          <p className={styles.paragraph}>Voltaremos brevemente com seu funcionamento.</p>
          <Grid item className={styles.buttonContainer}>
            <Button variant="contained" color="primary" href="/main/home" className={styles.buttonLeft}>
              Voltar ao Início
            </Button>
          </Grid>
        </Grid>
        <Grid item className={styles.brokenRobotGrid}>
          <img className={styles.brokenRobotImage} src={brokenRobot} alt="home-pic" />
        </Grid>
      </Grid>
    </div>
  )
}

export default Maintenance
