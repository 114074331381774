import React from 'react'
import { Grid, Typography } from '@mui/material'
import feamStatusSignInMtrList from '../../../assets/images/updates/feam-status-info-update/status-in-mtr-list.png'
import feamStatusSignInMtrForm from '../../../assets/images/updates/feam-status-info-update/status-in-mtr-form.png'

const feamWebServerStatusUpdateContent = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Um indicador do status de funcionamento do servidor web da FEAM foi adicionado às páginas de listagem e ao
          formulário de novo MTR. Sua adição tem o propósito de trazer mais transparência e visibilidade sobre a
          eficiência da comunicação do nosso sistema com o órgão ambiental. Veja:
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={feamStatusSignInMtrList} alt="Demonstração do indicador de status da FEAM na listagem de MTR's." />
        <div>
          <Typography variant="caption">
            Demonstração do indicador de status da FEAM na listagem de MTR&apos;s.
          </Typography>
        </div>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img
          src={feamStatusSignInMtrForm}
          alt="Demonstração do indicador de status da FEAM na página de formulário de novo MTR."
        />
        <div>
          <Typography variant="caption">
            Demonstração do indicador de status da FEAM FEAM na página de formulário de novo MTR.
          </Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default feamWebServerStatusUpdateContent
