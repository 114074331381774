import axios from 'axios'

import Employee, { EmployeeMinimalResponse } from '../../models/Employee/employee'
import getEndpointUrl from '../../utils/url-resolver'
import type { CompanyMinimalInfo } from '../../models/Company/Company'

const baseUrl = `${getEndpointUrl('company')}/v1/employees`
const baseUrlCustomerEmployees = `${getEndpointUrl('company')}/v1/customer-employees`

const getAllEmployees = (): Promise<Employee[]> =>
  axios.get<Employee[]>(`${baseUrl}/`).then((response) => response.data)

const getEmployeesWithoutUser = (): Promise<Employee[]> =>
  axios.get<Employee[]>(`${baseUrl}/no-user`).then((response) => response.data)

const getEmployeesByIdBranch = (idBranch: number): Promise<Employee[]> =>
  axios.get<Employee[]>(`${baseUrl}/branch/${idBranch}`).then((response) => response.data)

const getEmployeesByIdCompany = (idCompany: number): Promise<Employee[]> =>
  axios.get<Employee[]>(`${baseUrl}/company/${idCompany}`).then((response) => response.data)

const getEmployeeById = (id: number): Promise<Employee> =>
  axios.get<Employee>(`${baseUrl}/${id}`).then((response) => response.data)

const createEmployee = (employee: Employee): Promise<Employee> =>
  axios.post(`${baseUrl}/`, employee).then((response) => response.data)

const updateEmployee = (employee: Employee): Promise<void> =>
  axios.put(`${baseUrl}/`, employee).then((response) => response.data)

const deleteEmployee = (id: number): Promise<void> => axios.delete(`${baseUrl}/${id}`)

const getEmployeesByCustomerIdentifier = (customerIdentifier: string): Promise<EmployeeMinimalResponse[]> =>
  axios.get(`${baseUrlCustomerEmployees}/customer/${customerIdentifier}/employees`).then((response) => response.data)

const getCustomersByEmployeeId = (employeeId: number): Promise<CompanyMinimalInfo[]> =>
  axios.get(`${baseUrlCustomerEmployees}/employee/${employeeId}/customers`).then((response) => response.data)

const updateEmployeeCustomerConnections = (customers: string[], employeeId: number): Promise<void> =>
  axios.put(`${baseUrlCustomerEmployees}/employee/${employeeId}/customers`, customers).then((response) => response.data)

export {
  getAllEmployees,
  getEmployeesWithoutUser,
  getEmployeesByIdBranch,
  getEmployeesByIdCompany,
  getEmployeeById,
  createEmployee,
  updateEmployee,
  deleteEmployee,
  getEmployeesByCustomerIdentifier,
  getCustomersByEmployeeId,
  updateEmployeeCustomerConnections,
}
