import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import AppContext, { AppContextType } from '../../../AppContext'
import DataTable from '../../../components/DataTable/DataTable'
import Report from '../../../models/Report/report'
import { deleteReport, getReportsByCustomerIdentifier } from '../../../services/Report/ReportService'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import { featureAccessCheck, iconAccessCheck } from '../../../utils/AccessPermissionsCheck'
import Icons from '../../../assets/icons/svgIcons'
import DeleteConfirmation, { DeleteItemText } from '../../../components/DeleteConfirmation/DeleteConfirmation'
import { ApplicationColors } from '../../../utils/applicationColors'
import { CompanyMinimalInfo } from '../../../models/Company/Company'
import ViewDeleteEditPopover from '../../../components/PopOver/ViewDeleteEditPopover'
import { getAllCustomers } from '../../../services/Companies/CompaniesService'
import getInitialSelectedCompany, { getCompanyByIdentifier } from '../../../utils/getInitialSelectedCompany'
import { handleReactGAEvent } from '../../../utils/handleReactAnalytics'

const ReportList: React.FC = () => {
  const [reports, setReports] = React.useState<Report[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const {
    setTitle,
    setCustomHeaderContent,
    showAlert,
    setIsShowLoading,
    setItemsBreadCrumb,
    defaultCompany,
    setDefaultCompany,
  } = React.useContext(AppContext as React.Context<AppContextType>)
  const [currentDeleteItem, setCurrentDeleteItem] = React.useState<Report | null>(null)
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = React.useState<boolean>(false)
  const [deleteItemTexts, setDeleteItemTexts] = React.useState<DeleteItemText>({ title: '', item: '', name: '' })
  const [selectedCustomer, setSelectedCustomer] = React.useState<string>()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [itemToChange, setItemToChange] = React.useState<Report>()
  const [anchor, setAnchor] = React.useState<any>(null)
  const [customers, setCustomers] = React.useState<CompanyMinimalInfo[]>([])
  const [customerOptions, setCustomerOptions] = React.useState<{ value: string; label: string }[]>([])
  const navigate = useNavigate()
  const reportsMap = [
    { key: 'name', value: 'Nome' },
    { key: 'type', value: 'Tipo' },
  ]

  const handleClickNew = React.useCallback((): void => {
    navigate('/main/report/form')
    handleReactGAEvent({
      category: 'Relatório',
      action: 'Novo',
      label: `Clicou no botão novo relatorio`,
    })
  }, [navigate])

  const handleEdit = (item: Report): void => {
    navigate(`/main/report/form/${item.identifier}`)
  }

  const handleView = (item: Report): void => {
    handleReactGAEvent({
      category: 'Relatório',
      action: 'Visualizar',
      label: `Visualizou um relatorio`,
    })
    navigate(`/main/report/${item.identifier}`)
  }

  const openDeleteConfirmation = (item: Report): void => {
    setIsShowDeleteConfirmation(true)
    setDeleteItemTexts({ title: 'Excluir relatório', item: 'o relatório', name: item.name })
    setCurrentDeleteItem(item)
  }

  const handleDelete = (item: Report): void => {
    setIsShowLoading(true)
    deleteReport(item.identifier as string)
      .then(() => {
        setReports(reports.filter((r) => r.identifier !== item.identifier))
        showAlert('success', 'Relatório excluído com sucesso.')
        setIsShowLoading(false)
        handleReactGAEvent({
          category: 'Relatório',
          action: 'Excluir com sucesso',
          label: `Excluiu um relatorio com sucesso`,
        })
      })
      .catch(() => {
        showAlert('error', 'Erro ao excluir relatório.')
        handleReactGAEvent({
          category: 'Relatório',
          action: 'Erro ao excluir',
          label: `Erro ao excluir um relatorio`,
        })
        setIsShowLoading(false)
      })
  }

  const handleClose = (): void => {
    setIsShowDeleteConfirmation(false)
  }

  const handleConfirmDelete = (): void => {
    if (currentDeleteItem) {
      handleDelete(currentDeleteItem)
    }
    setCurrentDeleteItem(null)
    handleClose()
    setIsOpen(false)
  }

  const customHeaderContent = React.useMemo(
    () =>
      featureAccessCheck('report-write') ? (
        <IconButton aria-label="Novo relatório" onClick={handleClickNew} size="large">
          <Icons.Add fill="white" />
        </IconButton>
      ) : (
        <div />
      ),
    [handleClickNew]
  )

  const handleInit = React.useCallback((): void => {
    setLoading(true)
    setTitle('Listar Relatórios')
    setCustomHeaderContent(customHeaderContent)
    const companies: { value: string; label: string }[] = []

    getAllCustomers().then((customerList) => {
      setCustomers(customerList)
      customerList.forEach((c) => {
        companies.push({ value: c.identifier, label: c.name })
      })
      setCustomerOptions(companies)

      const initialSelectedCompany = getInitialSelectedCompany(customerList, defaultCompany)
      if (initialSelectedCompany) {
        setSelectedCustomer(initialSelectedCompany.identifier)
        getReportsByCustomerIdentifier(initialSelectedCompany.identifier)
          .then((reportsList) => {
            if (reportsList.length === 0) {
              showAlert('info', 'Não há relatórios registrados para esse cliente.')
            }
            setReports(reportsList)
          })
          .catch(() => {
            showAlert('error', 'Erro ao carregar Relatórios.')
          })
          .finally(() => {
            setLoading(false)
          })
      }
    })
  }, [customHeaderContent, setCustomHeaderContent, setTitle, showAlert])

  const handleClosePopOver = (): void => {
    setIsOpen(false)
  }

  const handleMoreOptions = (item: Report, index?: number, anchorEl?: EventTarget & HTMLButtonElement): void => {
    setIsOpen(true)
    setAnchor(anchorEl)
    setItemToChange(item)
  }

  const actions = [
    { label: 'visualizar', icon: <Icons.MoreVertical fill={ApplicationColors.primaryColor} />, fn: handleMoreOptions },
  ]

  React.useEffect(() => {
    handleInit()
  }, [handleInit])

  React.useEffect(() => {
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Listar relatorios', path: '/main/report/list' }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeGenerator = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    setLoading(true)
    const newSelectedCustomer = getCompanyByIdentifier(customers, value?.value as string)
    if (newSelectedCustomer) setDefaultCompany(newSelectedCustomer)
    setSelectedCustomer(value?.value as string)
    getReportsByCustomerIdentifier(value?.value as string)
      .then(setReports)
      .catch(() => {
        showAlert('error', 'Erro ao carregar Relatórios. Tente novamente mais tarde.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <DeleteConfirmation
        handleConfirmDelete={handleConfirmDelete}
        isShowDeleteConfirmation={isShowDeleteConfirmation}
        handleClose={handleClose}
        deleteItemTexts={deleteItemTexts}
      />
      <Card className="card-filters" variant="outlined">
        <InputSelect2
          id="branches"
          label="Cliente"
          value={selectedCustomer}
          options={customerOptions}
          onChange={handleChangeGenerator}
        />
      </Card>
      <div className="filter--open">
        <DataTable
          data={reports}
          dataMap={reportsMap}
          loading={loading}
          actions={[
            {
              label: 'Opções',
              icon: <Icons.MoreVertical fill={ApplicationColors.primaryColor} />,
              fn: handleMoreOptions,
            },
          ]}
        />
        <ViewDeleteEditPopover
          viewFunction={handleView}
          deleteFunction={iconAccessCheck('report-delete') ? openDeleteConfirmation : undefined}
          editFunction={iconAccessCheck('report-edit') ? handleEdit : undefined}
          open={isOpen}
          item={itemToChange}
          handleClose={handleClosePopOver}
          anchor={anchor}
        />
      </div>
    </>
  )
}

export default ReportList
