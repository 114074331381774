import axios from 'axios'

import { UserToSend, UserMinimalResponse, UserResponse, UserFullResponse } from '../../models/User/user'
import getEndpointUrl from '../../utils/url-resolver'

const baseUrl = `${getEndpointUrl('company')}/v1/users`

const getUserByIdentifier = (identifier: string): Promise<UserResponse> =>
  axios.get<UserResponse>(`${baseUrl}/${identifier}`).then((response) => response.data)

const getUsersByCustomerIdentifier = (customerIdentifier: string): Promise<UserMinimalResponse[]> =>
  axios.get<UserMinimalResponse[]>(`${baseUrl}/customer/${customerIdentifier}`).then((response) => response.data)

const createUser = (user: UserToSend): Promise<UserResponse> =>
  axios.post<UserResponse>(`${baseUrl}/`, user).then((response) => response.data)

const updateUser = (user: UserToSend, identifier: string): Promise<UserResponse> =>
  axios.put<UserResponse>(`${baseUrl}/${identifier}`, user).then((response) => response.data)

const updateUserCargo = (accessLevelIds: number[], userIdentifier: string): Promise<UserMinimalResponse[]> =>
  axios
    .put<UserMinimalResponse[]>(`${baseUrl}/${userIdentifier}/cargo`, accessLevelIds)
    .then((response) => response.data)

const getThisUser = (): Promise<UserFullResponse> =>
  axios.get<UserFullResponse>(`${baseUrl}/principal`).then((response) => response.data)

export { getUsersByCustomerIdentifier, updateUserCargo, getUserByIdentifier, createUser, updateUser, getThisUser }
