export interface FeamStatusResponse {
  status: string
  code: number
}

export interface FeamStatusRequest {
  cnpj: string
}

export const FeamAcondicionamento = [
  { value: '1', label: 'Tambor' },
  { value: '2', label: 'Caçamba Aberta' },
  { value: '3', label: 'Caçamba Fechada' },
  { value: '4', label: 'Bombona Plástica' },
  { value: '5', label: 'Granel' },
  { value: '6', label: 'Caixa' },
  { value: '7', label: 'BigBag' },
  { value: '8', label: 'Fardo' },
  { value: '9', label: 'E08 - Caixa de papelão' },
  { value: '10', label: 'E08 - Balde' },
  { value: '11', label: 'E04 - Tanque' },
  { value: '12', label: 'E08 - Palete' },
  { value: '13', label: 'E08 - Outros' },
  { value: '14', label: 'Saco Plástico' },
  { value: '31', label: 'Caixa RSS perfurocortante' },
  { value: '32', label: 'Cofre' },
  { value: '33', label: 'IBC' },
  { value: '34', label: 'Recipiente Pressurizado' },
  { value: '35', label: 'Tambor metálico' },
  {
    value: '36',
    label: 'Cilindro ou outro recipiente pressurizado para acondicionamento de gases',
  },
  { value: '37', label: 'Contêiner/ Contentor' },
  {
    value: '38',
    label: 'IBC (contentor intermediário rígido para carga a granel)',
  },
  {
    value: '39',
    label: 'FIBC (contentor intermediário flexível para carga a granel)',
  },
]

export const FeamTecnologias = [
  { value: 31, label: 'Aterro Classe I' },
  { value: 32, label: 'Aterro Classe IIA e IIB' },
  { value: 33, label: 'Aterro de Reservação - RCC' },
  { value: 13, label: '"Autoclave",' },
  { value: 34, label: 'Barragem de Rejeitos' },
  { value: 35, label: 'Biometanização' },
  { value: 8, label: 'Blendagem para Coprocessamento' },
  { value: 10, label: 'Compostagem' },
  { value: 4, label: 'Coprocessamento' },
  { value: 17, label: 'Descontaminação de Lâmpadas' },
  { value: 36, label: 'Desmontagem REE e Veículos' },
  { value: 37, label: 'Disposição em Cava de Mineração' },
  { value: 14, label: 'Gaseificação' },
  { value: 6, label: 'Incineração' },
  { value: 15, label: 'Microondas' },
  { value: 38, label: 'Pilha de Estéril' },
  { value: 39, label: 'Pirólise' },
  { value: 1, label: 'Reciclagem' },
  { value: 3, label: 'Recuperação energética' },
  { value: 50, label: 'Remediação ex situ' },
  { value: 16, label: 'Rerrefino' },
  { value: 44, label: 'Reutilização' },
  { value: 42, label: 'Sistema de logística reversa formalmente instituído' },
  { value: 11, label: 'Tratamento de Efluentes' },
  { value: 9, label: 'Tratamento Térmico' },
  { value: 40, label: 'Triagem e Transbordo' },
  { value: 12, label: 'Uso Agrícola' },
  { value: 41, label: 'Uso Alimentação Animal' },
  { value: 51, label: 'Uso em vias para pavimentação ou manutenção' },
  { value: 43, label: 'Utilização de biomassa em caldeira' },
]

export const FeamUnidades = [
  { value: 1, label: 'Metro Cúbico' },
  { value: 2, label: 'Litro' },
  { value: 3, label: 'Quilograma' },
  { value: 4, label: 'Tonelada' },
  { value: 5, label: 'Unidade' },
]

export const FeamClasses = [
  { value: 1, label: 'Classe I' },
  { value: 2, label: 'Classe II A' },
  { value: 3, label: 'Classe II B' },
  { value: 4, label: 'RSS Grupo A1' },
  { value: 5, label: 'RSS Grupo A2' },
  { value: 6, label: 'RSS Grupo A3' },
  { value: 7, label: 'RSS Grupo A4' },
  { value: 8, label: 'RSS Grupo A5' },
  { value: 9, label: 'RSS Grupo B' },
  { value: 10, label: 'RSS Grupo C' },
  { value: 11, label: 'RSS Grupo E' },
  { value: 12, label: 'RSS Grupo A' },
  {
    value: 13,
    label: 'Resíduos Oriundos de Esgotamento Sanitário (Limpa Fossa)',
  },
  { value: 15, label: 'RCC Classe A' },
  { value: 16, label: 'RCC Classe B' },
  { value: 17, label: 'RCC Classe C' },
  { value: 18, label: 'RCC Classe D' },
  { value: 19, label: 'RSS Grupo D' },
]

export const FeamEstadoFisico = [
  { value: 1, label: 'Sólido' },
  { value: 2, label: 'Líquido' },
  { value: 3, label: 'Semi-sólido' },
  { value: 4, label: 'Gasoso' },
]

export const FeamResiduos = [
  {
    value: '010309',
    label: 'Lamas vermelhas da produção de alumina não abrangidas em 01 03 07',
  },
  {
    value: '110501',
    label: 'Escórias e cinzas de zinco não perigosas',
  },
  {
    value: '110502(*)',
    label: 'Cinzas de zinco contendo cádmio ou chumbo',
  },
  {
    value: '110503(*)',
    label: 'Resíduos sólidos do tratamento de gases  ',
  },
  {
    value: '110504(*)',
    label: 'Fluxantes usados',
  },
  {
    value: '110599',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '130201(*)',
    label: 'Óleos de motores, transmissões e lubrificação usados ou contaminados',
  },
  {
    value: '130299(*)',
    label: 'Outros óleos de motores, transmissão e lubrificação',
  },
  {
    value: '060699',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '170502(*)',
    label: 'Solos e rochas contendo contaminados com bifenilas policloradas (PCB)',
  },
  {
    value: '160605',
    label: 'Outras pilhas, baterias e acumuladores',
  },
  {
    value: '020110',
    label:
      'Resíduos metálicos, como por exemplo, estruturas metálicas, sucatas metálicas, varas e cabos utilizados em campo',
  },
  {
    value: '040110',
    label: 'Lodo do caleiro',
  },
  {
    value: '010306',
    label: 'Rejeitados não abrangidos em 01 03 04 (*) e 01 03 05 (*)',
  },
  {
    value: '101401(*)',
    label: 'Resíduos de limpeza de gases contendo mercúrio',
  },
  {
    value: '010308',
    label: 'Poeiras e pós não abrangidos em 01 03 07 (*)',
  },
  {
    value: '010408',
    label: 'Cascalhos e fragmentos de rocha não abrangidos em 01 04 07 (*)',
  },
  {
    value: '020301',
    label: 'Lodos de lavagem, limpeza, descasque, centrifugação e separação',
  },
  {
    value: '010410',
    label: 'Poeiras e pós não abrangidos em 01 04 07 (*)',
  },
  {
    value: '010412',
    label: 'Rejeitados e outros resíduos, resultantes da lavagem de minérios, não abrangidos em 01 04 07 (*)',
  },
  {
    value: '050702',
    label: 'Resíduos contendo enxofre de dessulfurização de petróleo',
  },
  {
    value: '010413',
    label: 'Resíduos de corte e serragem de pedra não abrangidos em 01 04 07 (*)',
  },
  {
    value: '060103(*)',
    label: 'Acido fluorídrico',
  },
  {
    value: '060104(*)',
    label: 'Acido fosfórico e acido fosforoso',
  },
  {
    value: '060105(*)',
    label: 'Acido nítrico e acido nitroso',
  },
  {
    value: '060106(*)',
    label: 'Outros ácidos',
  },
  {
    value: '060199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '060201(*)',
    label: 'Hidróxido de cálcio',
  },
  {
    value: '010507',
    label: 'Lodos e outros resíduos de perfuração contendo sais de bário não abrangidos em 01 05 05 (*) e 01 05 06 (*)',
  },
  {
    value: '130402(*)',
    label: 'Óleos bunker provenientes das canalizações dos cais',
  },
  {
    value: '160602(*)',
    label: 'Bateria e acumuladores elétricos de niquel cádmio e seus resíduos',
  },
  {
    value: '160120',
    label: 'Vidro',
  },
  {
    value: '010101',
    label: 'Resíduos da Extração de Minérios Metálicos',
  },
  {
    value: '010102',
    label: 'Resíduos da Extração de Minérios Não Metálicos',
  },
  {
    value: '010304(*)',
    label: 'Rejeitados geradores de ácidos, resultantes da transformação de sulfuretos',
  },
  {
    value: '010305(*)',
    label: 'Outros rejeitados contendo substancias perigosas',
  },
  {
    value: '010307(*)',
    label:
      'Outros resíduos contendo substancias perigosas, resultantes da transformação física e química de minérios metálicos',
  },
  {
    value: '010399',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '010407(*)',
    label:
      'Resíduos contendo substancia perigosas, resultantes da transformação física e química de minérios não metálicos',
  },
  {
    value: '010409',
    label: 'Areias e Argilas',
  },
  {
    value: '010499',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '010504',
    label: 'Lodos e outros resíduos de perfuração contendo água doce',
  },
  {
    value: '010505(*)',
    label: 'Lodos e outros resíduos de perfuração contendo hidrocarbonetos',
  },
  {
    value: '010506(*)',
    label: 'Lodos e outros resíduos de perfuração contendo substancias perigosas',
  },
  {
    value: '191208',
    label: 'Têxteis',
  },
  {
    value: '010599',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '020101',
    label: 'Lodos provenientes da lavagem e limpeza',
  },
  {
    value: '020102',
    label: 'Resíduos de tecidos animais',
  },
  {
    value: '020103',
    label: 'Resíduos de tecidos vegetais',
  },
  {
    value: '020104',
    label: 'Resíduos de plásticos (excluindo embalagens)',
  },
  {
    value: '020106',
    label:
      'Fezes, urina e estrume de animais (incluindo palha suja), efluentes recolhidos separadamente e tratados noutro local',
  },
  {
    value: '020107',
    label: 'Resíduos Silvícolas',
  },
  {
    value: '020108(*)',
    label: 'Resíduos agrotóxicos e afins (agroquímicos) contendo substancias perigosas',
  },
  {
    value: '020199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '020201',
    label: 'Lodos provenientes da lavagem e limpeza',
  },
  {
    value: '020202',
    label: 'Resíduos de tecidos animais e orgânico de processo (sebo, soro, ossos, sangue, etc.)',
  },
  {
    value: '020203',
    label: 'Materiais impróprios para consumo ou processamento',
  },
  {
    value: '020204',
    label: 'Lodos do Tratamento local de efluentes',
  },
  {
    value: '020299',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '020302',
    label: 'Resíduos de agentes conservantes',
  },
  {
    value: '020303',
    label: 'Resíduos da extração por solventes',
  },
  {
    value: '020304',
    label: 'Materiais impróprios para consumo ou processamento',
  },
  {
    value: '020305',
    label: 'Lodos do Tratamento local de efluentes',
  },
  {
    value: '020399',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '020403',
    label: 'Lodos do Tratamento local de efluentes',
  },
  {
    value: '020404',
    label: 'Vinhaça',
  },
  {
    value: '020405',
    label: 'Bagaço de Cana de Açúcar',
  },
  {
    value: '020499',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '020501',
    label: 'Materiais impróprios para consumo ou processamento',
  },
  {
    value: '020502',
    label: 'Lodos do Tratamento local de efluentes',
  },
  {
    value: '020599',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '020601',
    label: 'Materiais impróprios para consumo ou processamento',
  },
  {
    value: '020602',
    label: 'Resíduos de agentes conservantes',
  },
  {
    value: '020603',
    label: 'Lodos do Tratamento local de efluentes',
  },
  {
    value: '020699',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '020701',
    label: 'Resíduos de lavagem, limpeza e redução mecânica das matérias primas',
  },
  {
    value: '020702',
    label: 'Resíduos da destilação de álcool',
  },
  {
    value: '020703',
    label: 'Resíduos de tratamentos químicos',
  },
  {
    value: '020704',
    label: 'Materiais impróprios para consumo ou processamento',
  },
  {
    value: '020705',
    label: 'Lodos do Tratamento local de efluentes',
  },
  {
    value: '020799',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '030101',
    label: 'Resíduos do descasque da madeira',
  },
  {
    value: '030104(*)',
    label: 'Serragem, aparas, fitas de aplainamento, madeira, aglomerados e folheados, contendo substancias perigosas',
  },
  {
    value: '030199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '030201(*)',
    label: 'Produtos orgânicos não halogenados de preservação da madeira',
  },
  {
    value: '030202(*)',
    label: 'Agentes organoclorados de preservação da madeira',
  },
  {
    value: '030203(*)',
    label: 'Agentes organometálicos de preservação da madeira',
  },
  {
    value: '030204(*)',
    label: 'Agentes inorgânicos de preservação da madeira',
  },
  {
    value: '030205(*)',
    label: 'Outros agentes de preservação da madeira contendo substancias perigosas',
  },
  {
    value: '030206(*)',
    label:
      'Efluentes líquidos e resíduos originados no processo de preservação da madeira, provenientes de plantas que utilizam formulações que contem creosoto, com exceção dos efluentes líquidos dos processos de preservação da madeira que usam creosoto e/ou pentaclorofenol',
  },
  {
    value: '030207(*)',
    label:
      'Efluentes líquidos e resíduos originados no processo de preservação da madeira, provenientes de plantas que utilizam ou tenham utilizado formulações clorofenólicas, com exceção dos efluentes líquidos dos processos de preservação da madeira que usam creosoto e/ou pentaclorofenol',
  },
  {
    value: '191205',
    label: 'Vidro',
  },
  {
    value: '030208(*)',
    label:
      'Efluentes líquidos e resíduos originados no processo de preservação da madeira, provenientes de plantas que utilizam conservantes inorgânicos contendo arsênio ou cromo, com exceção dos efluentes líquidos dos processos de preservação de madeira que usam creosoto e/ou pentaclorofenol',
  },
  {
    value: '030299',
    label: 'Agentes de preservação da madeira não anteriormente especificados',
  },
  {
    value: '030301',
    label: 'Resíduos do descasque da madeira e resíduos de madeira',
  },
  {
    value: '030302',
    label: 'Lodos de lixívia verde (provenientes da valorização da lixívia de cozimento ou licor negro)',
  },
  {
    value: '030305',
    label: 'Lodos de branqueamento, provenientes da reciclagem de papel',
  },
  {
    value: '030307',
    label: 'Rejeitos mecanicamente separados da fabricação de pasta a partir de papel e papelão usado',
  },
  {
    value: '030308',
    label: 'Resíduos de triagem de papel e papelão destinado a reciclagem',
  },
  {
    value: '030309',
    label: 'Resíduos de lodo de cal',
  },
  {
    value: '030310',
    label: 'Rejeitos de fibras e lodos de fibras, fillers, e revestimentos, provenientes da separação mecânica',
  },
  {
    value: '030311',
    label: 'Lodos do tratamento local de efluentes não abrangidos em 03 03 10',
  },
  {
    value: '030399',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '040101',
    label: 'Resíduos das operações de descarna e divisão de tripa.',
  },
  {
    value: '040102',
    label: 'Resíduos da operação de calagem',
  },
  {
    value: '040103(*)',
    label: 'Resíduos de desengorduramento contendo solventes sem fase aquosa',
  },
  {
    value: '040104',
    label: 'Licores de curtimento contendo cromo',
  },
  {
    value: '040105',
    label: 'Licores de curtimento sem cromo',
  },
  {
    value: '040106',
    label: 'Lodos, em especial do tratamento de efluentes, contendo cromo',
  },
  {
    value: '040107',
    label: 'Lodos, em especial do tratamento de efluentes, sem cromo',
  },
  {
    value: '040108',
    label: 'Aparas, serragem e pós de couro provenientes de couros curtidos ao cromo',
  },
  {
    value: '040109',
    label: 'Resíduos da confecção e acabamentos',
  },
  {
    value: '040111(*)',
    label:
      'Lodos provenientes do tratamento de efluentes líquidos originados no processo de curtimento de couros ao cromo',
  },
  {
    value: '040199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '040209',
    label: 'Resíduos de materiais têxteis (têxteis impregnados, elastômeros, plastômeros)',
  },
  {
    value: '040210',
    label: 'Matéria orgânica de produtos naturais (por exemplo gordura, cera)',
  },
  {
    value: '040214(*)',
    label: 'Resíduos dos acabamentos, contendo solventes orgânicos ou contaminados',
  },
  {
    value: '040216(*)',
    label: 'Corantes e pigmentos contendo substancias perigosas',
  },
  {
    value: '040219(*)',
    label: 'Lodos do tratamento local de efluentes contendo substancias perigosas',
  },
  {
    value: '040221',
    label: 'Resíduos de fibras têxteis não processadas',
  },
  {
    value: '040222',
    label: 'Resíduos de fibras têxteis processadas',
  },
  {
    value: '040299',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '050102(*)',
    label: 'Lodos de dessalinização',
  },
  {
    value: '050103(*)',
    label:
      'Resíduos provenientes de fundos de tanques empregados na indústria de refino de petróleo, inclusive do tanque de armazenamento de óleo crú',
  },
  {
    value: '050104(*)',
    label: 'Lodos alquílicos ácidos',
  },
  {
    value: '050105(*)',
    label: 'Derrames de hidrocarbonetos',
  },
  {
    value: '010508',
    label: 'Lodos e outros resíduos de perfuração contendo cloretos não abrangidos em 01 05 05 (*) e 01 05 06 (*)',
  },
  {
    value: '050106(*)',
    label:
      'Lodos contendo hidrocarbonetos provenientes de operações de manutenção das instalações ou equipamentos, inclusive lodos provenientes de separadores e da limpeza dos tubos dos trocadores de calor',
  },
  {
    value: '050107(*)',
    label: 'Alcatrões ácidos',
  },
  {
    value: '050108(*)',
    label: 'Outros alcatrões',
  },
  {
    value: '050109(*)',
    label: 'Lodos do tratamento local de efluentes contendo substancias perigosas',
  },
  {
    value: '050111(*)',
    label: 'Resíduos de limpeza de combustíveis com bases',
  },
  {
    value: '050112(*)',
    label: 'Hidrocarbonetos contendo ácidos',
  },
  {
    value: '050113',
    label: 'Lodos de tratamento de água para abastecimento de caldeiras',
  },
  {
    value: '050114',
    label: 'Resíduos de colunas de arrefecimento',
  },
  {
    value: '050115(*)',
    label: 'Argilas de filtração usadas',
  },
  {
    value: '050116',
    label: 'Resíduos contendo enxofre de dessulfurização de petróleo',
  },
  {
    value: '050117',
    label: 'Betumes',
  },
  {
    value: '050118(*)',
    label:
      'Sólidos provenientes da emulsão residual oleosa, inclusive o sobrenadante proveniente de separadores tipo DAF (Dissolved Air Flotation)',
  },
  {
    value: '050199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '050601(*)',
    label: 'Alcatrões ácidos',
  },
  {
    value: '050603(*)',
    label: 'Outros alcatrões',
  },
  {
    value: '050604',
    label: 'Resíduos de colunas de arrefecimento',
  },
  {
    value: '050605(*)',
    label:
      'Resíduos provenientes dos tanques e lagoas de produção do coque, incluindo resíduos da coqueificação do carvão',
  },
  {
    value: '050606(*)',
    label: 'Resíduos provenientes da recuperação e destilação de subprodutos do coque produzidos a partir do carvão',
  },
  {
    value: '050607(*)',
    label:
      'Resíduos provenientes dos sistemas de tratamento de gases dos processos de coqueificação da carvão e da obtenção de subprodutos de coque produzidos a partir  do carvão',
  },
  {
    value: '050608(*)',
    label: 'Lodo calcário da destilação da amônia proveniente das operações de coqueificação',
  },
  {
    value: '050699',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '050701(*)',
    label: 'Resíduos contendo mercúrio',
  },
  {
    value: '050799',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '060101(*)',
    label: 'Ácido sulfúrico e ácido sulfuroso',
  },
  {
    value: '060102(*)',
    label: 'Acido clorídrico',
  },
  {
    value: '060203(*)',
    label: 'Hidróxido de amônio',
  },
  {
    value: '060204(*)',
    label: 'Hidróxidos de sódio e de potássio',
  },
  {
    value: '060205(*)',
    label: 'Outras bases',
  },
  {
    value: '060299',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '060313(*)',
    label: 'Sais no estado sólido e em soluções contendo metais pesados',
  },
  {
    value: '060315(*)',
    label: 'Óxidos metálicos contendo metais pesados',
  },
  {
    value: '060399',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '060403(*)',
    label: 'Resíduos contendo arsênio',
  },
  {
    value: '020109',
    label: 'Resíduos agrotóxicos e afins (agroquímicos) não abrangidos em 02 01 08 (*)',
  },
  {
    value: '060405(*)',
    label: 'Resíduos contendo outros metais pesados',
  },
  {
    value: '060499',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '060502(*)',
    label: 'Lodos de tratamento local de efluentes contendo substancias perigosas',
  },
  {
    value: '030105',
    label: 'Serragem, aparas, fitas de aplainamento, madeira, aglomerados e folheados não abrangidos em 03 01 04 (*)',
  },
  {
    value: '040215',
    label: 'Resíduos dos acabamentos não abrangidos em 04 02 14 (*)',
  },
  {
    value: '040217',
    label: 'Corantes e pigmentos não abrangidos em 04 02 16 (*)',
  },
  {
    value: '040220',
    label: 'Lodos do tratamento local de efluente não abrangidos em 04 02 19 (*)',
  },
  {
    value: '060602(*)',
    label: 'Resíduos contendo sulfuretos perigosos',
  },
  {
    value: '050110',
    label: 'Lodos do tratamento local de efluentes não abrangidos em 05 01 09 (*)',
  },
  {
    value: '060316',
    label: 'Óxidos metálicos não abrangidos em 06 03 15 (*)',
  },
  {
    value: '060503',
    label: 'Lodos de tratamento local de efluentes não abrangidos em 06 05 02 (*)',
  },
  {
    value: '060603',
    label: 'Resíduos contendo sulfuretos não abrangidos em 06 06 02 (*)',
  },
  {
    value: '070217',
    label: 'Resíduos contendo silicones que não os mencionados na rubrica 07 02 16 (*)',
  },
  {
    value: '070312',
    label: 'Lodos do tratamento local de efluentes não abrangidas em 07 02 11 (*)',
  },
  {
    value: '070412',
    label: 'Lodos do tratamento local de efluentes não abrangidas em 07 04 11 (*)',
  },
  {
    value: '070512',
    label: 'Lodos do tratamento local de efluentes não abrangidas em 07 05 11 (*)',
  },
  {
    value: '070514',
    label: 'Resíduos sólidos não abrangidos em 07 05 13 (*)',
  },
  {
    value: '070612',
    label: 'Lodos do tratamento local de efluentes não abrangidas em 07 06 11 (*)',
  },
  {
    value: '070712',
    label: 'Lodos do tratamento local de efluentes não abrangidas em 07 07 11 (*)',
  },
  {
    value: '080112',
    label: 'Resíduos de tintas e vernizes não abrangidos em 08 01 11 (*)',
  },
  {
    value: '080114',
    label: 'Lodos de tintas e vernizes não abrangias em 08 01 13 (*)',
  },
  {
    value: '080116',
    label: 'Lodos aquosas contendo tintas e vernizes não abrangidas em 08 01 15 (*)',
  },
  {
    value: '080118',
    label: 'Resíduos da remoção de tintas e vernizes não abrangidos em 08 01 17 (*)',
  },
  {
    value: '080120',
    label: 'Suspensões aquosas contendo tintas e vernizes não abrangidas em 08 01 19 (*)',
  },
  {
    value: '080313',
    label: 'Resíduos de tintas não abrangidos em 08  03 12 (*)',
  },
  {
    value: '080315',
    label: 'Lodos de tintas de impressão não abrangidas em 08 03 14 (*)',
  },
  {
    value: '080318',
    label: 'Resíduos de toner de impressão não abrangidos em 08 03 17 (*)',
  },
  {
    value: '080410',
    label: 'Resíduos de colas ou vedantes não abrangidos em 08 04 09 (*)',
  },
  {
    value: '080412',
    label: 'Lodos de colas ou vedantes não abrangidas em 08 04 11 (*)',
  },
  {
    value: '060701(*)',
    label: 'Resíduos de eletrolise contendo amianto',
  },
  {
    value: '080414',
    label: 'Lodos aquosos contendo colas ou vedantes não abrangidos em 08 04 13 (*)',
  },
  {
    value: '080416',
    label: 'Resíduos líquidos aquosos contendo colas ou vedantes não abrangidos em 08 04 15 (*)',
  },
  {
    value: '060311(*)',
    label: 'Sais no estado sólido e em soluções contendo cianetos',
  },
  {
    value: '060314(*)',
    label: 'Sais no estado sólido e não abrangidos em 06 03 11 (*) e 03 03 13 (*)',
  },
  {
    value: '070215',
    label: 'Resíduos de aditivos não abrangidos em 07 02 14 (*)',
  },
  {
    value: '100208',
    label: 'Resíduos sólidos do tratamento de gases não abrangidos em 10 02 07 (*)',
  },
  {
    value: '100212',
    label: 'Resíduos do tratamento de agua de arrefecimento não abrangidos em 10 02 11 (*)',
  },
  {
    value: '100214',
    label: 'Lodos e tortas de filtro de tratamento de gases não abrangidos em 10 02 13 (*)',
  },
  {
    value: '100318',
    label: 'Resíduos da fabricação de ânodos contendo carbono, não abrangidos em 10 03 17 (*)',
  },
  {
    value: '100320',
    label: 'Poeiras de gases de combustão não abrangidos em 10 03 19 (*)',
  },
  {
    value: '100322',
    label: 'Outras partículas e poeiras (incluindo poeiras da trituração de escórias) não abrangidas em 10 03 21 (*)',
  },
  {
    value: '100324',
    label: 'Resíduos sólidos do tratamento de gases não abrangidos em 10 03 23 (*)',
  },
  {
    value: '100326',
    label: 'Lodos e tortas de filtro do tratamento de gases não abrangidos em 10 03 25 (*)',
  },
  {
    value: '100328',
    label: 'Resíduos do tratamento da água de arrefecimento não abrangidos em 10 03 27 (*)',
  },
  {
    value: '100330',
    label:
      'Resíduos do tratamento de escórias salinas e do tratamento das impurezas negras não abrangidos em 10 03 29 (*)',
  },
  {
    value: '100410',
    label: 'Resíduos de tratamento de água de arrefecimento não abrangidos em 10 04 09 (*)',
  },
  {
    value: '100509',
    label: 'Resíduos do tratamento da água de arrefecimento não abrangidos em 10 05 08 (*)',
  },
  {
    value: '100511',
    label: 'Impurezas e escumas não abrangidas em 10 05 10 (*)',
  },
  {
    value: '100610',
    label: 'Resíduos do tratamento da água de arrefecimento não abrangidos em 10 06 09 (*)',
  },
  {
    value: '100708',
    label: 'Resíduos do tratamento da água de arrefecimento não abrangidos em 10 07 07 (*)',
  },
  {
    value: '100811',
    label: 'Impurezas e escumas não abrangidas em 10 08 10 (*)',
  },
  {
    value: '100813',
    label: 'Resíduos da fabricação de anodos contendo carbono não abrangidos em 10 08 12 (*)',
  },
  {
    value: '100816',
    label: 'Poeiras de gases não abrangidos em 10 08 15 (*)',
  },
  {
    value: '100818',
    label: 'Lodos e tortas de filtro do tratamento de gases de combustão não abrangidos em 10 08 17 (*)',
  },
  {
    value: '100820',
    label: 'Resíduos do tratamento da água de arrefecimento não abrangidos em 10 08 19 (*)',
  },
  {
    value: '100906',
    label: 'Moldes e modelos e moldes de fundição não vazados não abrangidos em 10 09 05 (*)',
  },
  {
    value: '100908',
    label: 'Moldes e modelos e moldes de fundição vazados não abrangidos em 10 09 07 (*)',
  },
  {
    value: '100910',
    label: 'Poeiras de gases de combustão não abrangidos em 10 09 09 (*)',
  },
  {
    value: '100912',
    label: 'Outras partículas não abrangidas em 10 09 11 (*)',
  },
  {
    value: '100914',
    label: 'Resíduos de aglutinantes não abrangidos em 10 09 13 (*)',
  },
  {
    value: '100916',
    label: 'Resíduos de agentes indicadores de fendas e trincas não abrangidos em 10 09 15 (*)',
  },
  {
    value: '101006',
    label: 'Moldes e modelos e moldes de fundição não vazados não abrangidos em 10 10 05 (*)',
  },
  {
    value: '101008',
    label: 'Moldes e modelos e moldes de fundição vazados não abrangidos em 10 10 07 (*)',
  },
  {
    value: '101010',
    label: 'Poeiras de gases de combustão não abrangidas em 10 10 09 (*)',
  },
  {
    value: '101012',
    label: 'Outras partículas não abrangidas em 10 10 11 (*)',
  },
  {
    value: '101014',
    label: 'Resíduos de aglutinantes não abrangidos em 10 10 13 (*)',
  },
  {
    value: '101016',
    label: 'Resíduos de agentes indicadores de fendas e trincas não abrangidos em 10 10 15 (*)',
  },
  {
    value: '101110',
    label: 'Resíduos da preparação da mistura (antes do processo térmico) não abrangidas em 10 11 09 (*)',
  },
  {
    value: '101112',
    label: 'Resíduos de vidros não abrangidos em 10 11 11 (*)',
  },
  {
    value: '101114',
    label: 'Lodos de polimento e retificação de vidro não abrangidos em 10 11 13 (*)',
  },
  {
    value: '101116',
    label: 'Resíduos sólidos de tratamento de gases de combustão não abrangidos em 10 11 15 (*)',
  },
  {
    value: '101118',
    label: 'Lodos e tortas de filtro do tratamento de gases de combustão não abrangidas em 10 11 17 (*)',
  },
  {
    value: '101120',
    label: 'Resíduos sólidos do tratamento local de efluentes não abrangidas em 10 11 19 (*)',
  },
  {
    value: '101210',
    label: 'Resíduos sólidos do tratamento de gases não abrangidos em 10 12 09 (*)',
  },
  {
    value: '101212',
    label: 'Resíduos de vitrificação não abrangidos em 10 12 11 (*)',
  },
  {
    value: '101306',
    label: 'Partículas e poeiras exceto 10 13 12  (*) e 10 13 13',
  },
  {
    value: '101310',
    label: 'Resíduos da fabricação de fibrocimento não abrangidos em 10 13 09 (*)',
  },
  {
    value: '101311',
    label: 'Resíduos de materiais compósitos à base de cimento não abrangidos em 10 13 09 (*) e 10 13 10',
  },
  {
    value: '101313',
    label: 'Resíduos sólidos do tratamento de gases não abrangidos em 10 13 12 (*)',
  },
  {
    value: '110110',
    label: 'Lodos e tortas de filtro não abrangidas em 11 01 09 (*)',
  },
  {
    value: '110112',
    label: 'Líquidos de lavagem aquosos não abrangidos em 11 01 11 (*)',
  },
  {
    value: '110114',
    label: 'Resíduos de desengorduramento não abrangidas em 11 01 13 (*)',
  },
  {
    value: '110206',
    label: 'Resíduos de processos hidrometalurgicos do cobre não abrangidos em 11 02 05 (*)',
  },
  {
    value: '120302(*)',
    label: 'Resíduos de desengorduramento a vapor',
  },
  {
    value: '150111(*)',
    label:
      'Embalagens de metal, incluindo recipientes vazios sob pressão, com uma matriz porosa sólida perigosa (por exemplo amianto)',
  },
  {
    value: '150202(*)',
    label:
      'Absorventes, materiais filtrantes (incluindo filtros de óleo não anteriormente especificados), panos de limpeza e vestuário de proteção, contaminados por substâncias perigosas',
  },
  {
    value: '150203',
    label: 'Absorventes, materiais filtrantes, panos de limpeza e vestuário de proteção não abrangidos em 15 02 02 (*)',
  },
  {
    value: '160104',
    label: 'Veículos em fim de vida esvaziados de líquidos e outros componentes perigosos',
  },
  {
    value: '160112',
    label: 'Pastilhas de freio não abrangidas em 16 01 11 (*)',
  },
  {
    value: '160115',
    label: 'Fluídos anticongelantes não abrangidos em 16 01 14 (*)',
  },
  {
    value: '160121(*)',
    label: 'Componentes perigosos não abrangentes em 16 01 07 (*) a 16 01 11 (*), 16 01 13 (*) e 16 01 14 (*)',
  },
  {
    value: '160210(*)',
    label: 'Equipamento fora de uso contendo ou contaminado por PCB não abrangido em 16 02 09 (*)',
  },
  {
    value: '160213(*)',
    label: 'Equipamento fora de uso contendo componentes perigosos não abrangidos em 16 02 09 (*) a 16 02 12 (*)',
  },
  {
    value: '160214',
    label: 'Equipamento fora de uso não abrangidos em 16 02 09 (*) a 16 02 13 (*)',
  },
  {
    value: '160304',
    label: 'Resíduos inorgânicos não abrangidos em 16 03 03 (*)',
  },
  {
    value: '160306',
    label: 'Resíduos orgânicos não abrangidos em 16 03 05 (*)',
  },
  {
    value: '160505',
    label: 'Gases em recipientes sob pressão não abrangidos em 13 05 04 (*)',
  },
  {
    value: '160509',
    label: 'Produtos químicos fora de uso não abrangidos em 16 05 06 (*), 16 05 07 (*) ou 16 05 08 (*)',
  },
  {
    value: '090113(*)',
    label: 'Resíduos líquidos aquosos da recuperação local de prata não abrangidos em 09 01 06 (*)',
  },
  {
    value: '100101',
    label: 'Cinzas, escórias e poeiras de caldeiras (excluídas as poeiras de caldeiras abrangidas em 10 01 04 (*)',
  },
  {
    value: '100115',
    label: 'Cinzas, escórias e poeiras de caldeiras de co-incineração não abrangidas em 10 01 14 (*)',
  },
  {
    value: '100117',
    label: 'Cinzas voláteis de co-incineração não abrangidas em 10 01 16 (*)',
  },
  {
    value: '100119',
    label: 'Resíduos de lavagem de gases não abrangidos em 10 01 05, 10 01 07 e 10 01 18 (*)',
  },
  {
    value: '100121',
    label: 'Lodos de tratamento não abrangidos em 10 01 20 (*)',
  },
  {
    value: '100123',
    label: 'Lodos aquosos provenientes da limpeza de caldeiras não abrangidos em 10 01 22 (*)',
  },
  {
    value: '160801',
    label: 'Catalisadores usados contendo ouro, prata, rênio, ródio, paládio, irídio ou platina, exceto 16 08 07 (*)',
  },
  {
    value: '160804',
    label: 'Catalisadores usados de cracking catalítico em leito fluidizado, exceto 16 08 99 (*)',
  },
  {
    value: '161002',
    label: 'Resíduos líquidos aquosos não abrangidos em 16 10 01 (*)',
  },
  {
    value: '161004',
    label: 'Concentrados aquosos não abrangidos em 16 10 03 (*)',
  },
  {
    value: '161102',
    label: 'Revestimentos de fornos e refratários à base de carbono não abrangidos em 16 11 01 (*)',
  },
  {
    value: '161104',
    label: 'Outros revestimentos de fornos e refratários não abrangidos em 16 11 03 (*)',
  },
  {
    value: '161106',
    label:
      'Revestimentos de fornos e refratários provenientes de processos não metalúrgicos não abrangidos em 16 11 05 (*)',
  },
  {
    value: '170107',
    label: 'Misturas de cimento, tijolos, ladrilhos, telhas e materiais cerâmicos não abrangidos em 17 01 06 (*)',
  },
  {
    value: '170302',
    label: 'Misturas betuminosas não abrangidas em 17 03 01 (*)',
  },
  {
    value: '170411',
    label: 'Cabos não abrangidos em 17 04 10 (*)',
  },
  {
    value: '170504',
    label: 'Solos e rochas não abrangidos em 17 05 03 (*)',
  },
  {
    value: '170506',
    label: 'Lodos de dragagem não abrangidos em 17 05 05 (*)',
  },
  {
    value: '170508',
    label: 'Britas de linhas de ferroviárias não abrangidas em 17 05 07 (*)',
  },
  {
    value: '170604',
    label: 'Materiais de isolamento não abrangidos em 17 06 01 (*) e 17 06 03 (*)',
  },
  {
    value: '170802',
    label: 'Materiais de construção à base de gesso não abrangidos em 17 08 01 (*)',
  },
  {
    value: '170904',
    label: 'Mistura de resíduos de construção e demolição não abrangidos em 17 09 01 (*), 17 09 02 (*) e 17 09 03 (*)',
  },
  {
    value: '200136',
    label:
      'Produtos eletroeletrônicos e seus componentes fora de uso não abrangido em 20 01 21 (*), 20 01 23 (*) ou 20 01 35 (*)',
  },
  {
    value: '080501(*)',
    label: 'resíduos de isocianatos',
  },
  {
    value: '090101(*)',
    label: 'Banhos de revelação e ativação de base aquosa',
  },
  {
    value: '090102(*)',
    label: 'Banhos de revelação de chapas litográficas de impressão de base aquosa',
  },
  {
    value: '090103(*)',
    label: 'Banhos de revelação à base de solventes',
  },
  {
    value: '090104(*)',
    label: 'Banhos de fixação',
  },
  {
    value: '090105(*)',
    label: 'Banhos de branqueamento e de fixadores de branqueamento',
  },
  {
    value: '090106(*)',
    label: 'Resíduos contendo prata do tratamento local de resíduos fotográficos',
  },
  {
    value: '090107',
    label: 'Película e papel fotográfico com prata ou compostos de prata',
  },
  {
    value: '090108',
    label: 'Película e papel fotográfico sem prata ou compostos de prata',
  },
  {
    value: '090199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '100102',
    label: 'Cinzas voláteis da combustão de carvão',
  },
  {
    value: '100103',
    label: 'Cinzas voláteis da combustão de turfa ou madeira não tratada',
  },
  {
    value: '100104(*)',
    label: 'Cinzas voláteis e poeiras de caldeiras da combustão de hidrocarbonetos',
  },
  {
    value: '100105',
    label: 'Resíduos cálcicos de reação, na forma sólida, provenientes da dessulfuração de gases de combustão',
  },
  {
    value: '100107',
    label: 'Resíduos cálcicos de reação, na forma de lodos, provenientes da dessulfuração de gases de combustão',
  },
  {
    value: '100109(*)',
    label: 'Ácido sulfúrico e ácido sulfuroso',
  },
  {
    value: '100113(*)',
    label: 'Cinzas voláteis da combustão de hidrocarbonetos emulsionados, utilizados como combustível',
  },
  {
    value: '100114(*)',
    label: 'cinzas, escórias e poeiras de caldeiras de co-incineração contendo substancias perigosas',
  },
  {
    value: '100116(*)',
    label: 'Cinzas voláteis de co-incineração contendo substancias perigosas',
  },
  {
    value: '100118(*)',
    label: 'Resíduos de lavagem de gases contendo substancias perigosas',
  },
  {
    value: '100120(*)',
    label: 'Lodos de tratamento local de efluentes contendo substancias perigosas',
  },
  {
    value: '100122(*)',
    label: 'Lodos aquosos provenientes da limpeza de caldeiras contendo substancias perigosas',
  },
  {
    value: '100124',
    label: 'Areias de leitos fluidizados',
  },
  {
    value: '100125',
    label: 'Resíduos do armazenamento de combustíveis e da preparação de centrais elétricas a carvão',
  },
  {
    value: '100126',
    label: 'Resíduos do tratamento de água de arrefecimento',
  },
  {
    value: '100199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '190112',
    label: 'Cinzas e escórias não abrangidas em 19 01 11 (*)',
  },
  {
    value: '190114',
    label: 'Cinzas voláteis não abrangidas em 19 01 13 (*)',
  },
  {
    value: '190116',
    label: 'Cinzas de caldeiras não abrangidas em 19 01 15 (*)',
  },
  {
    value: '190118',
    label: 'Resíduos de pirólise não abrangidos em 19 01 17 (*)',
  },
  {
    value: '190206',
    label: 'Lodos de tratamento físico-químico não abrangidos em 19 02 05 (*)',
  },
  {
    value: '190210',
    label: 'Resíduos combustíveis não abrangidos em 19 02 08 (*) e 19 02 09 (*)',
  },
  {
    value: '190305',
    label: 'Resíduos estabilizados não abrangidos em 19 03 04 (*)',
  },
  {
    value: '190307',
    label: 'Resíduos solidificados não abrangidos em 19 03 06 (*)',
  },
  {
    value: '190703',
    label: 'Lixiviados ou líquidos percolados de aterros não abrangidos em 19 07 02 (*)',
  },
  {
    value: '190810(*)',
    label: 'Misturas de gorduras e óleos, da separação água/óleo, não abrangidas em 19 08 09',
  },
  {
    value: '190812',
    label: 'Lodos de tratamento biológico de efluentes industriais não abrangidos em 19 08 11 (*)',
  },
  {
    value: '190814',
    label: 'Lodos de outros tratamentos de efluentes industriais não abrangidos em 19 08 13 (*)',
  },
  {
    value: '191004',
    label: 'Frações leves e poeiras não abrangidas em 19 10 03 (*)',
  },
  {
    value: '191006',
    label: 'Outras frações não abrangidas em 19 10 05 (*)',
  },
  {
    value: '191106',
    label: 'Lodos de tratamento local de efluentes não abrangidos em 19 11 05 (*)',
  },
  {
    value: '191207',
    label: 'Madeira não abrangidas em 19 12 06 (*)',
  },
  {
    value: '191213',
    label:
      'Outros resíduos (incluindo misturas de materiais) do tratamento mecânico de resíduos não abrangidos em 19 12 12 (*)',
  },
  {
    value: '191302',
    label: 'Resíduos da descontaminação de solos não abrangidos em 19 13 01 (*)',
  },
  {
    value: '191304',
    label: 'Lodos da descontaminação de solos não abrangidos em 19 13 03 (*)',
  },
  {
    value: '191306',
    label: 'Lodos da descontaminação de águas freáticas não abrangidas em 19 13 05 (*)',
  },
  {
    value: '191308',
    label:
      'Resíduos líquidos aquosos e concentrados aquosos da descontaminação de águas freáticas não abrangidas em 19 13 07 (*)',
  },
  {
    value: '200128',
    label: 'Tintas, produtos adesivos, colas e resinas não abrangidas em 20 01 27 (*)',
  },
  {
    value: '200130',
    label: 'Detergentes não abrangidos em 20 01 29 (*)',
  },
  {
    value: '200133(*)',
    label:
      'Pilhas e acumuladores abrangidos em 16 06 01 (*) ou 16 06 03 (*) e pilhas e acumuladores não separados contendo essas pilhas ou acumuladores',
  },
  {
    value: '200134',
    label: 'Pilhas e acumuladores não abrangidos em 20 01 33 (*)',
  },
  {
    value: '200135(*)',
    label:
      'Produtos eletroeletrônicos e seus componentes fora de uso não abrangido em 20 01 21 (*) ou 20 01 23 (*) contendo componentes perigosos (6)',
  },
  {
    value: '200138',
    label: 'Madeira não abrangida em 20 01 37 (*)',
  },
  {
    value: '130502(*)',
    label: 'Lodo proveniente dos separadores óleo/água',
  },
  {
    value: '100809',
    label: 'Outras escórias',
  },
  {
    value: '130306(*)',
    label:
      'Óleos minerais isolantes , de refrigeração e de transmissão de calor clorados, não abrangidos em 13 03 01 (*)',
  },
  {
    value: '130307(*)',
    label: 'Óleos minerais isolantes , de refrigeração e de transmissão de calor não clorados',
  },
  {
    value: '130308(*)',
    label: 'Óleos sintéticos isolantes, de refrigeração e de transmissão de calor',
  },
  {
    value: '191204',
    label: 'Plásticos',
  },
  {
    value: '130309(*)',
    label: 'Óleos facilmente biodegradáveis isolantes, de refrigeração e de transmissão de calor',
  },
  {
    value: '130310(*)',
    label: 'Outros óleos isolantes, de refrigeração e de transmissão de calor',
  },
  {
    value: '130401(*)',
    label: 'Óleos bunker de navios de navegação interior',
  },
  {
    value: '130403(*)',
    label: 'Óleos bunker de outros tipos de navios',
  },
  {
    value: '130501(*)',
    label: 'Resíduos sólidos provenientes de desarenadores e de separadores óleo/água',
  },
  {
    value: '130503(*)',
    label: 'Lodo proveniente do interceptor',
  },
  {
    value: '130506(*)',
    label: 'Óleos provenientes dos separadores óleo/água',
  },
  {
    value: '130507(*)',
    label: 'Água com óleo proveniente dos separadores óleo/água',
  },
  {
    value: '130508(*)',
    label: 'Misturas de resíduos provenientes de desarenadores e de separadores',
  },
  {
    value: '130701(*)',
    label: 'Fuelóleo e óleo diesel',
  },
  {
    value: '130702(*)',
    label: 'Gasolina',
  },
  {
    value: '130703(*)',
    label: 'Outros combustíveis (incluindo misturas)',
  },
  {
    value: '130801(*)',
    label: 'Lodos ou emulsões de dessalinização',
  },
  {
    value: '130802(*)',
    label: 'Outras emulsões e misturas',
  },
  {
    value: '130899(*)',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '140601(*)',
    label: 'Clorofluorcarbonetos (CFC), HCFC, HFC',
  },
  {
    value: '140602(*)',
    label: 'Outros solventes e misturas de solventes halogenados',
  },
  {
    value: '140603(*)',
    label: 'Outros solventes e misturas de solventes',
  },
  {
    value: '140604(*)',
    label: 'Lodos ou resíduos sólidos contendo solventes halogenados',
  },
  {
    value: '140605(*)',
    label: 'Lodos ou resíduos sólidos contendo outros solventes',
  },
  {
    value: '150101',
    label: 'Embalagens de papel e cartão',
  },
  {
    value: '150102',
    label: 'Embalagens de plástico',
  },
  {
    value: '150103',
    label: 'Embalagens de madeira',
  },
  {
    value: '150104',
    label: 'Embalagens de metal',
  },
  {
    value: '150105',
    label: 'Embalagens longa-vida',
  },
  {
    value: '150106',
    label: 'Mistura de embalagens',
  },
  {
    value: '150107',
    label: 'Embalagens de vidro',
  },
  {
    value: '150109',
    label: 'Embalagens têxteis',
  },
  {
    value: '150110(*)',
    label:
      'Embalagens de qualquer um dos tipos acima descritos contendo ou contaminadas por resíduos de substâncias perigosas',
  },
  {
    value: '160103(*)',
    label: 'Veículos em fim de vida ',
  },
  {
    value: '160106(*)',
    label: 'Resíduo proveniente da trituração de veículos em fim de vida (Ash Schredder Residue)',
  },
  {
    value: '160108(*)',
    label: 'Componentes e peças contendo mercúrio',
  },
  {
    value: '160109(*)',
    label: 'Componentes e peças contendo PCB',
  },
  {
    value: '160107(*)',
    label: 'Filtros de óleo automotivos',
  },
  {
    value: '160110(*)',
    label: 'Componentes explosivos, por exemplo, almofadas de ar (air bags)',
  },
  {
    value: '160111(*)',
    label: 'Pastilhas de freio contendo amianto',
  },
  {
    value: '160113(*)',
    label: 'Fluidos de freio',
  },
  {
    value: '160114(*)',
    label: 'Fluidos anticongelantes contendo substancias perigosas',
  },
  {
    value: '160116',
    label: 'Recipientes para gás liquefeito sob pressão',
  },
  {
    value: '160117',
    label: 'Sucatas metálicas ferrosas',
  },
  {
    value: '160118',
    label: 'Sucatas metálicas não ferrosas',
  },
  {
    value: '160119',
    label: 'Plástico',
  },
  {
    value: '160122',
    label: 'Componentes não anteriormente especificados',
  },
  {
    value: '160123',
    label: 'Pneus inservíveis/usados aeronáuticos',
  },
  {
    value: '160124',
    label: 'Pneus inservíveis/usados de automóveis',
  },
  {
    value: '160125',
    label: 'Pneus inservíveis/usados de bicicletas',
  },
  {
    value: '160126',
    label: 'Pneus inservíveis/usados de caminhões e ônibus',
  },
  {
    value: '160127',
    label: 'Pneus inservíveis/usados de motocicletas',
  },
  {
    value: '160128',
    label: 'Pneus inservíveis/usados de tratores',
  },
  {
    value: '160129',
    label: 'Pneus inservíveis/usados outras aplicações',
  },
  {
    value: '160199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '160209(*)',
    label: 'Transformadores, capacitores e demais equipamentos elétricos contendo PCB',
  },
  {
    value: '160211(*)',
    label: 'Equipamento fora de uso contendo clorofluorcarbonetos, HCFC, HFC',
  },
  {
    value: '160212(*)',
    label: 'Equipamento fora de uso contendo amianto livre',
  },
  {
    value: '160215(*)',
    label: 'Componentes perigosos retirados de equipamento fora de uso',
  },
  {
    value: '160303(*)',
    label: 'Resíduos inorgânicos contendo substâncias perigosas',
  },
  {
    value: '160305(*)',
    label: 'Resíduos orgânicos contendo substâncias perigosas',
  },
  {
    value: '160401(*)',
    label: 'Resíduos de munições',
  },
  {
    value: '160402(*)',
    label: 'Resíduos de fogo de artifício',
  },
  {
    value: '160403(*)',
    label: 'Lodos provenientes do tratamento de efluentes líquidos  originados no processo e produção de explosivos',
  },
  {
    value: '160405(*)',
    label: 'Carvão usado proveniente do tratamento de efluentes líquidos que contenham explosivos',
  },
  {
    value: '160406(*)',
    label: 'Água rosa/vermelha proveniente das operações de TNT',
  },
  {
    value: '160499(*)',
    label: 'Outros resíduos de explosivos',
  },
  {
    value: '160504(*)',
    label: 'Gases em recipientes sob pressão (incluindo freons e halons) contendo substâncias perigosas',
  },
  {
    value: '160506(*)',
    label:
      'Produtos químicos de laboratório contendo ou compostos por substâncias perigosas, incluindo misturas de produtos químicos de laboratório',
  },
  {
    value: '160507(*)',
    label: 'Produtos químicos inorgânicos de laboratório contendo ou compostos por substancias perigosas',
  },
  {
    value: '160508(*)',
    label: 'Produtos químicos orgânicos fora de uso contendo ou compostos por substâncias perigosas',
  },
  {
    value: '160601(*)',
    label:
      'Bateria e acumuladores elétricos à base de chumbo e seus resíduos, incluindo os plásticos provenientes da carcaça externa da bateria ',
  },
  {
    value: '160603(*)',
    label: 'Pilhas contendo mercúrio',
  },
  {
    value: '160606(*)',
    label: 'Eletrólitos de pilhas e acumuladores recolhidos separadamente',
  },
  {
    value: '160708(*)',
    label: 'Resíduos contendo hidrocarbonetos',
  },
  {
    value: '160709(*)',
    label: 'Resíduos contendo outras substâncias perigosas',
  },
  {
    value: '160799',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '160802(*)',
    label: 'Catalisadores usados contendo metais de transição (4) ou compostos de metais de transição perigosos',
  },
  {
    value: '160803',
    label: 'Catalisadores usados contendo metais de transição ou compostos de metais não especificados de outra forma',
  },
  {
    value: '120113',
    label: 'Resíduos de soldadura',
  },
  {
    value: '160805(*)',
    label: 'Catalizadores usados contendo ácido fosfórico',
  },
  {
    value: '160806(*)',
    label: 'Líquidos usados utilizados como catalisadores',
  },
  {
    value: '190606',
    label: 'Lama e lodos de digestores de tratamento anaeróbico de resíduos animais e vegetais',
  },
  {
    value: '160807(*)',
    label: 'Catalisadores usados provenientes do reator de hidrocloração utilizado na produção de 1,1,1-tricloroetano',
  },
  {
    value: '160808(*)',
    label:
      'Catalizador gasto proveniente do hidrotratamento das operações de refino de petróleo, incluindo leitos usados para dessulfurizar as alimentações para outros reatores catalíticos (este código não inclui o meio de suporte inerte)',
  },
  {
    value: '160899(*)',
    label: 'Outros catalizadores usados contaminados com substâncias perigosas',
  },
  {
    value: '160901(*)',
    label: 'Permanganatos, por exemplo, permanganato de potássio',
  },
  {
    value: '160902(*)',
    label: 'Cromatos, por exemplo, cromato de potássio, dicromato de potássio ou de sódio',
  },
  {
    value: '160903(*)',
    label: 'Peróxidos , por exemplo, água oxigenada',
  },
  {
    value: '160904(*)',
    label: 'Substâncias oxidantes não anteriormente especificados',
  },
  {
    value: '161001(*)',
    label: 'Resíduos líquidos aquosos contendo substâncias perigosas',
  },
  {
    value: '161003(*)',
    label: 'Concentrados aquosos contendo substâncias perigosas',
  },
  {
    value: '161101(*)',
    label:
      'Revestimentos de fornos e refratários à base de carbono provenientes de processos metalúrgicos contendo substâncias perigosas',
  },
  {
    value: '161103(*)',
    label:
      'Outros revestimentos de fornos e refratários provenientes de processos metalúrgicos contendo substâncias perigosas',
  },
  {
    value: '161105(*)',
    label:
      'Revestimentos de fornos e refratários provenientes de processos não metalúrgicos contendo substâncias perigosas',
  },
  {
    value: '170101',
    label: 'Resíduos de cimento  ',
  },
  {
    value: '170102',
    label: 'Tijolos',
  },
  {
    value: '170103',
    label: 'Ladrilhos, telhas e materiais cerâmicos',
  },
  {
    value: '170106(*)',
    label:
      'Misturas ou frações separadas de cimento, tijolos, ladrilhos, telhas e materiais cerâmicos contendo substancias perigosas',
  },
  {
    value: '170201',
    label: 'Madeira ',
  },
  {
    value: '170202',
    label: 'Vidro',
  },
  {
    value: '170203',
    label: 'Plástico',
  },
  {
    value: '170204(*)',
    label: 'Vidro, plástico e madeira, misturados ou não, contendo ou contaminados com substâncias perigosas',
  },
  {
    value: '170301(*)',
    label: 'Misturas betuminosas contendo alcatrão',
  },
  {
    value: '170303(*)',
    label: 'Asfalto e produtos de alcatrão',
  },
  {
    value: '170401',
    label: 'Cobre, bronze e latão',
  },
  {
    value: '170402',
    label: 'Alumínio',
  },
  {
    value: '170403',
    label: 'Chumbo',
  },
  {
    value: '170404',
    label: 'Zinco',
  },
  {
    value: '170405',
    label: 'Ferro e aço',
  },
  {
    value: '170406',
    label: 'Estanho',
  },
  {
    value: '170407',
    label: 'Mistura de sucatas',
  },
  {
    value: '170409(*)',
    label: 'Resíduos metálicos contaminados com substâncias perigosas',
  },
  {
    value: '170410(*)',
    label: 'Cabos contendo hidrocarbonetos, alcatrão ou outras substâncias perigosas',
  },
  {
    value: '170412',
    label: 'Magnésio',
  },
  {
    value: '170413',
    label: 'Níquel',
  },
  {
    value: '170503(*)',
    label: 'Solos e rochas contendo outras substâncias perigosas',
  },
  {
    value: '170505(*)',
    label: 'Lodos de dragagem contendo substâncias perigosas',
  },
  {
    value: '170507(*)',
    label: 'Britas de linhas ferroviárias contendo substâncias perigosas',
  },
  {
    value: '170509(*)',
    label:
      'Resíduos resultantes da incineração ou tratamento de solos contaminados por substâncias orgânicas perigosas',
  },
  {
    value: '170601(*)',
    label: 'Materiais de isolamento contendo amianto',
  },
  {
    value: '170603(*)',
    label: 'Outros materiais de isolamento contendo ou constituídos por substâncias perigosas',
  },
  {
    value: '170605(*)',
    label: 'Materiais de construção contendo amianto (por exemplo, telhas, tubos, etc.)',
  },
  {
    value: '170801(*)',
    label: 'Materiais de construção à base de gesso contaminados com substâncias perigosas',
  },
  {
    value: '170901(*)',
    label: 'Resíduos de construção e demolição contendo mercúrio',
  },
  {
    value: '170902(*)',
    label:
      'Resíduos de construção e demolição contendo PCB (por exemplo vedantes com PCB, revestimentos de piso à base de resinas com PCB, condensadores de uso doméstico com PCB)',
  },
  {
    value: '170903(*)',
    label: 'Outros resíduos de construção e demolição (incluindo misturas de resíduos) contendo substâncias perigosas',
  },
  {
    value: '190306(*)',
    label: 'Resíduos assinalados como perigosos, solidificados',
  },
  {
    value: '190808(*)',
    label: 'Resíduos de sistema de membranas contendo metais pesados',
  },
  {
    value: '190102',
    label: 'Materiais ferrosos removidos das cinzas',
  },
  {
    value: '190105(*)',
    label: 'Tortas de filtro provenientes do tratamento de gases',
  },
  {
    value: '190106(*)',
    label: 'Resíduos líquidos aquosos provenientes do tratamento de gases e outros resíduos líquidos aquosos ',
  },
  {
    value: '190107(*)',
    label: 'Resíduos sólidos provenientes do tratamento de gases',
  },
  {
    value: '190110(*)',
    label: 'Carvão ativado usado proveniente do tratamento de gases de combustão',
  },
  {
    value: '190111(*)',
    label: 'Cinzas e escórias contendo substâncias perigosas',
  },
  {
    value: '190113(*)',
    label: 'Cinzas voláteis contendo substâncias perigosas',
  },
  {
    value: '190115(*)',
    label: 'Cinzas de caldeiras contendo substâncias perigosas',
  },
  {
    value: '190117(*)',
    label: 'Resíduos de pirólise contendo substâncias perigosas',
  },
  {
    value: '190119',
    label: 'Areias de leitos fluidizados',
  },
  {
    value: '190199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '190203',
    label: 'Misturas de resíduos contendo apenas resíduos não perigosos',
  },
  {
    value: '190204(*)',
    label: 'Misturas de resíduos contendo, pelo menos, um resíduos perigoso',
  },
  {
    value: '190205(*)',
    label: 'Lodos de tratamento físico-químico contendo substâncias perigosas',
  },
  {
    value: '190207(*)',
    label: 'Óleos e concentrados da separação',
  },
  {
    value: '190208(*)',
    label: 'Resíduos combustíveis líquidos contendo substâncias perigosas',
  },
  {
    value: '190209(*)',
    label: 'Resíduos combustíveis sólidos contendo substâncias perigosas',
  },
  {
    value: '190211(*)',
    label: 'Outros resíduos contendo substancias perigosas',
  },
  {
    value: '190299',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '190304(*)',
    label: 'Resíduos assinalados como perigosos, parcialmente estabilizados ',
  },
  {
    value: '190401',
    label: 'Resíduos vitrificados',
  },
  {
    value: '190402(*)',
    label: 'Cinzas voláteis e outros resíduos do tratamento de gases de combustão',
  },
  {
    value: '190403(*)',
    label: 'Fase sólida não vitrificada',
  },
  {
    value: '190404',
    label: 'Resíduos líquidos aquosos da tempera de resíduos vitrificados',
  },
  {
    value: '190501',
    label: 'Fração não compostada de resíduos urbanos e equiparados',
  },
  {
    value: '190502',
    label: 'Fração não compostada de resíduos animais e vegetais',
  },
  {
    value: '190503',
    label: 'Composto fora de especificação',
  },
  {
    value: '190599',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '190603',
    label: 'Lodo do tratamento anaeróbico de resíduos urbanos e equiparados',
  },
  {
    value: '190604',
    label: 'Lamas e lodos de digestores de tratamento anaeróbico de resíduos urbanos e equiparados',
  },
  {
    value: '190605',
    label: 'Lodo de tratamento anaeróbico de resíduos animais e vegetais',
  },
  {
    value: '200137(*)',
    label: 'Madeira contendo substancias perigosas',
  },
  {
    value: '190699',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '190702(*)',
    label: 'Lixiviados ou líquidos percolados de aterros contendo substancias perigosas',
  },
  {
    value: '190801',
    label: 'Resíduos retirados da fase de gradeamento',
  },
  {
    value: '190802',
    label: 'Resíduos de desarenamento',
  },
  {
    value: '190805',
    label: 'Lodos de tratamento de efluentes urbanos',
  },
  {
    value: '190806(*)',
    label: 'Resinas de troca iônica, saturadas ou usadas',
  },
  {
    value: '190807(*)',
    label: 'Soluções e lodos da regeneração de colunas de permuta iônica',
  },
  {
    value: '190809',
    label: 'Misturas de gorduras e óleos, da separação água/óleo, contendo apenas óleos e gorduras alimentares',
  },
  {
    value: '190811(*)',
    label: 'Lodos de tratamento biológico de efluentes industriais contendo substancias perigosas',
  },
  {
    value: '190813(*)',
    label: 'Lodos de outros tratamentos de efluentes industriais contendo substancias perigosas',
  },
  {
    value: '190899',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '190901',
    label: 'Resíduos retirados da fase de gradeamento',
  },
  {
    value: '190902',
    label: 'Lodos de clarificação da água',
  },
  {
    value: '190903',
    label: 'Lodos de descarbonatação',
  },
  {
    value: '190904',
    label: 'Carvão ativado usado',
  },
  {
    value: '190905',
    label: 'Resinas de troca iônica, saturadas ou usadas',
  },
  {
    value: '190906',
    label: 'Soluções  e lodos da regeneração de colunas de troca iônica',
  },
  {
    value: '190999',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '191001',
    label: 'Resíduos de ferro ou aço',
  },
  {
    value: '191002',
    label: 'Resíduos não ferrosos',
  },
  {
    value: '191003(*)',
    label: 'Frações leves e poeiras contendo substancias perigosas',
  },
  {
    value: '191005(*)',
    label: 'Outras frações contendo substancias perigosas',
  },
  {
    value: '191101(*)',
    label: 'Argilas de filtração usadas',
  },
  {
    value: '191102(*)',
    label: 'Borras ácidas',
  },
  {
    value: '191103(*)',
    label: 'Resíduos líquidos aquosos',
  },
  {
    value: '191104(*)',
    label: 'Resíduos de limpeza de combustíveis com bases',
  },
  {
    value: '191105(*)',
    label: 'Lodos do tratamento local de efluentes contendo substancias perigosas',
  },
  {
    value: '191107(*)',
    label: 'Resíduos de limpeza de gases de combustão',
  },
  {
    value: '191199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '191201',
    label: 'Papel e cartão',
  },
  {
    value: '191202',
    label: 'Metais ferrosos',
  },
  {
    value: '191203',
    label: 'Metais não ferrosos',
  },
  {
    value: '191206(*)',
    label: 'Madeira contendo substancias perigosas',
  },
  {
    value: '191209',
    label: 'Substancias minerais (por exemplo, areia, rochas)',
  },
  {
    value: '191210',
    label: 'Resíduos combustíveis (combustíveis derivados de resíduos)',
  },
  {
    value: '191211',
    label: 'Borrachas',
  },
  {
    value: '191212(*)',
    label:
      'Outros resíduos (incluindo misturas de materiais) do tratamento mecânico de resíduos contendo substancias perigosas',
  },
  {
    value: '191301(*)',
    label: 'Resíduos da descontaminação de solos contendo substancias perigosas',
  },
  {
    value: '191303(*)',
    label: 'Lodos da descontaminação de solos contendo substancias perigosas',
  },
  {
    value: '191305(*)',
    label: 'Lodos da descontaminação de águas freáticas contendo substancias perigosas ',
  },
  {
    value: '191307(*)',
    label:
      'Resíduos líquidos aquosos e concentrados aquosos da descontaminação de águas freáticas contendo substancias perigosas',
  },
  {
    value: '200101',
    label: 'Papel e cartão',
  },
  {
    value: '200102',
    label: 'Vidro',
  },
  {
    value: '200108',
    label: 'Resíduos biodegradáveis de cozinha e cantinas',
  },
  {
    value: '200110',
    label: 'Roupas',
  },
  {
    value: '200111',
    label: 'Têxteis',
  },
  {
    value: '200113(*)',
    label: 'Solventes',
  },
  {
    value: '200114(*)',
    label: 'Ácidos',
  },
  {
    value: '200115(*)',
    label: 'Resíduos alcalinos',
  },
  {
    value: '200117(*)',
    label: 'Produtos químicos para fotografia',
  },
  {
    value: '200119(*)',
    label: 'Pesticidas',
  },
  {
    value: '200121(*)',
    label: 'Lâmpadas fluorescentes, de vapor de sódio e mercúrio e de luz mista',
  },
  {
    value: '200123(*)',
    label: 'Produtos eletroeletrônicos fora de uso contendo clorofluorcarbonetos',
  },
  {
    value: '200125',
    label: 'Óleos e gorduras vegetais alimentares',
  },
  {
    value: '200126(*)',
    label: 'Óleos e gorduras não abrangidas em 20 01 25',
  },
  {
    value: '200127(*)',
    label: 'Tintas, produtos adesivos, colas e resinas contendo substancias perigosas',
  },
  {
    value: '200129(*)',
    label: 'Detergentes contendo substancias perigosas',
  },
  {
    value: '200139',
    label: 'Plásticos',
  },
  {
    value: '200140',
    label: 'Metais',
  },
  {
    value: '200141',
    label: 'Resíduos da limpeza de chaminés',
  },
  {
    value: '200199',
    label: 'Outras frações não anteriormente especificadas',
  },
  {
    value: '200201',
    label:
      'Resíduos de varrição,  limpeza de logradouros e vias públicas e outros serviços de limpeza urbana biodegradáveis',
  },
  {
    value: '200202',
    label: 'Terras e pedras',
  },
  {
    value: '200203',
    label:
      'Outros resíduos de varrição,  limpeza de logradouros e vias públicas e outros serviços de limpeza urbana não biodegradáveis',
  },
  {
    value: '200301',
    label: 'Outros resíduos urbanos e equiparados, incluindo misturas de resíduos',
  },
  {
    value: '200302',
    label: 'Resíduos de mercados públicos e feiras',
  },
  {
    value: '200303',
    label: 'Resíduos da limpeza de ruas e de galerias de drenagem pluvial',
  },
  {
    value: '200304',
    label: 'Lodos de fossas sépticas',
  },
  {
    value: '200306',
    label: 'Resíduos de limpeza de esgotos, bueiros e bocas de lobo',
  },
  {
    value: '200399',
    label: 'Resíduos urbanos e equiparados não anteriormente especificados',
  },
  {
    value: '060904',
    label: 'Resíduos cálcicos de reação não abrangidos em 06 09 03 (*)',
  },
  {
    value: '061302(*)',
    label: 'Carvão ativado usado - exceto 06 07 02 (*)',
  },
  {
    value: '130301(*)',
    label:
      'Óleos de isolamento térmico, de refrigeração e de transmissão de calor usados, fluidos dielétricos e resíduos contaminados com bifenilas policloradas (PCB)',
  },
  {
    value: '060702(*)',
    label: 'Resíduos de carvão ativado utilizado na produção de cloro',
  },
  {
    value: '060703(*)',
    label: 'Lodos de sulfato de bário contendo mercúrio',
  },
  {
    value: '060704(*)',
    label: 'Soluções e ácidos, por exemplo, ácido de contato',
  },
  {
    value: '060705(*)',
    label:
      'Lodos de purificação de salmoura e lodos provenientes do tratamento de efluentes líquidos originados no processo de produção de cloro em células de mercúrio',
  },
  {
    value: '060799',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '060902',
    label: 'Escórias com fósforo',
  },
  {
    value: '060903(*)',
    label: 'Resíduos cálcicos de reação contendo ou contaminados com substancias perigosas',
  },
  {
    value: '060999',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '061002(*)',
    label: 'Resíduos contendo substancias perigosas',
  },
  {
    value: '061099',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '061101',
    label: 'Resíduos cálcicos de reação da produção de dióxido de titânio',
  },
  {
    value: '061102(*)',
    label:
      'Lodos provenientes do tratamento  de efluentes líquidos originados no processo de produção do pigmento branco de dióxido de titânio, por meio do método de cloretos, a partir de minérios que contenham cromo',
  },
  {
    value: '061103(*)',
    label:
      'Resíduos da fabricação e de locais de armazenamento de cloreto férrico a partir de ácidos formados durante a produção do dióxido de titânio utilizando o processo de ilmenitecloreto ',
  },
  {
    value: '061104(*)',
    label:
      'Lodo de tratamento de efluentes líquidos originados na produção dos seguintes pigmentos: laranja e amarelo de cromo, laranja de molibdato, amarelo de zinco, verde de cromo, verde de óxido de cromo,(anidro e hidratado) e azul de ferro',
  },
  {
    value: '061199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '061301(*)',
    label: 'Produtos inorgânicos de proteção das plantas, agentes de preservação da madeira e outros biocidas',
  },
  {
    value: '061303',
    label: 'Negro de fumo',
  },
  {
    value: '061304(*)',
    label: 'Resíduos de processamento do amianto, incluindo pós e fibras',
  },
  {
    value: '061305(*)',
    label: 'Fuligem',
  },
  {
    value: '061399',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '070107(*)',
    label: 'Resíduos de destilação e resíduos de reação halogenados',
  },
  {
    value: '070101(*)',
    label: 'Líquidos de lavagem e efluentes orgânicos aquosos',
  },
  {
    value: '070103(*)',
    label: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
  },
  {
    value: '070104(*)',
    label: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
  },
  {
    value: '070108(*)',
    label: 'Outros resíduos de destilação e resíduos de reação',
  },
  {
    value: '070109(*)',
    label: 'Absorventes usados e tortas de filtro halogenados',
  },
  {
    value: '070110(*)',
    label: 'Outros absorventes usados e tortas de filtro',
  },
  {
    value: '070111(*)',
    label: 'Lodos de tratamento de efluentes contendo substancias perigosas',
  },
  {
    value: '070199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '070201(*)',
    label: 'Líquidos de lavagem e efluentes orgânicos aquosos',
  },
  {
    value: '070203(*)',
    label: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
  },
  {
    value: '070204(*)',
    label: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
  },
  {
    value: '070207(*)',
    label: 'Resíduos de destilação e resíduos de reação halogenados',
  },
  {
    value: '070208(*)',
    label: 'Outros resíduos de destilação e resíduos de reação',
  },
  {
    value: '070209(*)',
    label: 'Absorventes usados e tortas de filtro halogenados',
  },
  {
    value: '070210(*)',
    label: 'Outros absorventes usados e tortas de filtro',
  },
  {
    value: '070211(*)',
    label: 'Lodos de tratamento de efluentes contendo substancias perigosas',
  },
  {
    value: '070213',
    label: 'Resíduos e refugos de plásticos',
  },
  {
    value: '070214(*)',
    label: 'Resíduos de aditivos contendo substâncias perigosas',
  },
  {
    value: '070216(*)',
    label: 'Resíduos contendo silicones perigosos',
  },
  {
    value: '070112',
    label: 'Lodos do tratamento local de efluentes não abrangidas em 07 01 11 (*)',
  },
  {
    value: '070212',
    label: 'Lodos do tratamento local de efluentes não abrangidas em 07 02 11 (*)',
  },
  {
    value: '060404(*)',
    label: 'Resíduos contendo mercúrio',
  },
  {
    value: '070299',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '070301(*)',
    label: 'Líquidos de lavagem e efluentes orgânicos aquosos',
  },
  {
    value: '070303(*)',
    label: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
  },
  {
    value: '070304(*)',
    label: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
  },
  {
    value: '070307(*)',
    label: 'Resíduos de destilação e resíduos de reação halogenados',
  },
  {
    value: '070308(*)',
    label: 'Outros resíduos de destilação e resíduos de reação',
  },
  {
    value: '070309(*)',
    label: 'Absorventes usados e tortas de filtro halogenados',
  },
  {
    value: '070310(*)',
    label: 'Outros absorventes usados e tortas de filtro',
  },
  {
    value: '070311(*)',
    label: 'Lodos de tratamento de efluentes contendo substancias perigosas',
  },
  {
    value: '070399',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '070401(*)',
    label: 'Líquidos de lavagem e efluentes orgânicos aquosos',
  },
  {
    value: '070403(*)',
    label: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
  },
  {
    value: '070404(*)',
    label: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
  },
  {
    value: '070407(*)',
    label: 'Resíduos de destilação e resíduos de reação halogenados',
  },
  {
    value: '070408(*)',
    label: 'Outros resíduos de destilação e resíduos de reação',
  },
  {
    value: '070409(*)',
    label: 'Absorventes usados e tortas de filtro halogenados',
  },
  {
    value: '070410(*)',
    label: 'Outros absorventes usados e tortas de filtro',
  },
  {
    value: '070411(*)',
    label: 'Lodos de tratamento de efluentes contendo substancias perigosas',
  },
  {
    value: '070413(*)',
    label: 'Resíduos contendo substancias perigosas',
  },
  {
    value: '070499',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '070501(*)',
    label: 'Líquidos de lavagem e efluentes orgânicos aquosos',
  },
  {
    value: '070503(*)',
    label: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
  },
  {
    value: '070504(*)',
    label: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
  },
  {
    value: '070507(*)',
    label: 'Resíduos de destilação e resíduos de reação halogenados',
  },
  {
    value: '070508(*)',
    label: 'Outros resíduos de destilação e resíduos de reação',
  },
  {
    value: '070509(*)',
    label:
      'Carvão ativo usado proveniente da etapa de descoloração da produção de compostos arseniacais ou organoarseniacais',
  },
  {
    value: '070510(*)',
    label: 'Absorventes usados e tortas de filtro halogenados e não halogenados',
  },
  {
    value: '070511(*)',
    label: 'Lodos de tratamento de efluentes contendo substancias perigosas',
  },
  {
    value: '070513(*)',
    label: 'Resíduos contendo substancias perigosas',
  },
  {
    value: '070599',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '070505(*)',
    label:
      'Lodos provenientes do tratamento de efluentes líquidos originados no processo de produção de compostos arsênicas ou organoarseniacais',
  },
  {
    value: '070506(*)',
    label:
      'Resíduos de fundo de destilação originados na etapa de destilação de compostos anilínicos empregados na produção de compostos arseniacais  ou compostos organoarseniacais',
  },
  {
    value: '070699',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '070601(*)',
    label: 'Líquidos de lavagem e efluentes orgânicos aquosos',
  },
  {
    value: '070603(*)',
    label: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
  },
  {
    value: '070604(*)',
    label: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
  },
  {
    value: '070607(*)',
    label: 'Resíduos de destilação e resíduos de reação halogenados',
  },
  {
    value: '070608(*)',
    label: 'Outros resíduos de destilação e resíduos de reação',
  },
  {
    value: '070609(*)',
    label: 'Absorventes usados e tortas de filtro halogenados e halogenados',
  },
  {
    value: '070610(*)',
    label: 'Outros absorventes usados e tortas de filtro',
  },
  {
    value: '070611(*)',
    label: 'Lodos de tratamento de efluentes contendo substancias perigosas',
  },
  {
    value: '070799',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '070701(*)',
    label: 'Líquidos de lavagem e efluentes orgânicos aquosos',
  },
  {
    value: '070703(*)',
    label: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
  },
  {
    value: '070704(*)',
    label: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
  },
  {
    value: '070707(*)',
    label: 'Resíduos de destilação e resíduos de reação halogenados',
  },
  {
    value: '070708(*)',
    label: 'Outros resíduos de destilação e resíduos de reação',
  },
  {
    value: '070709(*)',
    label: 'Absorventes usados e tortas de filtro halogenados e halogenados',
  },
  {
    value: '070710(*)',
    label: 'Outros absorventes usados e tortas de filtro',
  },
  {
    value: '070711(*)',
    label: 'Lodos de tratamento de efluentes contendo substancias perigosas',
  },
  {
    value: '080111(*)',
    label: 'Resíduos de tintas e vernizes contendo solventes orgânicos ou outras substancias perigosas',
  },
  {
    value: '080113(*)',
    label: 'Lodos de tintas e vernizes contendo solventes orgânicos ou outras substancias perigosas',
  },
  {
    value: '080115(*)',
    label: 'Lodos aquosas contendo tintas e vernizes orgânicos ou outras substancias perigosas',
  },
  {
    value: '080119(*)',
    label: 'Suspensões aquosas contendo tintas ou vernizes com solventes orgânicos ou outras substancias perigosas',
  },
  {
    value: '080121(*)',
    label: 'Resíduos de produtos de remoção de tintas e vernizes',
  },
  {
    value: '080122(*)',
    label: 'Lodos ou poeiras provenientes do sistema de controle de emissão de gases empregado na produção de tintas',
  },
  {
    value: '080199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '080117(*)',
    label: 'Resíduos da remoção de tintas e vernizes contendo solventes orgânicos ou outras substancias perigosas',
  },
  {
    value: '080201',
    label: 'Resíduos de revestimentos na forma pulvurulenta',
  },
  {
    value: '080202',
    label: 'Lodos aquosas contendo materiais cerâmicos',
  },
  {
    value: '080203',
    label: 'Suspensões aquosas contendo materiais cerâmicos',
  },
  {
    value: '080204(*)',
    label: 'Resíduos de revestimentos contendo amianto',
  },
  {
    value: '080299',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '080307',
    label: 'Lodos aquosas contendo tintas de impressão',
  },
  {
    value: '080308',
    label: 'Resíduos líquidos aquosos contendo tintas de impressão',
  },
  {
    value: '080312(*)',
    label: 'Resíduos de tintas de impressão contendo substancias perigosas',
  },
  {
    value: '080314(*)',
    label: 'Lodos de tintas contendo substancias perigosas',
  },
  {
    value: '080316',
    label: 'Resíduos de solução de água régia',
  },
  {
    value: '080317(*)',
    label: 'Resíduos de toner de impressão contendo substancias perigosas',
  },
  {
    value: '080319(*)',
    label: 'Óleos de dispersão',
  },
  {
    value: '080399',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '080409(*)',
    label: 'Resíduos de colas ou vedantes contendo solventes orgânicos ou outras substancias perigosas',
  },
  {
    value: '080411(*)',
    label: 'Lodos de colas ou vedantes contendo solventes orgânicos ou outras substancias perigosas',
  },
  {
    value: '080413(*)',
    label: 'Lodos aquosos contendo colas ou vedantes com solventes orgânicos ou outras substancias perigosas',
  },
  {
    value: '080415(*)',
    label: 'Resíduos líquidos aquosos contendo colas ou vedantes com solventes orgânicos ou substancias perigosas',
  },
  {
    value: '080417(*)',
    label: 'Óleo de resina',
  },
  {
    value: '080499',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '100201',
    label: 'Escória e outros desperdícios da fabricação do ferro e do aço',
  },
  {
    value: '100202',
    label: 'Escórias de alto-fornos granulada (areia de escória) provenientes da fabricação de ferro e do aço',
  },
  {
    value: '100203(*)',
    label:
      'Lodos ou poeiras provenientes do sistema de controle de emissão da gases na produção de aço primário em fornos elétricos',
  },
  {
    value: '100207(*)',
    label: 'Resíduos sólidos do tratamento de gases contendo substancias perigosas',
  },
  {
    value: '100210',
    label: 'Escamas de laminagem',
  },
  {
    value: '100211(*)',
    label: 'Resíduos do tratamento de agua de arrefecimento contendo hidrocarbonetos',
  },
  {
    value: '100213(*)',
    label: 'Lodos e tortas de filtro de tratamento de gases contendo substancias perigosas',
  },
  {
    value: '100215',
    label: 'Outros lodos e tortas de filtro',
  },
  {
    value: '100216(*)',
    label:
      'Poeiras provenientes do sistema de controle de emissão de gases empregado nos fornos Cubilot empregados na fundição de ferro',
  },
  {
    value: '100299',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '100302',
    label: 'Resíduos de ânodos',
  },
  {
    value: '100303(*)',
    label: 'Cátodos usados provenientes da redução de alumínio primário',
  },
  {
    value: '100304(*)',
    label: 'Escórias da produção primária',
  },
  {
    value: '100305(*)',
    label: 'Resíduos provenientes do desmonte das cubas de redução empregadas na produção de alumínio primário',
  },
  {
    value: '100306',
    label: 'Resíduos de alumina',
  },
  {
    value: '100308(*)',
    label: 'Escórias salinas da produção secundária',
  },
  {
    value: '100309(*)',
    label: 'Impurezas negras da produção secundária',
  },
  {
    value: '100315(*)',
    label: 'Escumas inflamáveis ou que, em contato com a água, libertam gases inflamáveis em quantidades perigosas',
  },
  {
    value: '100317(*)',
    label: 'Resíduos da fabricação de ânodos contendo alcatrão',
  },
  {
    value: '100319(*)',
    label: 'Poeiras de gases de combustão contendo substancias perigosas',
  },
  {
    value: '100321(*)',
    label: 'Outras partículas e poeiras (incluindo poeiras da trituração de escórias) contendo substâncias perigosas',
  },
  {
    value: '100323(*)',
    label: 'Resíduos sólidos do tratamento de gases contendo substancias perigosas',
  },
  {
    value: '100325(*)',
    label: 'Lodos e tortas de filtro do tratamento de gases contendo substancias perigosas',
  },
  {
    value: '100327(*)',
    label: 'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos',
  },
  {
    value: '100329(*)',
    label:
      'Resíduos do tratamento de escórias salinas e do tratamento das impurezas negras contendo substancias perigosas',
  },
  {
    value: '100399',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '100401(*)',
    label: 'Escórias da produção primária e secundária',
  },
  {
    value: '100402(*)',
    label: 'Impurezas e escumas da produção primária e secundária',
  },
  {
    value: '100403(*)',
    label: 'Arseniato de cálcio',
  },
  {
    value: '100404(*)',
    label:
      'Lodos, lixivias ou poeiras provenientes de sistema de controle de emissão de gases empregado na produção primária e secundária do chumbo',
  },
  {
    value: '100405(*)',
    label: 'Outras partículas e poeiras',
  },
  {
    value: '100407(*)',
    label: 'Lodos e tortas de filtro do tratamento de gases',
  },
  {
    value: '100409(*)',
    label: 'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos',
  },
  {
    value: '100316',
    label: 'Escumas não abrangidas em 10 03 15 (*)',
  },
  {
    value: '100499',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '100406(*)',
    label: 'Resíduos sólidos do tratamento de gases',
  },
  {
    value: '100501',
    label: 'Escórias da produção primária e secundária',
  },
  {
    value: '100502(*)',
    label: 'Lodos calcários de anodos eletrolíticos originados na produção de zinco primário',
  },
  {
    value: '100503(*)',
    label: 'Poeiras de gases de combustão',
  },
  {
    value: '100504',
    label: 'Outras partículas e poeiras não perigosas',
  },
  {
    value: '100505(*)',
    label: 'Resíduos sólidos do tratamento de gases',
  },
  {
    value: '100506(*)',
    label: 'Lodos e tortas de filtro do tratamento de gases',
  },
  {
    value: '100507(*)',
    label: 'Resíduos provenientes da unidade cádmio (óxido de ferro) do processo de produção de zinco primário',
  },
  {
    value: '100508(*)',
    label: 'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos',
  },
  {
    value: '100510(*)',
    label:
      'Impurezas e escumas inflamáveis ou que, em contato com a água, libertam gases inflamáveis em quantidades perigosas',
  },
  {
    value: '100599',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '100601',
    label: 'Escórias da produção primária e secundária',
  },
  {
    value: '100602',
    label: 'Impurezas e escumas da produção primária e secundária',
  },
  {
    value: '100603(*)',
    label: 'Poeiras de gases de combustão',
  },
  {
    value: '100604',
    label: 'Outras partículas e poeiras não perigosas',
  },
  {
    value: '100606(*)',
    label: 'Resíduos sólidos do tratamento de gases',
  },
  {
    value: '100607(*)',
    label: 'Lodos e tortas de filtro do tratamento de gases',
  },
  {
    value: '100609(*)',
    label:
      'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos , incluindo lamas e lodos do adensamento da purga ácida do processo de produção de cobre primário',
  },
  {
    value: '100699',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '100701',
    label: 'Escórias da produção primária e secundária',
  },
  {
    value: '100702',
    label: 'Impurezas e escumas da produção primária e secundária',
  },
  {
    value: '100703',
    label: 'Resíduos sólidos do tratamento de gases',
  },
  {
    value: '100704',
    label: 'Outras partículas e poeiras não perigosas',
  },
  {
    value: '100705',
    label: 'Lodos e tortas de filtro do tratamento de gases',
  },
  {
    value: '100707(*)',
    label: 'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos',
  },
  {
    value: '100799',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '100804',
    label: 'Partículas e poeiras não perigosas',
  },
  {
    value: '100808(*)',
    label: 'Escórias salinas da produção primária e secundária',
  },
  {
    value: '100810(*)',
    label:
      'Impurezas e escumas inflamáveis ou que, em contato com a água, libertam gases inflamáveis em quantidades perigosas',
  },
  {
    value: '100812(*)',
    label: 'Resíduos da fabricação de anodos contendo alcatrão',
  },
  {
    value: '100814',
    label: 'Resíduos de anodos',
  },
  {
    value: '100815(*)',
    label: 'Poeiras da gases de combustão contendo substancias perigosas',
  },
  {
    value: '100817(*)',
    label: 'Lodos e tortas de filtro do tratamento de gases de combustão contendo substancias perigosas ',
  },
  {
    value: '100819(*)',
    label: 'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos',
  },
  {
    value: '100899',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '100903',
    label: 'Escórias do forno',
  },
  {
    value: '100905(*)',
    label: 'Moldes e modelos e moldes de fundição não vazados contendo substancias perigosas',
  },
  {
    value: '100907(*)',
    label: 'Moldes e modelos e moldes de fundição vazados contendo substancias perigosas',
  },
  {
    value: '100909(*)',
    label: 'Poeiras de gases de combustão contendo substancias perigosas',
  },
  {
    value: '100911(*)',
    label: 'Outras partículas contendo substancias perigosas',
  },
  {
    value: '100913(*)',
    label: 'Resíduos de aglutinantes contendo substâncias perigosas',
  },
  {
    value: '100915(*)',
    label: 'Resíduos de agentes indicadores de fendas e trincas contendo substancias perigosas',
  },
  {
    value: '100999',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '101003',
    label: 'Escórias do forno',
  },
  {
    value: '101005(*)',
    label: 'Moldes e modelos e moldes de fundição não vazados contendo substancias perigosas',
  },
  {
    value: '101007(*)',
    label: 'Moldes e modelos e moldes de fundição vazados contendo substancias perigosas',
  },
  {
    value: '101009(*)',
    label: 'Poeiras de gases de combustão contendo substancias perigosas',
  },
  {
    value: '101011(*)',
    label: 'Outras partículas contendo substancias perigosas',
  },
  {
    value: '101013(*)',
    label: 'Resíduos de aglutinantes contendo substancias perigosas',
  },
  {
    value: '101015(*)',
    label: 'Resíduos de agentes indicadores de fendas e trincas contendo substancias perigosas',
  },
  {
    value: '120112(*)',
    label: 'Ceras e gorduras usadas',
  },
  {
    value: '101099',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '101103',
    label: 'Resíduos de materiais fibrosos à base de vidro',
  },
  {
    value: '101105',
    label: 'Partículas e poeiras',
  },
  {
    value: '101109(*)',
    label: 'Resíduos da preparação da mistura (antes do processo térmico) contendo substancias perigosas',
  },
  {
    value: '101111(*)',
    label:
      'Resíduos de vidro em pequenas partículas e em pó de vidro contendo metais pesados (por exemplo, tubos catódicos)',
  },
  {
    value: '101113(*)',
    label: 'Lodos de polimento e retificação de vidro contendo substancias perigosas',
  },
  {
    value: '101115(*)',
    label: 'Resíduos sólidos de tratamento de gases de combustão contendo substancias perigosas',
  },
  {
    value: '101117(*)',
    label: 'Lodos e tortas de filtro do tratamento de gases de combustão contendo substancias perigosas ',
  },
  {
    value: '101119(*)',
    label: 'Resíduos sólidos do tratamento local de efluentes contendo substancias perigosas',
  },
  {
    value: '101199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '101201',
    label: 'Resíduos da preparação da mistura (antes do tratamento térmico)',
  },
  {
    value: '101203',
    label: 'Partículas e poeiras',
  },
  {
    value: '101205',
    label: 'Lodos e tortas de filtro do tratamento de gases',
  },
  {
    value: '101206',
    label: 'Moldes fora de uso',
  },
  {
    value: '101208',
    label:
      'Resíduos de fabricação de peças cerâmicas, tijolos, ladrilhos, telhas e produtos de construção (após o processo térmico)',
  },
  {
    value: '101209(*)',
    label: 'Resíduos sólidos do tratamento de gases contendo substancias perigosas',
  },
  {
    value: '101211(*)',
    label: 'Resíduos de vitrificação contendo metais pesados',
  },
  {
    value: '101213',
    label: 'Lodos de tratamento local de efluentes',
  },
  {
    value: '101299',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '101301',
    label: 'Resíduos de preparação da mistura antes do processo térmico',
  },
  {
    value: '101304',
    label: 'Resíduos da calcinação e hidratação da cal',
  },
  {
    value: '101307',
    label: 'Lodos e tortas de filtro do tratamento de gases',
  },
  {
    value: '101309(*)',
    label: 'Resíduos da fabricação de fibrocimento contendo amianto',
  },
  {
    value: '101312(*)',
    label: 'Resíduos sólidos do tratamento de gases contendo substancias perigosas',
  },
  {
    value: '101314',
    label: 'Resíduos de cimento e de lodos de cimento',
  },
  {
    value: '101399',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '110104(*)',
    label: 'Banho de decapagem exaurido proveniente das operações de acabamento do aço',
  },
  {
    value: '110105(*)',
    label: 'Ácidos de decapagem',
  },
  {
    value: '110106(*)',
    label: 'Ácidos não anteriormente especificados',
  },
  {
    value: '110107(*)',
    label: 'Bases de decapagem',
  },
  {
    value: '110108(*)',
    label: 'Lodos de fosfatização',
  },
  {
    value: '110109(*)',
    label: 'Lodos e tortas de filtro contendo substancias perigosas',
  },
  {
    value: '110111(*)',
    label: 'Soluções exauridas, lodos e líquidos de lavagem aquosos contendo cianeto e/ou outras substancias perigosas',
  },
  {
    value: '110113(*)',
    label: 'Resíduos de desengorduramento contendo substancias perigosas',
  },
  {
    value: '110115(*)',
    label: 'Eluatos e lodos de sistema de membranas ou de permuta iônica contendo substancias perigosas',
  },
  {
    value: '110116(*)',
    label: 'Resinas de permuta iônica saturadas ou usadas',
  },
  {
    value: '110198(*)',
    label: 'Outros resíduos contendo substancias perigosas',
  },
  {
    value: '110199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '110202(*)',
    label: 'Lodos da hidrometalurgia do zinco',
  },
  {
    value: '110203',
    label: 'Resíduos da produção de ânodos dos processos eletrolíticos aquosos',
  },
  {
    value: '110205(*)',
    label: 'Resíduos de processos hidrometalurgicos do cobre contendo substancias perigosas',
  },
  {
    value: '110207(*)',
    label: 'Outros resíduos contendo substancias perigosas ',
  },
  {
    value: '110299',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '110301(*)',
    label: 'Resíduos contendo cianetos',
  },
  {
    value: '110302(*)',
    label: 'Outros resíduos',
  },
  {
    value: '110303(*)',
    label:
      'Lodos originados no tratamento de efluentes líquidos provenientes dos banhos de têmpera das operações de tratamento térmico de metais nos quais são utilizados cianetos',
  },
  {
    value: '120101',
    label: 'Aparas e limalhas de metais ferrosos',
  },
  {
    value: '120102',
    label: 'Poeiras e partículas de metais ferrosos',
  },
  {
    value: '120103',
    label: 'Aparas e limalhas de metais não ferrosos',
  },
  {
    value: '120104',
    label: 'Poeiras e partículas de metais não ferrosos',
  },
  {
    value: '120105',
    label: 'Aparas de matérias plásticas',
  },
  {
    value: '120106(*)',
    label: 'Óleos minerais de corte e usinagem com halogênios (exceto emulsões, misturas e soluções)',
  },
  {
    value: '120107(*)',
    label: 'Óleos minerais de corte e usinagem sem halogênios (exceto emulsões, misturas e soluções)',
  },
  {
    value: '120108(*)',
    label: 'Emulsões, misturas e soluções de corte e usinagem com halogênios',
  },
  {
    value: '120109(*)',
    label: 'Emulsões, misturas e soluções de corte e usinagem sem halogênios',
  },
  {
    value: '120110(*)',
    label: 'Óleos sintéticos de corte e usinagem',
  },
  {
    value: '120114(*)',
    label: 'Lodos da usinagem contendo substancias perigosas',
  },
  {
    value: '120116(*)',
    label: 'Resíduos de materiais de polimento contendo substancias perigosas',
  },
  {
    value: '120118(*)',
    label: 'Lodos metálicos (lodos de retificação, superacabamento e lixamento) contendo óleo',
  },
  {
    value: '120119(*)',
    label: 'Óleos de usinagem facilmente biodegradáveis',
  },
  {
    value: '120120(*)',
    label: 'Mós e materiais de retificação usados contendo substâncias perigosas',
  },
  {
    value: '120199',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '130101(*)',
    label: 'Óleos hidraúlicos contendo PCB (1)',
  },
  {
    value: '130104(*)',
    label: 'Emulsões coloidais ',
  },
  {
    value: '130105(*)',
    label: 'Emulsões não coloidais',
  },
  {
    value: '130109(*)',
    label: 'Óleos hidráulicos minerais clorados',
  },
  {
    value: '130110(*)',
    label: 'Óleos hidráulicos minerais não clorados',
  },
  {
    value: '130111(*)',
    label: 'Óleos hidráulicos sintéticos',
  },
  {
    value: '130112(*)',
    label: 'Óleos hidráulicos facilmente biodegradáveis',
  },
  {
    value: '130113(*)',
    label: 'Outros óleos hidráulicos',
  },
  {
    value: '120115',
    label: 'Lodos de usinagem não abrangidas em 10 01 14 (*)',
  },
  {
    value: '120117',
    label: 'Resíduos de materiais de polimento não abrangidos em 12 01 16 (*)',
  },
  {
    value: '120121',
    label: 'Mós e materiais de retificação usados não abrangidos em 12 01 20 (*)',
  },
  {
    value: '120301(*)',
    label: 'Líquidos de lavagem aquosos',
  },
  {
    value: '010103',
    label: 'Rejeito do beneficiamento do minério',
  },
  {
    value: '010104',
    label: 'Estéril',
  },
  {
    value: '010105',
    label: 'Outros resíduos não anteriormente especificados',
  },
  {
    value: '180401(*)',
    label:
      'Materiais perfurocortantes ou escarificantes, tais como: lâminas de barbear, agulhas, escalpes, ampolas de vidro, brocas, limas endodônticas, pontas diamantadas, lâminas de bisturi, lancetas, tubos capilares; micropipetas; lâminas e lamínulas; espátulas; e todos os utensílios de vidro quebrados no laboratório (pipetas, tubos de coleta sanguínea e placas de Petri) e outras similares (E conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180301(*)',
    label:
      'Materiais resultantes de laboratórios de pesquisa e ensino na área de saúde, laboratórios de análises clínicas e serviços de medicina nuclear e radioterapia que contenha radionuclídeos em quantidade superior aos limites de eliminação - CNEN-6.05 (C conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180205(*)',
    label: 'Outros produtos considerados perigosos (B conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180204(*)',
    label: 'Efluentes dos equipamentos automatizados utilizados em análises clínicas (B conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180203(*)',
    label: 'Efluentes de processadores de imagem (reveladores e fixadores) (B conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180202(*)',
    label:
      'Resíduos de saneantes, desinfetantes, desinfestantes; resíduos contendo metais pesados; reagentes para laboratório, inclusive os recipientes contaminados por estes (B conforme ANVISA RDC 222/2018)',
  },
  {
    value: '200131(*)',
    label: 'Medicamentos citotóxicos e citostáticos',
  },
  {
    value: '200132',
    label: 'Medicamentos não abrangidos em 20 01 31 (*)',
  },
  {
    value: 'Grupo B',
    label:
      'Resíduos de Serviços de Saúde classificados como Grupo B, conforme ANVISA RDC 222/2018 - Contempla os resíduos códigos 180201(*), 180202(*), 180203(*), 180204(*) e 180205(*) conforme IBAMA 13/2012',
  },
  {
    value: '180201(*)',
    label:
      'Produtos hormonais e produtos antimicrobianos; citostáticos; antineoplásicos - imunossupressores; digitálicos; imunomoduladores; anti-retrovirais, quando descartados por serviço de saúde, farmácias, drogarias e distribuidores de medicamentos ou apreendidos e os resíduos e insumos farmacêuticos dos medicamentos sujeitos a controle especial (B conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180115(*)',
    label:
      'Órgãos, tecidos, fluidos orgânicos, materiais perfurocortantes ou escarificantes e demais materiais resultantes da atenção à saúde de indivíduos ou animais, com suspeita ou certeza de contaminação com príons (A5 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180114(*)',
    label: 'Bolsa transfusionais vazias ou com volume residual pós-transfusão (A4 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180113(*)',
    label:
      'Carcaças, peças anatômicas, vísceras e outros resíduos provenientes de animais não submetidos a processos de experimentação com inoculação de micro-organismos, bem como suas forrações (A4 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180112(*)',
    label:
      'Peças anatômicas (órgãos e tecidos) e outros resíduos provenientes de procedimentos cirúrgicos ou de estudo anátomo-patológicos ou de confirmação diagnóstica (A4 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180111(*)',
    label:
      'Recipientes e materiais resultantes do processo de assistência à saúde, que não contenha sangue ou líquidos corpóreos na forma livre (A4 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180110(*)',
    label:
      'Resíduos de tecido adiposo proveniente de lipoaspiração, lipoescultura ou outro procedimento de cirurgia plástica que gere este tipo de resíduo (A4 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180109(*)',
    label:
      'Sobras de amostras de laboratório e seus recipientes contendo fezes, urina e secreções, provenientes de pacientes que não contenham e nem sejam suspeitos de conter agentes com elevado risco individual e elevado risco à comunidade, e nem apresentem relevância epidemiológica  e risco de disseminação, ou micro-organismo causador de doença emergente que se torne epidemiologicamente importante ou cujo mecanismo de transmissão seja desconhecido ou com suspeita de contaminação com príons (A4 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180108(*)',
    label:
      'Filtros de ar e gases de área contaminada; membrana filtrante de equipamento médico hospitalar e de pesquisa, entre outros similares (A4 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180107(*)',
    label: 'Kits de linhas arteriais, endovenosas e dialisadores, quando descartados (A4 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180106(*)',
    label:
      'Peças anatômicas (membros) do ser humano; produto de fecundação sem sinais vitais, com peso menor que 500 gramas ou estatura menor que 25 cm ou idade gestacional menor que 20 semanas , que não tenham valor científico ou legal e não tenha havido requisição pelo paciente ou familiares  (A3 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180105(*)',
    label:
      'Carcaças, peças anatômicas, vísceras e outros resíduos provenientes de animais submetidos a processos de experimentação com inoculação de micro-organismos, bem como suas forrações, e os cadáveres de animais suspeitos de serem portadores de micro-organismos  de relevância epidemiológica e com risco de disseminação, que foram submetidos ou não a estudo anatomopatológico ou confirmação diagnóstica (A2 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180104(*)',
    label:
      'Sobras de amostras de laboratório contendo sangue ou líquidos corpóreos, recipientes e materiais resultantes do processo de assistência à saúde, contendo sangue ou líquidos corpóreos na forma livre (A1 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180103(*)',
    label:
      'Bolsa transfusionais contendo sangue ou hemocomponentes rejeitadas por contaminação ou por má conservação ou com prazo de validade vencido, e aquelas oriundas de coleta incompleta (A1 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180102(*)',
    label:
      'Resíduos resultantes da atenção da saúde de indivíduos ou animais, com suspeita ou certeza de contaminação biológica por agentes com elevado risco individual e elevado risco para a comunidade, micro-organismos com relevância epidemiológica e risco de disseminação ou causador de doença emergente que se torne epidemiologicamente importante ou cujo mecanismo de transmissão seja desconhecido (A1 conforme ANVISA RDC 222/2018)',
  },
  {
    value: '180101(*)',
    label:
      'Culturas e estoques de micro-organismos; resíduos de fabricação de produtos biológicos, exceto os hemoderivados; descarte de vacinas de micro-organismos vivos ou atenuados; meios de cultura e instrumentos utilizados para transferência, inoculação ou mistura de culturas; resíduos de laboratório de manipulação genética  (A1 conforme ANVISA RDC 222/2018)',
  },
  {
    value: 'Classe A',
    label:
      'Resíduos reutilizáveis ou recicláveis como agregados, tais como: a) de construção, demolição, reformas e reparos de pavimentação e de outras obras de infraestrutura, inclusive solos provenientes de terraplanagem; b) de construção, demolição, reformas e reparos de edificações: componentes cerâmicos (tijolos, blocos, telhas, placas de revestimento etc.), argamassa e concreto; c) de processo de fabricação e/ou demolição de peças pré-moldadas em concreto (blocos, tubos, meio-fios etc.) produzidas nos canteiros de obras. Contempla os resíduos códigos 17 01 01,17 01 02, 17 01 03, 17 01 07, 17 03 02, 17 05 04, 17 05 06, 17 05 08, 17 06 04 e 17 09 04 conforme IBAMA 13/2012.',
  },
  {
    value: 'Subgrupo A1',
    label:
      'Resíduos de Serviços de Saúde classificados como Subgrupo A1, conforme ANVISA RDC 222/18 - Contempla os resíduos códigos 180101(*), 180102(*),180103(*) e 180104(*) conforme IBAMA 13/2012',
  },
  {
    value: 'Classe B',
    label:
      'Resíduos recicláveis para outras destinações, tais como plásticos, papel, papelão, metais, vidros, madeiras, embalagens vazias de tintas imobiliárias e gesso. Contempla os resíduos códigos 17 02 01, 17 02 02, 17 02 03, 17 04 01, 17 04 02, 17 04 03, 17 04 04, 17 04 05, 17 04 06, 17 04 07, 17 04 11, 17 04 12, 17 04 13 e 17 08 02 coforme IBAMA 13/2012.',
  },
  {
    value: 'Classe C',
    label:
      'Resíduos para os quais não foram desenvolvidas tecnologias ou aplicações economicamente viáveis que permitam a sua reciclagem ou recuperação',
  },
  {
    value: '160216',
    label: 'Componentes retirados de equipamento fora de uso não abrangidos em 16 02 15 (*)',
  },
  {
    value: '160604',
    label: 'Pilhas alcalinas - exceto 16 06 03 (*)',
  },
  {
    value: '160130',
    label: 'Pneus inservíveis/usados destinados para Sistema de Logística Reversa formalmente instituído.',
  },
  {
    value: 'Classe D*',
    label:
      'Resíduos perigosos oriundos do processo de construção, tais como tintas, solventes, óleos e outros ou aqueles contaminados ou prejudiciais à saúde oriundos de demolições, reformas e reparos de clínicas radiológicas, instalações industriais e outros, bem como telhas e demais objetos e materiais que contenham amianto ou outros produtos nocivos à saúde. Contempla os resíduos códigos 17 01 06, 17 02 04, 17 03 01, 17 03 03, 17 04 09, 17 04 10, 17 05 02, 17 05 03, 17 05 05, 17 05 07, 17 05 09, 17 06 01, 17 06 03, 17 06 05, 17 08 01, 17 09 01, 17 09 02 e 17 09 03 conforme IBAMA 13/2012.',
  },
  {
    value: '150112(*)',
    label: 'Embalagens vazias de óleo lubrificante destinadas para Sistema de Logística Reversa formalmente instituído',
  },
  {
    value: 'Grupo A',
    label:
      'Resíduos de Serviços de Saúde classificados como Grupos A1, A2, A3, A4 ou A5, conforme ANVISA RDC 222/2018 - Contempla os resíduos códigos 180101(*), 180102(*), 180103(*), 180104(*), 180105(*),  180106(*), 180107(*), 180108(*), 180109(*), 180110(*), 180111(*), 180112(*), 180113(*), 180114(*) e 180115(*) conforme IBAMA 13/2012',
  },
  {
    value: 'Grupo C',
    label:
      'Materiais resultantes de laboratórios de pesquisa e ensino na área de saúde, laboratórios de análises clínicas e serviços de medicina nuclear e radioterapia que contenha radionuclídeos em quantidade superior  aos limites de eliminação (CNEN-6.05), conforme ANVISA RDC 222/2018 - Contempla o resíduo código 180301(*) conforme IBAMA 13/2012',
  },
  {
    value: 'Grupo E',
    label:
      'Materiais perfurocortantes ou escarificantes, tais como: lâminas de barbear, agulhas, escalpes, ampolas de vidro, brocas, limas endodônticas, pontas diamantadas, lâminas de bisturi, lancetas, tubos capilares;  micropipetas; lâminas e lamínulas; espátulas; e todos os utensílios de vidro quebrados no laboratório (pipetas, tubos de coleta sanguínea e placas de Petri) e outras similares, conforme ANVISA RDC 222/2018 - Contempla o resíduo código 180401(*) conforme IBAMA 13/2012',
  },
  {
    value: 'Grupo D',
    label:
      'Resíduos que não apresentam risco biológico, químico ou radiológico à saúde ou ao meio ambiente, podendo ser equiparados aos resíduos domiciliares, classificados como Grupo D conforme RDC ANVISA 222/2018.',
  },
  {
    value: 'Subgrupo A2',
    label:
      'Resíduos de Serviços de Saúde classificados como Subgrupo  A2, conforme ANVISA RDC 222/18 - Contempla os resíduos código 180105(*)  conforme IBAMA 13/2012.',
  },
  {
    value: 'Subgrupo A4',
    label:
      'Resíduos de Serviços de Saúde classificados como Subgrupo A4, conforme ANVISA RDC 222/18 - Contempla os resíduos códigos 180107(*), 180108(*), 180109(*), 180110(*), 180111(*), 180112(*), 180113(*) e 180114(*) conforme IBAMA 13/2012',
  },
  {
    value: 'Subgrupo A5',
    label:
      'Resíduos de Serviços de Saúde classificados como Subgrupo  A5, conforme ANVISA RDC 222/18 - Contempla os resíduos código 180115(*)  conforme IBAMA 13/2012.',
  },
  {
    value: 'Subgrupo A3',
    label:
      'Resíduos de Serviços de Saúde classificados como Subgrupo  A3, conforme ANVISA RDC 222/18 - Contempla os resíduos código 180106(*)  conforme IBAMA 13/2012.',
  },
]
