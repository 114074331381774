import React from 'react'
import { Grid, Typography } from '@mui/material'
import viewModal from '../../../assets/images/updates/client-info-modal-update/view-client-info.gif'

const clientInfoVisualizationContent = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Nova funcionalidade na página de listagem de clientes. Agora, com apenas um clique na empresa desejada, você
          pode acessar rapidamente informações cadastradas, incluindo dados primários e do contrato, bem como todas
          observações feitas sobre o cliente. Essa melhoria facilita a visualização e gestão dos dados, tornando o
          processo mais ágil e intuitivo para os usuários.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Há de se destacar ainda a presença do botão &quot;Editar&quot; na caixa de informações do cliente. Com apenas
          um clique o usuário é redirecionado para edita-lo.
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={viewModal} alt="Visualização de dados do cliente" style={{ width: '90%' }} />
        <div>
          <Typography variant="caption">Navegação pela caixa de visualização de dados do cliente.</Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default clientInfoVisualizationContent
