import { Documents, PartnerDocumentInfos, PartnersDocuments } from '../../../../models/Partner/Partner'

export const extractDocumentsList = (documents: PartnersDocuments): PartnerDocumentInfos[] => {
  const documentsList: PartnerDocumentInfos[] = []

  Object.keys(documents).forEach((documentKey) => {
    const docObj: PartnerDocumentInfos = documents[documentKey as keyof Documents] as PartnerDocumentInfos
    if (docObj && typeof docObj !== 'string') {
      docObj.name = documentKey
      docObj.documentFile = docObj.documentFile || undefined
      docObj.exempt = docObj.exempt || null
      docObj.expirationDate = docObj.expirationDate || ''
      docObj.link = docObj.link || ''
      documentsList.push(docObj)
    }
  })
  return documentsList
}

export const createDocumentsObject = (documentsList: PartnerDocumentInfos[]): Documents => {
  const documents: Documents = {} as Documents
  documentsList.forEach((document) => {
    documents[document.name as keyof Documents] = document
  })
  return documents
}

export const removeDocumentFileField = (documentsList: PartnerDocumentInfos[]): Documents => {
  documentsList.map((document) => {
    const newDocument = { ...document }
    delete newDocument.documentFile
    return newDocument
  })
  return createDocumentsObject(documentsList)
}
