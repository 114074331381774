import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ClientFormType } from '../../../../models/Company/Company'
import Address from '../../Components/Address/Address'
import ClientPrimaryInfo from './components/ClientPrimaryInfo'
import ClientEnviromentSystemLogin from './components/ClientEnviromentSystemLogin'

interface ClientPrimaryInfoTabProps {
  form: UseFormReturn<ClientFormType>
}

const ClientPrimaryInfoTab: React.FC<ClientPrimaryInfoTabProps> = ({ form }) => {
  return (
    <>
      <ClientPrimaryInfo form={form} />
      <ClientEnviromentSystemLogin form={form} />
      <Address form={form} />
    </>
  )
}

export default ClientPrimaryInfoTab
