import MtrModel from '../../../../models/MtrModel/MtrModel'
import checkWasteNames from './checkWastesNames'

const setRequestData = (mtrModel: MtrModel): MtrModel => {
  const { wasteModel } = mtrModel
  const treatedWasteModelRegistry = wasteModel && wasteModel.length > 0 ? checkWasteNames(wasteModel) : wasteModel
  return {
    ...mtrModel,
    wasteModel: treatedWasteModelRegistry,
  }
}

export default setRequestData
