import React from 'react'
import { FormLabel, Grid, Typography } from '@mui/material'
import { ClientResponseI } from '../../../models/Client/Client'
import useStyles from '../Styles/ViewInfoModalStyle'
import ShowPassword from '../../ShowPassword/ShowPassword'

interface ClientPrimaryDataProps {
  client: ClientResponseI
}

const ClientPrimaryData: React.FC<ClientPrimaryDataProps> = ({ client }) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const classes = useStyles()

  const getPassword = (password: string): string => {
    return showPassword ? password : '*'.repeat(password.length)
  }

  return (
    <Grid container spacing={2} className={classes.mainCard}>
      <Grid item xs={12} key="primary-data-title">
        <h2 className={classes.tabTitle}>Dados Primários</h2>
      </Grid>
      <Grid item xs={6} key="company-name">
        <FormLabel className={classes.labelStyle} component="label">
          Razão Social
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-name">
          {client.primaryData.name}
        </Typography>
      </Grid>
      <Grid item xs={6} key="documents">
        <FormLabel className={classes.labelStyle} component="label">
          CNPJ
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-cnpj">
          {client.primaryData.cnpj}
        </Typography>
      </Grid>
      <Grid item xs={12} key="system-title">
        <h2 className={classes.tabTitle}>Dados de acesso ao sistema</h2>
      </Grid>
      <Grid item xs={6} key="system-name">
        <FormLabel className={classes.labelStyle} component="label">
          Sistema
        </FormLabel>
        <Typography variant="body1">{client.primaryData.mtrSystemAccess.mtrSystemName || 'Não informado'}</Typography>
      </Grid>
      <Grid item xs={6} key="unit-code">
        <FormLabel className={classes.labelStyle} component="label">
          Código de Unidade
        </FormLabel>
        <Typography variant="body1">
          {client.primaryData.mtrSystemAccess.mtrSystemUnitCode || 'Não informado'}
        </Typography>
      </Grid>
      <Grid item xs={6} key="system-user">
        <FormLabel className={classes.labelStyle} component="label">
          Usuário (CPF)
        </FormLabel>
        <Typography variant="body1">{client.primaryData.mtrSystemAccess.mtrSystemUser || 'Não informado'}</Typography>
      </Grid>
      <Grid item xs={4} key="system-pass">
        <FormLabel className={classes.labelStyle} component="label">
          Senha
        </FormLabel>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body1">
            {client.primaryData.mtrSystemAccess.mtrSystemPass
              ? getPassword(client.primaryData.mtrSystemAccess.mtrSystemPass)
              : 'Não informado'}
          </Typography>
          {client.primaryData.mtrSystemAccess.mtrSystemPass && (
            <ShowPassword showPassword={showPassword} handleShowPassword={() => setShowPassword(!showPassword)} />
          )}
        </div>
      </Grid>
      <Grid item xs={12} key="address-title">
        <h2 className={classes.tabTitle}>Endereço</h2>
      </Grid>
      <Grid item xs={12} key="address">
        <Typography variant="body1">
          {client.primaryData.address.street},&nbsp;
          {client.primaryData.address.number},&nbsp;
          {client.primaryData.address.complement ? `${client.primaryData.address.complement}. ` : ''}
          {client.primaryData.address.district},&nbsp;
          {client.primaryData.address.city}&nbsp;-&nbsp;
          {client.primaryData.address.state},&nbsp;
          {client.primaryData.address.zipcode}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ClientPrimaryData
