import React from 'react'
import { FormLabel, Grid, Typography } from '@mui/material'
import useStyles from '../Styles/ViewInfoModalStyle'
import { CompanyContactsInfo } from '../../../models/Company/Company'

interface ContactProps {
  contact: CompanyContactsInfo
}

const Contact: React.FC<ContactProps> = ({ contact }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} key="contact-title" sx={{ '&.MuiGrid-item': { height: '60px' } }}>
        <h4>{contact.type}</h4>
      </Grid>
      <Grid item xs={6} key="contact-name">
        <FormLabel className={classes.labelStyle}>Nome</FormLabel>
        <Typography>{contact.name}</Typography>
      </Grid>
      <Grid item xs={6} key="contact-surname">
        <FormLabel className={classes.labelStyle}>Sobrenome</FormLabel>
        <Typography>{contact.surname}</Typography>
      </Grid>
      <Grid item xs={6} key="contact-telephone">
        <FormLabel className={classes.labelStyle}>Telefone</FormLabel>
        <Typography>{contact.telephone}</Typography>
      </Grid>
      <Grid item xs={6} key="contact-email">
        <FormLabel className={classes.labelStyle}>Email</FormLabel>
        <Typography>{contact.email}</Typography>
      </Grid>
    </Grid>
  )
}

export default Contact
