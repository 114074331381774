import React from 'react'
import { Grid, Typography } from '@mui/material'
import allPartnersOption from '../../../assets/images/updates/all-partners-option/all-partners-option.gif'

const allPartnersDocOptionContent = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          A página de Documentação Ambiental ganhou mais uma utilidade para agilizar a consulta de dados. Uma nova
          opção, &quot;Todos os Parceiros&quot;, foi adicionada ao filtro de empresas da página, permitindo que todos os
          parceiros disponíveis para aquele usuário sejam listados, com seus documentos, de uma só vez.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          ATENÇÃO: Os usuários podem ver somente os parceiros que atendem as empresas vinculadas a sua conta. Caso você
          não encontre algum parceiro em específico, entre em contato com time operacional da Recicla.
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={allPartnersOption} alt="Visualização dos documentos de todos os parceiros" style={{ width: '90%' }} />
        <div>
          <Typography variant="caption">Navegação pela visualização dos documentos de todos os parceiros.</Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default allPartnersDocOptionContent
