import React, { ChangeEvent } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Card, FormLabel, Grid } from '@mui/material'
import { ClientFormType } from '../../../../../models/Company/Company'
import InputSelect2, { Select2Options } from '../../../../Form/Select/Select2'
import InputText from '../../../../Form/Input/Input'
import InputPassword from '../../../../Form/Input/InputPassword'
import { useStyles } from '../../../CompanyFormStyle'

interface ClientEnviromentSystemLoginProps {
  form: UseFormReturn<ClientFormType>
}

const ClientEnviromentSystemLogin: React.FC<ClientEnviromentSystemLoginProps> = ({ form }) => {
  const { control, getValues, setValue, formState } = form
  const [showPassword, setShowPassword] = React.useState(false)
  const classes = useStyles()

  const systemOptions = [
    { value: 'FEAM', label: 'FEAM' },
    { value: 'IEMA', label: 'IEMA' },
    { value: 'CETESB', label: 'CETESB' },
  ]

  const handleSelectedSystem = (event: React.SyntheticEvent<Element, Event>, value: Select2Options | null): void => {
    setValue('system', String(value?.value))
  }

  const handleShowPassword = (): void => {
    setShowPassword(!showPassword)
  }

  /* eslint-disable no-param-reassign */
  const handleBranchChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ): void => {
    const { value } = event.target
    const newValue = value.replace(/^0+(\d+)/, '$1')
    onChange(newValue)
    event.target.value = newValue
  }

  return (
    <Card className="form-card" variant="outlined">
      <h2 className={classes.tabTitle}>Dados de acesso ao sistema</h2>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} key="system">
          <FormLabel component="label" className={classes.labelStyle}>
            Sistema
            <Controller
              name="system"
              control={control}
              render={({ field }) => (
                <InputSelect2
                  id="system"
                  label=""
                  options={systemOptions}
                  onChange={handleSelectedSystem}
                  clearable
                  sx={{ borderRadius: '4px' }}
                  value={getValues('system')}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="branchCode">
          <FormLabel component="label" className={classes.labelStyle}>
            Código de Unidade
            <Controller
              name="branch"
              control={control}
              defaultValue={getValues('branch')}
              render={({ field }) => (
                <InputText
                  id="branch"
                  value={field.value}
                  type="number"
                  label=""
                  errorText={formState.errors?.branch?.message}
                  onChange={(e) => handleBranchChange(e, field.onChange)}
                  autoComplete="off"
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="cnpj">
          <FormLabel component="label" className={classes.labelStyle}>
            CPF
            <Controller
              name="cpf"
              control={control}
              defaultValue={getValues('cpf')}
              render={({ field }) => (
                <InputText
                  id="cpf"
                  label=""
                  errorText={formState.errors?.cpf?.message}
                  autoComplete="new-cpf"
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="password">
          <FormLabel component="label" className={classes.labelStyle}>
            Senha
            <Controller
              name="password"
              control={control}
              defaultValue={getValues('password')}
              render={({ field }) => (
                <InputPassword
                  id="systemPassword"
                  isTypePassword={showPassword}
                  changePasswordView={handleShowPassword}
                  errorText={formState.errors?.password?.message}
                  field={field}
                />
              )}
            />
          </FormLabel>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ClientEnviromentSystemLogin
