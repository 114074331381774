import React from 'react'
import { useParams } from 'react-router-dom'
import { ObservationsInfo } from '../../../../../models/Company/Company'
import AppContext, { AppContextType } from '../../../../../AppContext'
import ClientObservation from '../../../../../models/Client/ClientObservation'
import {
  getObservationsByCustomerIdentifier,
  saveObservation,
} from '../../../../../services/Client/ClientObservationsService'
import Notes from '../../../Components/Notes/Notes'
import treatGMTZeroDates from '../../../../../utils/masks/treatGMTZeroDates'

const ClientNotes: React.FC = () => {
  const { setIsShowLoading, showAlert } = React.useContext<AppContextType>(AppContext as React.Context<AppContextType>)
  const { identifier } = useParams<{ identifier: string | undefined }>()

  const [notes, setNotes] = React.useState<ObservationsInfo[]>([])
  const [noteInProgress, setNoteInProgress] = React.useState<string>('')
  const [notesFecthed, setNotesFecthed] = React.useState<boolean>(false)

  const setObservationsByClientInfo = async (): Promise<void> => {
    setIsShowLoading(true)
    try {
      const savedObservations = (await getObservationsByCustomerIdentifier(identifier as string)).map((obs) => ({
        ...obs,
        createdAt: treatGMTZeroDates(obs.createdAt),
      }))
      setNotes(savedObservations)
    } catch (error) {
      showAlert('error', 'Erro ao carregar observações!')
    } finally {
      setNotesFecthed(true)
      setIsShowLoading(false)
    }
  }

  const postNote = async (event: React.SyntheticEvent<any>, value: string): Promise<void> => {
    event.preventDefault()
    setIsShowLoading(true)
    try {
      const observationRequest = { observation: value, customerIdentifier: identifier as string }
      const savedObservation = await saveObservation(observationRequest)
      if (savedObservation) {
        const newObservation = {
          ...savedObservation,
          createdAt: treatGMTZeroDates(savedObservation.createdAt),
        }
        const newNotesList = [...notes, newObservation]
        setNotes(newNotesList)
        showAlert('success', 'Observação criada com sucesso!')
      }
    } catch (error) {
      showAlert('error', 'Erro ao criar observação!')
    } finally {
      setIsShowLoading(false)
      setNoteInProgress('')
    }
  }

  React.useEffect(() => {
    setObservationsByClientInfo()
  }, [])

  return notesFecthed ? (
    <Notes notes={notes} postNote={postNote} noteInProgress={noteInProgress} setNoteInProgress={setNoteInProgress} />
  ) : (
    <div />
  )
}

export default ClientNotes
