import { createTheme, ThemeProvider } from '@mui/material'

export default createTheme({
  palette: {
    primary: {
      main: '#43b849',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: '#fff',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#43b849',
          },
        },
        colorSecondary: {
          color: '#43b849 !important',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          borderColor: '#43b849',
          backgroundColor: '#FFF',
          fontFamily: 'nunitoRegular !important',
        },
      },
    },
  },
})
