import FormHelperText from '@mui/material/FormHelperText'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import React from 'react'

export type InputTextProps = {
  id: string
  label?: string
  type?: string
  helperText?: string
  errorText?: string
} & TextFieldProps

const InputText: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, InputTextProps> = (
  { id, label, type, helperText, errorText, ...rest },
  ref
) => {
  const hasError = !!errorText

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        type={type || 'text'}
        data-cy={`input-${id}`}
        InputLabelProps={{ shrink: type === 'date' ? true : undefined }}
        error={hasError}
        inputRef={ref}
        id={id}
        label={label}
        helperText={errorText}
        {...rest}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  )
}

export default React.forwardRef(InputText)
