import axios from 'axios'

import Operation, { CustomerPartners, OperationList } from '../../models/Company/Operation'
import getEndpointUrl from '../../utils/url-resolver'
import { CompanyMinimalInfo } from '../../models/Company/Company'

const baseUrl = `${getEndpointUrl('company')}/v1/operation`
const newBaseUrl = `${getEndpointUrl('company')}/v1/customer-partners`

const getContractByCompanyId = (id: number): Promise<OperationList> =>
  axios.get<OperationList>(`${baseUrl}/company/${id}`).then((response) => response.data)

const getPartnersByCustomer = (id: string): Promise<CompanyMinimalInfo[]> =>
  axios.get<CompanyMinimalInfo[]>(`${newBaseUrl}/${id}`).then((response) => {
    return response.data
  })

const updatePartnersInCustomer = (customerPartners: CustomerPartners): Promise<void> =>
  axios
    .put(`${newBaseUrl}/${customerPartners.identifierCustomer}`, customerPartners.partners)
    .then((response) => response.data)

const createContract = (contract: Operation): Promise<void> =>
  axios.post(`${baseUrl}/`, contract).then((response) => response.data)

const createContractByCompany = (contract: Operation): Promise<void> =>
  axios.post(`${baseUrl}/company`, contract).then((response) => response.data)

export {
  getContractByCompanyId,
  getPartnersByCustomer,
  createContract,
  createContractByCompany,
  updatePartnersInCustomer,
}
