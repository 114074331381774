import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import PartnerPrimaryInfo from './components/PartnerPrimaryInfo'
import Address from '../../Components/Address/Address'
import { PartnerFormType } from '../../../../models/Company/Company'

interface PartnerPrimaryInfoTabProps {
  form: UseFormReturn<PartnerFormType>
}

const PartnerPrimaryInfoTab: React.FC<PartnerPrimaryInfoTabProps> = ({ form }) => {
  return (
    <>
      <PartnerPrimaryInfo form={form} />
      <Address form={form} />
    </>
  )
}

export default PartnerPrimaryInfoTab
