import { makeStyles } from '@mui/styles'
import { textAlign, textTransform } from '@mui/system'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  tableDiv: {
    marginLeft: '15px',
    marginRight: '15px',
  },
  documentDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px',
    width: '140px',
    '& .MuiChip-root': {
      backgroundColor: 'transparent',
    },
  },
  statusDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiChip-root': {
      width: '12px',
      height: '12px',
      borderRadius: '100%',
    },
    '& div': {
      paddingLeft: '4px',
    },
  },
  table: {
    '& thead': {
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
    '& thead th': {
      background: '#fff',
    },
    '& thead th:first-child': {
      zIndex: 2,
    },
    '& tbody th': {
      textAlign: 'left',
      textTransform: 'none',
      fontFamily: 'nunitoRegular !important',
      fontWeight: 500,
      borderBottom: 'rgb(224, 224, 224) solid 1px',
    },
    '& th': {
      position: 'sticky',
      minWidth: '200px',
      left: 0,
      zIndex: 1,
      background: '#fff',
      fontSize: '12px',
    },
    '& th:first-child': {
      zIndex: 0,
    },
    '& td': {
      background: '#fff',
      fontSize: '12px',
    },
  },
})

export default useStyles
