import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import React from 'react'

import { Order } from '../../../utils/sort'
import { DataTableMapProps } from '../DataTable'
import useStyles from './DataTableHeaderStyle'

type DataTableHeaderProps = {
  dataProps: DataTableMapProps[]
  hasActions: boolean
  order: Order
  orderBy: string
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  minWidth?: string
}

const DataTableHeader: React.FC<DataTableHeaderProps> = ({
  dataProps,
  hasActions,
  order,
  orderBy,
  onRequestSort,
  minWidth,
}: DataTableHeaderProps) => {
  const classes = useStyles()

  const createSortHandler =
    (property: string, value: string | number | JSX.Element) => (event: React.MouseEvent<unknown>) => {
      if (typeof value !== 'object') {
        onRequestSort(event, property)
      }
    }

  return (
    <TableHead>
      <TableRow>
        {dataProps.map((dataProp) => {
          const isComponent = typeof dataProp.value !== 'string'
          return (
            <TableCell
              // eslint-disable-next-line react/no-array-index-key
              key={`cell-${dataProp.key}`}
              align="left"
              padding="normal"
              sortDirection={orderBy === dataProp.key ? order : false}
              sx={{ minWidth }}
            >
              {!isComponent ? (
                <TableSortLabel
                  active={orderBy === dataProp.key}
                  direction={orderBy === dataProp.key ? order : 'asc'}
                  onClick={createSortHandler(dataProp.key, dataProp.value)}
                  style={{ textAlign: 'center', paddingLeft: '5%' }}
                >
                  {dataProp.value}
                  {orderBy === dataProp.key ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <div>{dataProp.value}</div>
              )}
            </TableCell>
          )
        })}
        {hasActions && (
          <TableCell align="center" padding="normal">
            Ações
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}

export default DataTableHeader
