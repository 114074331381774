export type Order = 'asc' | 'desc'

export type ItemType<Key extends keyof any> = {
  [key in Key]: number | string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, emptyValue?: string): number {
  const aVal = a[orderBy] ? a[orderBy] : (emptyValue as string)
  const bVal = b[orderBy] ? b[orderBy] : (emptyValue as string)
  if (bVal < aVal) {
    return -1
  }
  if (bVal > aVal) {
    return 1
  }
  return 0
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  emptyValue?: string
): (a: ItemType<Key>, b: ItemType<Key>) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, emptyValue)
    : (a, b) => -descendingComparator(a, b, orderBy, emptyValue)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export { getComparator, stableSort }
