import React from 'react'
import { Card } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { PartnerFormType } from '../../../../../models/Company/Company'
import { useStyles } from '../../../CompanyFormStyle'
import ContactForm from '../../../Components/Contact/ContactForm'

interface PartnerContactProps {
  form: UseFormReturn<PartnerFormType>
}

const PartnerContact: React.FC<PartnerContactProps> = ({ form }) => {
  const { formState } = form
  const companyFormclasses = useStyles()

  return (
    <Card className="form-card" variant="outlined">
      <h2 className={companyFormclasses.tabTitle}>Contatos</h2>
      <h3 style={{ color: '#33B652', fontWeight: 'lighter', paddingTop: '30px' }}>Financeiro</h3>
      <ContactForm form={form} contactType="financeContact" />
      <h3 style={{ color: '#33B652', fontWeight: 'lighter', paddingTop: '30px' }}>Operação</h3>
      <ContactForm form={form} contactType="operationalContact" />
      <h3 style={{ color: '#33B652', fontWeight: 'lighter', paddingTop: '30px' }}>Comercial</h3>
      <ContactForm form={form} contactType="commercialContact" />
      <h3 style={{ color: '#33B652', fontWeight: 'lighter', paddingTop: '30px' }}>Diretoria</h3>
      <ContactForm form={form} contactType="managementContact" />
    </Card>
  )
}

export default PartnerContact
