import { Divider, FormLabel, Grid, Typography } from '@mui/material'
import React from 'react'
import { WasteModel } from '../../../../models/MtrModel/MtrModel'
import getAllFeamData from '../../../../services/FEAM/FeamService'
import useStyles from './styles/GatheringConfirmationInfoModalStyle'
import DataTable from '../../../../components/DataTable/DataTable'

interface GatheringResiduesProps {
  wasteList: WasteModel[]
}

const GatheringResidues: React.FC<GatheringResiduesProps> = ({ wasteList }) => {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)

  const wasteTableMap = [
    { value: 'Resíduo', key: 'name' },
    { value: 'Qtd.', key: 'quantity' },
    { value: 'Und.', key: 'unit' },
    // { value: 'Est. Físico', key: 'state' },
    { value: 'Classe', key: 'wasteClass' },
    // { value: 'Acondi.', key: 'packagingCod' },
    { value: 'Tecn.', key: 'technology' },
    // { value: 'Num. ONU', key: 'onuNumber' },
    // { value: 'Classe de Risco', key: 'riskClass' },
    { value: 'Cód. Int. no Gerador', key: 'generatorInternalCode' },
    { value: 'Desc. Int. do Gerador', key: 'generatorInternalDescription' },
  ]

  const renderWasteList = (wastes: WasteModel[]): JSX.Element => {
    const feamData = getAllFeamData()

    const treatedWasteModel = wastes.map((waste) => {
      const unit = feamData.unidades.find((u) => u.value === Number(waste.unit))?.label
      const wasteClass = feamData.classes.find((c) => c.value === Number(waste.wasteClass))?.label
      const state = feamData.estadosFisicos.find((e) => e.value === Number(waste.state))?.label
      const packaging = feamData.acondicionamento.find((a) => a.value === waste.packagingCod)?.label
      const technology = feamData.tecnologias.find((t) => t.value === Number(waste.technology))?.label
      return {
        ...waste,
        name: `${waste.wasteCode} - ${waste.name.substring(0, 200)} ${waste.name.length > 200 ? '...' : ''}`,
        unit,
        wasteClass,
        state,
        packagingCod: packaging,
        technology,
      }
    })

    return (
      <DataTable
        dataMap={wasteTableMap}
        data={treatedWasteModel}
        loading={loading}
        config={{ showPagination: false }}
        settings
      />
    )
  }

  return (
    <Grid container spacing={2} className={classes.card}>
      {renderWasteList(wasteList)}
    </Grid>
  )
}

export default GatheringResidues
