import React from 'react'
import { Grid, Typography } from '@mui/material'
import newDocumentOption from '../../../assets/images/updates/new-partner-document-cnd-municipal-update/new-document-option-cnd-municipal-form.png'

const newDocumentOptionCndMunicipalUpdateContent = (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="update-content">
          Nova opção de documentação ambiental do parceiro foi adicionada ao sistema. A CND Municipal pode ser inclusa
          no escopo de documentos de cada parceiro, ao editá-lo. Além disso, após adicionado, o documento pode ser
          acessado via página de Documentação Ambiental.
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '15px' }}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      >
        <img src={newDocumentOption} alt="Nova opção 'CND Municipal' no escopo de documentos ambientais do parceiro." />
        <div>
          <Typography variant="caption">
            Nova opção &apos;CND Municipal&apos; no escopo de documentos ambientais do parceiro.
          </Typography>
        </div>
      </Grid>
    </Grid>
  </>
)

export default newDocumentOptionCndMunicipalUpdateContent
