import React from 'react'
import { Card, Chip, Grid, IconButton } from '@mui/material'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import { getAllCustomers } from '../../../services/Companies/CompaniesService'
import Icons from '../../../assets/icons/svgIcons'
import DataTableFreezer from '../../../components/DataTable/DataTableFreezer'
import ViewDeletePopover from '../../../components/PopOver/ViewDeletePopover'
import AppContext, { AppContextType } from '../../../AppContext'
import { ApplicationColors } from '../../../utils/applicationColors'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import useStyles from './DocumentationStyle'
import { PartnerDocumentInfos, PartnersDocuments } from '../../../models/Partner/Partner'
import {
  getAllPartnerDocumentsByCustomer,
  getAllPartnersDocumentsByUserIdentifier,
} from '../../../services/Partner/PartnerService'
import { CompanyMinimalInfo } from '../../../models/Company/Company'
import getInitialSelectedCompany, { getCompanyByIdentifier } from '../../../utils/getInitialSelectedCompany'
import { getLoggedUser } from '../../../services/Auth/TokenService'

interface DocumentationPartnersInfo {
  name: string
  documentationLink: string
  licencaAmbiental: JSX.Element
  dml: JSX.Element
  ctf: JSX.Element
  avcb: JSX.Element
  alvaraFuncionamento: JSX.Element
  alvaraVigilancia: JSX.Element
  cndMunicipal: JSX.Element
  licencaCacamba: JSX.Element
  licencaVeiculos: JSX.Element
  mopp: JSX.Element
  aatipp: JSX.Element
}

const Documentation: React.FC = () => {
  const {
    defaultBranch,
    setTitle,
    setCustomHeaderContent,
    showAlert,
    setItemsBreadCrumb,
    setIsShowLoading,
    defaultCompany,
    setDefaultCompany,
  } = React.useContext(AppContext as React.Context<AppContextType>)
  const [selectedCompany, setSelectedCompany] = React.useState<string>('')
  const [customers, setCustomers] = React.useState<CompanyMinimalInfo[]>([])
  const [companyFilterOptions, setCompanyFilterOptions] = React.useState<Select2Options[]>([])
  const [partnersDocumentsList, setPartnersDocumentsList] = React.useState<DocumentationPartnersInfo[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [itemToChange, setItemToChange] = React.useState<string>()
  const [anchor, setAnchor] = React.useState<any>(null)
  const classes = useStyles()
  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  const [containerTableHeight, setContainerTableHeight] = React.useState(350)

  const documentationTableFields = [
    { key: 'name', value: 'Parceiro', isHead: true },
    { key: 'licencaAmbiental', value: 'Licença Ambiental' },
    { key: 'ctf', value: 'CTF Ibama' },
    { key: 'avcb', value: 'AVCB' },
    { key: 'alvaraFuncionamento', value: 'Alvará de Funci. da Pref.' },
    { key: 'alvaraVigilancia', value: 'Alvará da Vig. Sanitaria' },
    { key: 'cndMunicipal', value: 'CND Municipal' },
    { key: 'dml', value: 'DML' },
    { key: 'licencaCacamba', value: 'Licença de Caçamba' },
    { key: 'licencaVeiculos', value: 'Licença de Veic. para Transp.' },
    { key: 'mopp', value: 'MOPP do Motorista' },
    { key: 'aatipp', value: 'AATIPP' },
  ]

  const documentNotApplied = (
    <div className={classes.documentDiv}>
      <Chip label="N/A" />
    </div>
  )

  const getDocDivColorByDate = (paramDate: string): string => {
    const today = new Date()
    const inputDate = new Date(paramDate)

    const differenceInTime = inputDate.getTime() - today.getTime()
    const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24))

    if (differenceInDays < 0) {
      return ApplicationColors.error90
    }
    if (differenceInDays <= 30) {
      return ApplicationColors.warning80
    }
    return ApplicationColors.success90
  }

  const treatDocumentDate = (date: string): string => {
    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
  }

  const getDocValues = (document: PartnerDocumentInfos): JSX.Element => {
    if (document.status === 'NA' && !document.link) return documentNotApplied
    const divDate = document.expirationDate ? treatDocumentDate(document.expirationDate) : 'Sem data'
    const divColor = !document.expirationDate
      ? ApplicationColors.warning80
      : getDocDivColorByDate(document.expirationDate.toString())
    return (
      <div
        style={{
          backgroundColor: divColor,
        }}
        className={classes.documentDiv}
      >
        <Chip label={document.exempt ? 'Dispensado' : divDate} />
        <Icons.Download className={classes.cursorPointer} onClick={() => window.open(document.link, '_blank')} />
      </div>
    )
  }

  const getPartnersDocuments = async (identifier: string): Promise<PartnersDocuments[] | null> => {
    const isAllPartners = identifier === 'todos'
    const userIdentifier = getLoggedUser()!.identifier
    const partnersDocs = isAllPartners
      ? await getAllPartnersDocumentsByUserIdentifier(userIdentifier)
      : await getAllPartnerDocumentsByCustomer(identifier)
    return partnersDocs
  }

  const setPartnersInfo = async (identifier: string): Promise<void> => {
    setIsShowLoading(true)
    try {
      const partnersDocs = await getPartnersDocuments(identifier)
      if (partnersDocs) {
        const documentsList: DocumentationPartnersInfo[] = []
        partnersDocs.forEach((partnerDoc) => {
          const documentObj: DocumentationPartnersInfo = {
            name: partnerDoc.partnerName,
            documentationLink: partnerDoc.documentationLink ? partnerDoc.documentationLink : '',
            licencaAmbiental: documentNotApplied,
            dml: documentNotApplied,
            ctf: documentNotApplied,
            avcb: documentNotApplied,
            alvaraFuncionamento: documentNotApplied,
            alvaraVigilancia: documentNotApplied,
            cndMunicipal: documentNotApplied,
            licencaCacamba: documentNotApplied,
            licencaVeiculos: documentNotApplied,
            mopp: documentNotApplied,
            aatipp: documentNotApplied,
          }

          documentObj.licencaAmbiental = getDocValues(partnerDoc.licencaAmbiental)
          documentObj.dml = getDocValues(partnerDoc.dml)
          documentObj.ctf = getDocValues(partnerDoc.ctfIbama)
          documentObj.avcb = getDocValues(partnerDoc.avcb)
          documentObj.alvaraFuncionamento = getDocValues(partnerDoc.alvaraFuncionamento)
          documentObj.alvaraVigilancia = getDocValues(partnerDoc.alvaraVigilancia)
          documentObj.cndMunicipal = getDocValues(partnerDoc.cndMunicipal)
          documentObj.licencaCacamba = getDocValues(partnerDoc.licencaCacamba)
          documentObj.licencaVeiculos = getDocValues(partnerDoc.licencaVeiculos)
          documentObj.mopp = getDocValues(partnerDoc.mopp)
          documentObj.aatipp = getDocValues(partnerDoc.aatipp)
          documentsList.push(documentObj)
        })

        setPartnersDocumentsList(documentsList)
      }
    } catch {
      setPartnersDocumentsList([])
      showAlert('info', 'Não foram encontrados parceiros para este cliente.')
    } finally {
      setIsShowLoading(false)
    }
  }

  const getMainViewHeight = (): number => {
    const search = document.getElementsByTagName('main')
    const main = search.length === 1 ? search[0].getBoundingClientRect() : { height: 120 }
    return main.height
  }

  const readjustContainerTableHeight = (): void => {
    const filterHeight = 120
    const tableHeight = getMainViewHeight() - filterHeight
    setContainerTableHeight(tableHeight)
  }

  const getInitialSelectedCustomer = (customersInfo: CompanyMinimalInfo[]): CompanyMinimalInfo => {
    return getInitialSelectedCompany(customersInfo, defaultCompany)
  }

  const setCustomersInfo = (customersInfo: CompanyMinimalInfo[]): void => {
    setCustomers(customersInfo)
    const initialSelectedCompany = getInitialSelectedCustomer(customersInfo)
    setSelectedCompany(initialSelectedCompany.identifier)
    const companyOptions = [
      { label: 'Todos os parceiros', value: 'todos' },
      ...customersInfo.map((customer) => ({ value: customer.identifier, label: customer.name })),
    ]
    setCompanyFilterOptions(companyOptions)
    setPartnersInfo(initialSelectedCompany.identifier)
  }

  const handleInit = React.useCallback(async () => {
    setLoading(true)
    setIsShowLoading(true)
    setTitle('Documentação Ambiental')
    setCustomHeaderContent(<div />)
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Documentação ambiental', path: '/main/documentation/list' },
    ])

    try {
      const customersInfo = await getAllCustomers()
      setCustomersInfo(customersInfo)
    } catch (err) {
      showAlert('error', 'Erro ao capturar informações da página. Contate o suporte.')
    } finally {
      setLoading(false)
      setIsShowLoading(false)
    }
  }, [])

  React.useEffect(() => {
    handleInit()
  }, [handleInit])

  React.useEffect(() => {
    readjustContainerTableHeight()

    window.addEventListener('resize', readjustContainerTableHeight)

    return () => {
      window.removeEventListener('resize', readjustContainerTableHeight)
    }
  }, [])

  const setDefaultCustomerInfo = (customerIdentifier: string): void => {
    const customerInfo = getCompanyByIdentifier(customers, customerIdentifier)
    if (customerInfo) setDefaultCompany(customerInfo)
  }

  const onChangeCompany = (event?: React.SyntheticEvent, value?: Select2Options | null): void => {
    const identifier = value?.value as string
    setSelectedCompany(identifier)
    setPartnersInfo(identifier)
    setDefaultCustomerInfo(identifier)
  }

  const handleView = (item: string): void => {
    window.open(item as string, '_blank')
  }

  const handleClose = (): void => {
    setIsOpen(false)
  }

  const handleMoreOptions = (item: string, anchorEl?: EventTarget & HTMLButtonElement): void => {
    setIsOpen(true)
    setAnchor(anchorEl)
    setItemToChange(item)
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <Card className="card-filters" variant="outlined">
          <InputSelect2
            id="companySelect"
            label="Cliente"
            value={selectedCompany}
            options={companyFilterOptions}
            onChange={onChangeCompany}
            clearable
          />
        </Card>
      </Grid>
      <div
        className={`${classes.tableDiv} filter--open`}
        style={{ height: `${containerTableHeight}px` }}
        ref={tableContainerRef}
      >
        <DataTableFreezer
          data={partnersDocumentsList}
          dataMap={documentationTableFields}
          loading={loading}
          compact
          minWidth="180px"
          customColumns={[
            {
              label: 'Documentação',
              content: (row: DocumentationPartnersInfo) =>
                row && row.documentationLink ? (
                  <IconButton
                    size="large"
                    onClick={({ target }) => handleMoreOptions(row.documentationLink, target as HTMLButtonElement)}
                  >
                    <Icons.MoreVertical fill={ApplicationColors.primaryColor} />
                  </IconButton>
                ) : (
                  <p />
                ),
            },
          ]}
          tableClassName={classes.table}
        />
        <ViewDeletePopover
          viewFunction={handleView}
          open={isOpen}
          item={itemToChange}
          handleClose={handleClose}
          anchor={anchor}
        />
      </div>
    </Grid>
  )
}

export default Documentation
