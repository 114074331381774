import React from 'react'
import { Card, FormLabel, Grid } from '@mui/material'
import { Controller, FieldError, UseFormReturn } from 'react-hook-form'
import AppContext, { AppContextType } from '../../../../../AppContext'
import InputText from '../../../../Form/Input/Input'
import InputSelect2Multiple, { Select2Options } from '../../../../Form/Select/Select2Multiple'
import InputSelect2 from '../../../../Form/Select/Select2'
import InputFile from '../../../../Form/Input/InputFile'
import {
  residuesOptions,
  operationOptions,
  homologationStatusOptions,
} from '../../../../../models/Company/FormOptions/TechnicalInfoOptions'
import states from '../../../../../utils/states-and-citys'
import getCitiesByState from '../../../../../services/Cities/Cities'
import { useStyles } from '../../../CompanyFormStyle'
import { PartnerFormType } from '../../../../../models/Company/Company'

interface PartnerTechnicalInfoProps {
  form: UseFormReturn<PartnerFormType>
}

const PartnerTechnicalInfo: React.FC<PartnerTechnicalInfoProps> = ({ form }) => {
  const [residues, setResidues] = React.useState<string[]>([])
  const [selectedStates, setSelectedStates] = React.useState<string[]>([])
  const [citiesList, setCitiesList] = React.useState<Select2Options[]>([])
  const [selectedCities, setSelectedCities] = React.useState<string[]>([])
  const { setIsShowLoading } = React.useContext(AppContext as React.Context<AppContextType>)

  const { control, formState, setValue, getValues } = form
  const companyFormclasses = useStyles()

  const handleResiduesChange = (_event: React.SyntheticEvent, value: Select2Options[] | null): void => {
    const residuesList = value ? (value.map((type) => type.value) as string[]) : []
    setResidues(residuesList)
    setValue('residues', residuesList)
  }

  const handleOperationStatusChange = (_event: React.SyntheticEvent, value: Select2Options | null): void => {
    setValue('operationStatus', value?.value as string)
  }

  const handleHomologationStatusChange = (_event: React.SyntheticEvent, value: Select2Options | null): void => {
    setValue('homologationStatus', value?.value as string)
  }

  const updateCitiesListWhenAStateIsRemoved = (newStatesList: string[]): void => {
    if (newStatesList.length > 0) {
      const newCitiesList = citiesList.filter((city) => newStatesList.includes(String(city.label).split('-')[1].trim()))
      setCitiesList(newCitiesList)
      const newSelectedCities = selectedCities.filter((city) =>
        newStatesList.includes(String(city).split('-')[1].trim())
      )
      setSelectedCities(newSelectedCities)
      setValue('citiesActive', newSelectedCities)
    } else {
      setCitiesList([])
      setSelectedCities([])
      setValue('citiesActive', [])
    }
  }

  const updateCitiesListWhenNewStateIsAdded = (newStatesList: string[]): void => {
    if (newStatesList.length > 0) {
      newStatesList.forEach((state) => {
        const isThere = citiesList.some((city) => String(city.label).includes(state))
        if (!isThere) {
          setIsShowLoading(true)
          getCitiesByState(state).then((cities) => {
            const newCities = cities
            setCitiesList([...citiesList, ...newCities])
            setIsShowLoading(false)
          })
        }
      })
    }
  }

  const handleActiveStatesChange = (_event: React.SyntheticEvent, value: Select2Options[] | null): void => {
    const newStatesList = value ? (value.map((type) => type.value) as string[]) : []
    const didStateGetRemoved = selectedStates.some((state) => !newStatesList.includes(state))
    setSelectedStates(newStatesList)
    setValue('statesActive', newStatesList)
    if (didStateGetRemoved) {
      updateCitiesListWhenAStateIsRemoved(newStatesList)
    } else {
      updateCitiesListWhenNewStateIsAdded(newStatesList)
    }
  }

  const handleActiveCitiesChange = (_event: React.SyntheticEvent, value: Select2Options[] | null): void => {
    const newCitiesList = value ? (value.map((city) => city.value) as string[]) : []
    setSelectedCities(newCitiesList)
    setValue('citiesActive', newCitiesList)
  }

  const handleCompanysFacadeFileChange = (file: File | undefined): void => {
    setValue('companysFacade', file)
  }

  const setCitiesListByActiveStates = async (activeStates: string[]): Promise<void> => {
    const citiesPromises: Promise<Select2Options[]>[] = activeStates.map(async (state) => {
      const cities = await getCitiesByState(state)
      return cities
    })

    const citiesListByActiveStates: Select2Options[] = (await Promise.all(citiesPromises)).flat()
    setCitiesList(() => citiesListByActiveStates)
  }

  const setSelectedActiveCities = (activeCities: string[]): void => {
    const newCitiesList: string[] = []
    activeCities.forEach((city) => {
      const isThere = newCitiesList.some((cityOption) => String(cityOption).includes(city))
      if (!isThere) {
        newCitiesList.push(city)
      }
    })
    setSelectedCities(newCitiesList)
  }

  React.useEffect(() => {
    setIsShowLoading(true)
    const selectedStatesList = getValues('statesActive')
    setSelectedStates(selectedStatesList)
    setCitiesListByActiveStates(selectedStatesList)
    const residuesList = getValues('residues') || []
    setResidues(residuesList)
    setSelectedActiveCities(getValues('citiesActive') || [])
    setIsShowLoading(false)
  }, [])

  return (
    <Card className="form-card" variant="outlined">
      <h2 className={companyFormclasses.tabTitle}>Dados técnicos</h2>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} key="residues">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Tipos de Resíduos que trabalha
          </FormLabel>
          <Controller
            name="residues"
            control={control}
            render={({ field }) => (
              <InputSelect2Multiple
                id="residues"
                label=""
                errorText={(formState.errors?.residues as unknown as FieldError)?.message}
                options={residuesOptions}
                {...field}
                value={residues}
                onChange={handleResiduesChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} key="operationStatus">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Status da Operação
            <Controller
              name="operationStatus"
              control={control}
              defaultValue="Ativo"
              render={({ field }) => (
                <InputSelect2
                  id="operationStatus"
                  label=""
                  errorText={(formState.errors?.operationStatus as unknown as FieldError)?.message}
                  value={field.value}
                  options={operationOptions}
                  onChange={handleOperationStatusChange}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="homologationStatus">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Status de Homologação
            <Controller
              name="homologationStatus"
              control={control}
              render={({ field }) => (
                <InputSelect2
                  id="homologationStatus"
                  label=""
                  errorText={formState.errors?.homologationStatus?.message}
                  options={homologationStatusOptions}
                  value={field.value}
                  onChange={handleHomologationStatusChange}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="companyesFacade">
          <FormLabel
            component="label"
            sx={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}
            className={companyFormclasses.labelStyle}
          >
            Fachada da Empresa
            <Controller
              name="companysFacade"
              control={control}
              render={() => (
                <InputFile
                  id="companysFacade"
                  file={getValues('companysFacade') || undefined}
                  fileLink={getValues('companysFacadeLink') || ''}
                  documentName="Fachada"
                  isPartnerDoc={false}
                  onChange={handleCompanysFacadeFileChange}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="statesActive">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Todos os estados em que atua
            <Controller
              name="statesActive"
              control={control}
              defaultValue={selectedStates}
              render={({ field }) => (
                <InputSelect2Multiple
                  id="statesActive"
                  label=""
                  errorText={(formState.errors?.statesActive as unknown as FieldError)?.message}
                  options={states}
                  {...field}
                  onChange={handleActiveStatesChange}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="citiesActive">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Cidades em que atua
            <Controller
              name="citiesActive"
              control={control}
              render={({ field }) => (
                <InputSelect2Multiple
                  id="citiesActive"
                  label=""
                  errorText={(formState.errors?.citiesActive as unknown as FieldError)?.message}
                  options={citiesList}
                  value={selectedCities}
                  onChange={handleActiveCitiesChange}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="trucksQuantity">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Quantidade de caminhões
            <Controller
              name="trucksQuantity"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <InputText
                  id="trucksQuantity"
                  label=""
                  errorText={formState.errors?.trucksQuantity?.message}
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="employeesQuantity">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Quantidade de funcionários
            <Controller
              name="employeesQuantity"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <InputText
                  id="employeesQuantity"
                  label=""
                  errorText={formState.errors?.employeesQuantity?.message}
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
      </Grid>
    </Card>
  )
}

export default PartnerTechnicalInfo
