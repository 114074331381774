import { SxProps } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {},
    form: {
      width: '45vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingLeft: '7vw',
      paddingRight: '7vw',
      paddingBottom: '50vh',
    },
    header: {
      marginBottom: '3vh',
      position: 'relative',
    },
    headerTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: '30%',
    },
    closeButton: {
      position: 'absolute',
      right: '0%',
      top: '25%',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '5vh',
    },
    clearButton: {
      width: '217px',
      color: 'black !important',
      backgroundColor: 'white',
      borderRadius: '100px !important',
      border: '2px solid #43b849 !important',
      marginRight: '36px !important',
    },
    filterButton: {
      borderRadius: '100px !important',
      width: '217px',
    },
  })
)
export default useStyles
