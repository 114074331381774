import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    truncate: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
    },
    card: {
      width: '100%',
      height: '100%',
    },
    table: {
      height: '85%',
      width: '100%',
    },
  })
)

export default useStyles
