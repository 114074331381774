import { WasteModel } from '../../../../models/MtrModel/MtrModel'

const checkWasteNameMaximumLength = (wasteName: string): string => {
  return wasteName.length <= 255 ? wasteName : `${wasteName.substring(0, 245)}... (...)`
}

const checkWasteNames = (model: WasteModel[]): WasteModel[] => {
  return model.map((waste) => {
    return {
      ...waste,
      name: checkWasteNameMaximumLength(waste.name),
    }
  })
}

export default checkWasteNames
