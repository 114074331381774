import * as yup from 'yup'

const validationSchema = yup.object({
  name: yup.string().required('Nome é obrigatório'),
  cnpj: yup.string().required('CNPJ é obrigatório'),
  corporateName: yup.string().required('Razão social é obrigatório'),
  serviceType: yup
    .array()
    .of(yup.string().required('Tipo de serviço é obrigatório'))
    .min(1, 'Tipo de serviço é obrigatório')
    .required('Tipo de serviço é obrigatório'),
  icms: yup.boolean(),
  taxRegime: yup
    .mixed()
    .oneOf(['Simples Nacional', 'Lucro Presumido', 'Lucro Real'])
    .required('Regime tributário é obrigatório'),
  cep: yup.string().required('CEP é obrigatório'),
  state: yup.string().required('Estado é obrigatório'),
  city: yup.string().required('Cidade é obrigatório'),
  district: yup.string().required('Bairro é obrigatório'),
  street: yup.string().required('Logradouro é obrigatório'),
  number: yup
    .number()
    .typeError('Favor inserir um número válido')
    .min(1, 'Valor invalido')
    .required('Número é obrigatório'),
  residues: yup.array().of(yup.string()),
  statesActive: yup.array().of(yup.string()),
  citiesActive: yup
    .array()
    .of(yup.string())
    .test(
      'must_contain_cities',
      'Você não selecionou cidades de todos estados selecionados.',
      (value, testContext): boolean => {
        const { statesActive } = testContext.parent
        if (!statesActive || statesActive.length === 0) return true
        const AreAllStatesWithCities = statesActive.every((state: string) => {
          const cities = value?.filter((city) => String(city).split('-')[1].trim() === state)
          return cities ? cities.length > 0 : false
        })
        return AreAllStatesWithCities
      }
    ),
  branch: yup
    .number()
    .typeError('Favor inserir um número válido')
    .min(1, 'Valor invalido')
    .required('Filial é obrigatório'),
})

export default validationSchema
