import React, { useCallback } from 'react'
import Drawer from '@mui/material/Drawer'
import Grid from '@mui/material/Grid'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button, IconButton } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import Input from '../../../../components/Form/Input/Input'
import useYupValidationResolver from '../../../../utils/yup-validator-resolver'
import DateRange from '../../../../components/Form/DateRange/DateRange'
import { dateToISOStringWithoutTimestamp, localDateFromLocalDateString } from '../../../../utils/convertDate'
import { Order } from '../../../../utils/sort'
import useStyles from './GatheringFilterDrawerStyles'
import { getPartnersByCustomerIdentifier } from '../../../../services/Client/ClientPartnerService'
import type { CompanyMinimalInfo } from '../../../../models/Company/Company'
import InputSelectCompany, { SelectCompanyOptions } from '../../../../components/Form/Select/SelectCompany'
import InputSelect2, { Select2Options } from '../../../../components/Form/Select/Select2'
import { unmaskCNPJ } from '../../../../utils/masks/maskCNPJ'

export interface GatheringParams {
  generatorCnpj: string
  transporterCnpj?: string
  receiverCnpj?: string
  startDate?: string
  endDate?: string
  mtrState?: string
  manifestCode?: string
  orderBy?: string
  orderDirection?: Order
  pageIndex?: number
  pageSize?: number
}
interface FilterParams {
  generator: CompanyMinimalInfo
  transporter?: CompanyMinimalInfo
  receiver?: CompanyMinimalInfo
  startDate?: string
  endDate?: string
  mtrState?: string
  manifestCode?: string
  orderBy?: string
  orderDirection?: Order
  pageIndex?: number
  pageSize?: number
}
type FilterDrawerProps = {
  open: boolean
  onClose: () => void
  generatorOptions: CompanyMinimalInfo[]
  transporterOptions: CompanyMinimalInfo[]
  receiverOptions: CompanyMinimalInfo[]
  generator: CompanyMinimalInfo
  setGenerator: React.Dispatch<React.SetStateAction<CompanyMinimalInfo>>
  transporter?: CompanyMinimalInfo
  setTransporter: React.Dispatch<React.SetStateAction<CompanyMinimalInfo | undefined>>
  receiver?: CompanyMinimalInfo
  setReceiver: React.Dispatch<React.SetStateAction<CompanyMinimalInfo | undefined>>
  setParams: React.Dispatch<React.SetStateAction<GatheringParams>>
  setPartnersInfo: (partners: CompanyMinimalInfo[] | null) => void
}

const schema = yup.object().shape({
  generator: yup.string(),
  transporter: yup.string(),
  receiver: yup.string(),
  startDate: yup.string(),
  endDate: yup.string(),
  mtrState: yup.string(),
  manifestCode: yup.string(),
})

const GatheringFilterDrawer: React.FC<FilterDrawerProps> = ({
  open,
  onClose,
  generatorOptions,
  transporterOptions,
  receiverOptions,
  generator,
  transporter,
  receiver,
  setGenerator,
  setTransporter,
  setReceiver,
  setParams,
  setPartnersInfo,
}: FilterDrawerProps) => {
  const classes = useStyles()
  const resolver = useYupValidationResolver(schema)
  const { control, formState, handleSubmit, reset } = useForm<FilterParams>({ resolver })

  const statusOptions = [
    { value: 'Salvo', label: 'Salvo' },
    { value: 'Cancelado', label: 'Cancelado' },
    { value: 'Recebido', label: 'Recebido' },
    { value: 'Erro', label: 'Erro' },
  ]
  const [startDate, setStartDate] = React.useState<string>()
  const [endDate, setEndDate] = React.useState<string>()
  const [dateRange, setDateRange] = React.useState<{ startDate: Date; endDate: Date } | undefined>()
  const [mtrState, setMtrState] = React.useState<string>()
  const [selectedGenerator, setSelectedGenerator] = React.useState<CompanyMinimalInfo | undefined>(generator)

  const handleChangeGenerator = async (
    event: React.SyntheticEvent,
    value: SelectCompanyOptions | null
  ): Promise<void> => {
    if (value) {
      const newGenerator = value as CompanyMinimalInfo
      setSelectedGenerator(newGenerator)
      const partners = await getPartnersByCustomerIdentifier(newGenerator.identifier)
      setPartnersInfo(partners)
    }
  }
  const handleChangeTransporter = (event: React.SyntheticEvent, value: SelectCompanyOptions | null): void => {
    setTransporter(value ?? undefined)
  }
  const handleChangeReceiver = (event: React.SyntheticEvent, value: SelectCompanyOptions | null): void => {
    const selectedReceiver = value ?? undefined
    setReceiver(selectedReceiver)
  }
  const handleChangeStatus = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    setMtrState(value?.value as string)
  }

  const handleChangeDate = (ranges: { startDate?: Date | undefined; endDate?: Date | undefined }): void => {
    if (ranges.startDate) setStartDate(dateToISOStringWithoutTimestamp(ranges.startDate))
    if (ranges.endDate) setEndDate(dateToISOStringWithoutTimestamp(ranges.endDate))
  }

  const returnDateFromParams = useCallback((): void => {
    if (startDate && endDate) {
      setDateRange({
        startDate: localDateFromLocalDateString(startDate),
        endDate: localDateFromLocalDateString(endDate),
      })
    } else {
      setDateRange(undefined)
    }
  }, [startDate, endDate])

  const resetAllFields = (): void => {
    reset({
      manifestCode: '',
    })
    setMtrState(undefined)
    setEndDate(undefined)
    setStartDate(undefined)
    setReceiver(undefined)
    setTransporter(undefined)
  }

  React.useEffect(() => {
    returnDateFromParams()
  }, [startDate, endDate, returnDateFromParams])

  React.useEffect(() => {
    if (open) setSelectedGenerator(generator)
  }, [open])

  const onSubmit = (data: FilterParams): void => {
    setGenerator(selectedGenerator as CompanyMinimalInfo)
    const newData = {
      ...data,
      manifestCode: data.manifestCode ?? undefined,
      startDate,
      endDate,
      generatorCnpj: unmaskCNPJ(selectedGenerator?.cnpj as string),
      transporterCnpj: transporter?.cnpj ? unmaskCNPJ(transporter?.cnpj) : undefined,
      receiverCnpj: receiver?.cnpj ? unmaskCNPJ(receiver?.cnpj) : undefined,
      orderDirection: 'DESC' as Order,
      mtrState,
      generator: selectedGenerator,
    }
    setParams(newData)
    onClose()
  }

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.header}>
          <Grid item xs={10} className={classes.headerTitle}>
            <h1 data-cy="mtr-filter-drawer-title">Filtrar MTRs</h1>
          </Grid>
          <Grid item xs={2} className={classes.closeButton}>
            <IconButton onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <InputSelectCompany
          id="generatorIdentifier"
          sx={{ marginBottom: '3vh' }}
          errorText={formState.errors?.generator?.identifier?.message}
          label="Empresa"
          options={generatorOptions}
          onChange={handleChangeGenerator}
          company={selectedGenerator}
        />
        <DateRange onRangeUpdate={handleChangeDate} defaultSelectionRange={dateRange} />

        <InputSelectCompany
          id="transporter"
          clearable
          sx={{ marginBottom: '3vh' }}
          errorText={formState.errors?.transporter?.identifier?.message}
          label="Transportador"
          options={transporterOptions}
          company={transporter}
          onChange={handleChangeTransporter}
        />

        <InputSelectCompany
          id="receiver"
          clearable
          sx={{ marginBottom: '3vh' }}
          errorText={formState.errors?.receiver?.identifier?.message}
          label="Destinador"
          options={receiverOptions}
          company={receiver}
          onChange={handleChangeReceiver}
        />

        <InputSelect2
          id="mtrState"
          clearable
          sx={{ marginBottom: '3vh' }}
          errorText={formState.errors?.mtrState?.message}
          label="Status de integração"
          options={statusOptions}
          value={mtrState}
          onChange={handleChangeStatus}
        />

        <Controller
          name="manifestCode"
          control={control}
          render={({ field }) => <Input sx={{ marginBottom: '3vh' }} id="manifestCode" label="Manifesto" {...field} />}
        />
        <Grid className={classes.buttonContainer}>
          <Button
            className={classes.clearButton}
            data-cy="mtr-filter-drawer-clean-button"
            type="button"
            onClick={resetAllFields}
          >
            Limpar
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.filterButton}
            data-cy="mtr-filter-drawer-submit-filter"
          >
            Filtrar
          </Button>
        </Grid>
      </form>
    </Drawer>
  )
}

export default GatheringFilterDrawer
